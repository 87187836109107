import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import { useCallback, useEffect, useMemo, useState } from 'react';

import Checkbox from '@maya/common/checkbox/Checkbox';
import CheckboxGroup from '@maya/common/checkbox/CheckboxGroup';
import RadioGroup from '@maya/common/radio-group/RadioGroup';
import Select from '@maya/common/select/Select';
import NumberField from '@maya/common/textfield/NumberField';
import TextField from '@maya/common/textfield/TextField';
import useDisciplines from '@maya/hooks/static/useDisciplines';
import useFormTypes from '@maya/hooks/static/useFormTypes';
import useBranchId from '@maya/hooks/useBranchId';
import { useIsSmallScreen } from '@maya/hooks/useMediaQuery';
import useTranslate from '@maya/hooks/useTranslate';
import { Forms, PaySchedule } from '@maya/interface';
import { useAppDispatch, useAppSelector } from '@maya/store/hooks';
import { createVisitType, saveVisitType, selectVisitTypeSaveInProgress } from '@maya/store/slices/visit-type';

import type { CreateVisitTypeDTO, VisitTypeDTO } from '@maya/interface';
import type { FC } from 'react';

export interface VisitTypeFormProps {
  visitType?: VisitTypeDTO;
  editMode?: boolean;
  onClose?: () => void;
}

function getData(visitType?: VisitTypeDTO): Partial<CreateVisitTypeDTO> {
  return { ...(visitType ?? { formIds: [Forms.ROUTE_SHEET] }) };
}

const VisitTypeForm: FC<VisitTypeFormProps> = ({ visitType, editMode = false, onClose }) => {
  const t = useTranslate();
  const isSmallScreen = useIsSmallScreen();

  const [version, setVersion] = useState(0);
  const [data, setData] = useState<Partial<CreateVisitTypeDTO>>(getData(visitType));
  const [dataValid, setDataValid] = useState<Record<string, boolean>>();

  const formTypes = useFormTypes();
  const disciplines = useDisciplines();

  const saveInProgress = useAppSelector(selectVisitTypeSaveInProgress);

  const updateData = useCallback((newData: Partial<VisitTypeDTO>) => {
    setData((oldData) => ({
      ...oldData,
      ...newData
    }));
  }, []);

  const [editing, setEditing] = useState(false);
  const startEditing = useCallback(() => {
    setEditing(true);
  }, []);
  const endEditing = useCallback(() => {
    setData(getData(visitType));
    setVersion((oldVersion) => oldVersion + 1);
    setEditing(false);
  }, [visitType]);

  useEffect(() => {
    setData(getData(visitType));
    setVersion((oldVersion) => oldVersion + 1);
  }, [visitType]);

  const dispatch = useAppDispatch();
  const branchId = useBranchId();

  const handleValidate = useCallback(
    (key: string) => (valid: boolean) => {
      setDataValid((old) => ({ ...old, [key]: valid }));
    },
    []
  );
  const formIsValid = useMemo(
    () =>
      dataValid
        ? Object.keys(dataValid).reduce((acc, key) => {
            return acc && dataValid[key];
          }, true)
        : true,
    [dataValid]
  );

  const handleSave = useCallback(async () => {
    if (!formIsValid) {
      return;
    }

    if (branchId) {
      if (visitType) {
        if (!(await dispatch(saveVisitType(branchId, { ...visitType, ...data }, t)))) {
          return;
        }
      } else {
        if (!(await dispatch(createVisitType(branchId, data as CreateVisitTypeDTO, t)))) {
          return;
        }
      }
    }

    if (editMode) {
      setEditing(false);
    } else {
      onClose?.();
    }
  }, [formIsValid, branchId, editMode, visitType, dispatch, data, t, onClose]);

  const handleClose = useCallback(() => {
    onClose?.();
  }, [onClose]);

  const direction = useMemo(() => (isSmallScreen ? 'column' : 'row'), [isSmallScreen]);

  return (
    <Box key={version} component="form" autoComplete="off" sx={{ paddingBottom: '16px', maxWidth: '550px' }}>
      <Stack spacing={2}>
        <TextField
          data-testid="name"
          required
          label={t('visitTypes.form.name')}
          defaultValue={data.name}
          onChange={(newValue) => updateData({ name: newValue })}
          onValidate={handleValidate('name')}
          disabled={editMode && !editing}
        />
        <Select
          data-testid="discipline"
          key="discipline-select"
          required
          label={t('visitTypes.form.discipline')}
          defaultValue={data.disciplineId}
          options={disciplines.map((discipline) => ({
            value: discipline.id,
            label: discipline.name
          }))}
          onChange={(newValue) => updateData({ disciplineId: newValue })}
          onValidate={handleValidate('discipline')}
          disabled={editMode && !editing}
        />
        <Stack direction={direction} spacing={3} sx={{ width: '100%' }}>
          <NumberField
            data-testid="defaultPay"
            required
            label={t('visitTypes.form.defaultPay')}
            defaultValue={data.defaultPay}
            onChange={(newValue) => updateData({ defaultPay: newValue })}
            onValidate={handleValidate('defaultPay')}
            disabled={editMode && !editing}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>
            }}
          />
          <NumberField
            data-testid="defaultHours"
            required
            label={t('visitTypes.form.defaultHours')}
            defaultValue={data.defaultHours}
            onChange={(newValue) => updateData({ defaultHours: newValue })}
            onValidate={handleValidate('defaultHours')}
            disabled={editMode && !editing}
          />
        </Stack>
        <RadioGroup
          data-testid="paySchedule"
          label={t('visitTypes.form.paySchedule')}
          boldLabel
          options={[
            {
              label: t('visitTypes.form.visit'),
              option: PaySchedule.Visit
            },
            {
              label: t('visitTypes.form.hour'),
              option: PaySchedule.Hour
            }
          ]}
          defaultValue={data.paySchedule}
          onChange={(newValue) => updateData({ paySchedule: newValue as PaySchedule })}
          disabled={editMode && !editing}
          onValidate={handleValidate('paySchedule')}
        />
        <FormControl fullWidth>
          <FormLabel sx={{ fontWeight: 'bold' }} component="legend">
            {t('visitTypes.form.mileage')}
          </FormLabel>
          <FormGroup>
            <Checkbox
              key={version}
              label={t('visitTypes.form.getMileage')}
              defaultChecked={Boolean(data.mileage)}
              onChange={(newValue) => updateData({ mileage: newValue })}
              disabled={editMode && !editing}
            />
          </FormGroup>
          <FormHelperText>&nbsp;</FormHelperText>
        </FormControl>
        <CheckboxGroup
          label={t('visitTypes.form.requiredForms')}
          boldLabel
          checkboxes={formTypes.map((type) => ({
            name: type.id,
            label: type.name,
            disabled: type.code === Forms.ROUTE_SHEET
          }))}
          required
          defaultValue={data.formIds}
          onChange={(newValue) => updateData({ formIds: newValue })}
          onValidate={handleValidate('paySchedule')}
          disabled={editMode && !editing}
        />
        <Stack direction="row" spacing={3}>
          {!editMode || editing ? (
            <>
              <Button
                data-testid="save"
                variant="contained"
                onClick={handleSave}
                disabled={!formIsValid || saveInProgress}
              >
                {t('app.save')}
              </Button>
              <Button data-testid="cancel" onClick={editMode ? endEditing : handleClose}>
                {t('app.cancel')}
              </Button>
            </>
          ) : (
            <Button
              data-testid="edit"
              variant="contained"
              onClick={startEditing}
              disabled={!formIsValid || saveInProgress}
            >
              {t('app.edit')}
            </Button>
          )}
        </Stack>
      </Stack>
    </Box>
  );
};

export default VisitTypeForm;
