import { useEffect, useMemo } from 'react';
import { Navigate, Route, HashRouter as Router, Routes } from 'react-router-dom';

import NewUserView from '@maya/admin/NewUserView';
import UserList from '@maya/admin/UserList';
import UserView from '@maya/admin/UserView';
import ForgotPassword from '@maya/auth/ForgotPassword';
import Login from '@maya/auth/Login';
import ResetPassword from '@maya/auth/ResetPassword';
import Verify from '@maya/auth/Verify';
import CompanyList from '@maya/company/CompanyList';
import CompanyViewPage from '@maya/company/CompanyViewPage';
import NewCompanyView from '@maya/company/NewCompanyView';
import Dashboard from '@maya/dashboard/Dashboard';
import EmployeeList from '@maya/employee/EmployeeList';
import EmployeeView from '@maya/employee/EmployeeView';
import NewEmployeeView from '@maya/employee/NewEmployeeView';
import useTranslate from '@maya/hooks/useTranslate';
import PatientConsent from '@maya/patient-consent/PatientConsent';
import PatientList from '@maya/patient/PatientList';
import PatientView from '@maya/patient/PatientView';
import PatientOverviewNewView from '@maya/patient/overview/PatientOverviewNewView';
import VisitDetails from '@maya/patient/visits/VisitDetails';
import FieldEmployeePayroll from '@maya/payroll/FieldEmployeePayroll';
import Payroll from '@maya/payroll/Payroll';
import RouteSheet from '@maya/route-sheet/RouteSheet';
import Settings from '@maya/settings/Settings';
import { useAppDispatch, useAppSelector } from '@maya/store/hooks';
import {
  checkAuth,
  selectAuthCheckComplete,
  selectAuthCheckInProgress,
  selectAuthInProgress,
  selectIsBranchAdmin,
  selectIsMayaAdmin,
  selectIsOffice,
  selectProfile
} from '@maya/store/slices/auth';
import TaskList from '@maya/task-list/TaskList';
import NewVisitTypeView from '@maya/visit-type/NewVisitTypeView';
import VisitTypeView from '@maya/visit-type/VisitTypeView';

import type { FC } from 'react';

const App: FC = () => {
  const dispatch = useAppDispatch();
  const t = useTranslate();

  const isMayaAdmin = useAppSelector(selectIsMayaAdmin);
  const isOffice = useAppSelector(selectIsOffice);
  const isBranchAdmin = useAppSelector(selectIsBranchAdmin);

  const authInProgress = useAppSelector(selectAuthInProgress);
  const authCheckInProgress = useAppSelector(selectAuthCheckInProgress);
  const authCheckComplete = useAppSelector(selectAuthCheckComplete);

  useEffect(() => {
    if (authCheckInProgress || authCheckComplete) {
      return;
    }

    dispatch(checkAuth(t));
  }, [authCheckComplete, authCheckInProgress, dispatch, t]);

  // TODO: Change default path based on permissions and user type
  const defaultPath = useMemo(() => {
    if (isMayaAdmin) {
      return '/company/list';
    }

    if (isOffice) {
      return '/patient/list';
    }

    return '/dashboard/summary';
  }, [isMayaAdmin, isOffice]);

  const authProfile = useAppSelector(selectProfile);
  if (!authProfile || authInProgress) {
    return (
      <Router key="auth-router">
        <Routes>
          <Route path="/verify" element={<Verify />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="*" element={<Login />} />
        </Routes>
      </Router>
    );
  }

  if (isMayaAdmin) {
    return (
      <Router key="maya-admin-app-router">
        <Routes>
          <Route path="/" element={<Navigate to={defaultPath} />} />
          <Route path="/company/list" element={<CompanyList />} />
          <Route path="/company/details/:id" element={<CompanyViewPage />} />
          <Route path="/company/new" element={<NewCompanyView />} />
          <Route path="/user/list" element={<UserList />} />
          <Route path="/user/details/:id" element={<UserView />} />
          <Route path="/user/new" element={<NewUserView />} />
          <Route path="*" element={<Navigate to={defaultPath} />} />
        </Routes>
      </Router>
    );
  }

  const officeFieldCommonViews = (
    <>
      <Route path="/patient/details/:id" element={<PatientView />} />
      <Route path="/patient/details/:id/:tab" element={<PatientView />} />
      <Route path="/visit/:visitId/route-sheet/:id" element={<RouteSheet />} />
      <Route path="/visit/:visitId/patient-consent/:id" element={<PatientConsent />} />
      <Route path="/visit/:visitId/task-list/:id" element={<TaskList />} />
    </>
  );

  if (isOffice) {
    return (
      <Router key="office-app-router">
        <Routes>
          <Route path="/" element={<Navigate to={defaultPath} />} />
          <Route path="/patient/list" element={<PatientList />} />
          <Route path="/patient/new" element={<PatientOverviewNewView />} />
          {officeFieldCommonViews}
          <Route path="/employee/list" element={<EmployeeList />} />
          <Route path="/employee/details/:id" element={<EmployeeView />} />
          <Route path="/employee/new" element={<NewEmployeeView />} />
          <Route path="/payroll" element={<Payroll />} />
          {isBranchAdmin ? (
            <>
              <Route path="/settings/:tab" element={<Settings />} />
              <Route path="/visit-type/details/:id" element={<VisitTypeView />} />
              <Route path="/visit-type/new" element={<NewVisitTypeView />} />
            </>
          ) : null}
          <Route path="*" element={<Navigate to={defaultPath} />} />
        </Routes>
      </Router>
    );
  }

  return (
    <Router key="field-app-router">
      <Routes>
        <Route path="/" element={<Navigate to={defaultPath} />} />
        <Route path="/dashboard/:tab" element={<Dashboard />} />
        <Route path="/visit/:id" element={<VisitDetails />} />
        {officeFieldCommonViews}
        <Route path="/payroll" element={<FieldEmployeePayroll />} />
        <Route path="*" element={<Navigate to={defaultPath} />} />
      </Routes>
    </Router>
  );
};

export default App;
