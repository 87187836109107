import { useMemo } from 'react';

import { useAppSelector } from '@maya/store/hooks';
import { selectProfile } from '@maya/store/slices/auth';

export default function useBranchId() {
  const profile = useAppSelector(selectProfile);

  return useMemo(() => (profile && profile.branchIds.length > 0 ? profile.branchIds[0] : undefined), [profile]);
}
