import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import logo from '@maya/assets/images/maya-logo.png';
import { useIsValidPassword } from '@maya/hooks/useIsValidPassword';
import useQuery from '@maya/hooks/useQuery';
import useTranslate from '@maya/hooks/useTranslate';
import { useAppDispatch, useAppSelector } from '@maya/store/hooks';
import { resend, selectAuthInProgress, verify } from '@maya/store/slices/auth';
import { isEmpty } from '@maya/util/string.util';

import type { FC, FormEvent, KeyboardEventHandler, MouseEvent } from 'react';

const Verify: FC = () => {
  const t = useTranslate();
  const navigate = useNavigate();
  const query = useQuery();

  const [showResend, setShowResend] = useState(false);
  const [resendUsername, setResendUsername] = useState('');

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const [username, setUsername] = useState(query.get('username') ?? '');
  const [code, setCode] = useState(query.get('token') ?? '');
  const [password, setPassword] = useState('');

  const isValidPassword = useIsValidPassword(password);

  const isInvalid = useMemo(() => {
    return isEmpty(username) || isEmpty(code) || isEmpty(password) || !isValidPassword;
  }, [code, isValidPassword, password, username]);

  const inProgress = useAppSelector(selectAuthInProgress);

  const dispatch = useAppDispatch();
  const handleVerify = useCallback(
    (event?: FormEvent) => {
      event?.preventDefault();

      if (isInvalid) {
        return;
      }

      dispatch(verify(username, code, password, navigate, t));
    },
    [code, dispatch, isInvalid, navigate, password, t, username]
  );

  const handleKeydown: KeyboardEventHandler = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        handleVerify();
      }
    },
    [handleVerify]
  );

  const handleResendOpen = useCallback(() => {
    setResendUsername(username);
    setShowResend(true);
  }, [username]);

  const handleResendClose = useCallback(() => {
    setShowResend(false);
  }, []);

  const handleResend = useCallback(
    (event?: FormEvent) => {
      event?.preventDefault();

      if (isEmpty(resendUsername)) {
        return;
      }

      dispatch(resend(resendUsername, navigate, t));

      setUsername(resendUsername);
      setCode(query.get('token') ?? '');
      setPassword('');
      setShowResend(false);
    },
    [dispatch, navigate, query, resendUsername, t]
  );

  return (
    <Box
      sx={{
        inset: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh'
      }}
    >
      <Card sx={{ maxWidth: 500, width: '100%', margin: '0 16px' }}>
        <CardContent>
          {showResend ? (
            <Box
              key="resend"
              component="form"
              sx={{ display: 'flex', flexDirection: 'column' }}
              onSubmit={handleResend}
            >
              <Box
                component="img"
                src={logo}
                alt="Maya Logo"
                sx={{
                  maxWidth: 350,
                  height: 150,
                  alignSelf: 'center',
                  marginBottom: '40px'
                }}
              />
              <TextField
                data-testid="resend-username"
                label={t('auth.username')}
                variant="filled"
                sx={{ marginBottom: '24px' }}
                defaultValue={resendUsername}
                onChange={(event) => {
                  setResendUsername(event.target.value);
                }}
                onKeyDown={handleKeydown}
                disabled={inProgress}
                required
              />
              <Box sx={{ display: 'flex', gap: '16px' }}>
                <Button
                  data-testid="resend"
                  variant="contained"
                  onClick={handleResend}
                  disabled={isEmpty(resendUsername) || inProgress}
                >
                  {t('auth.resend')}
                </Button>
                <Button data-testid="resend-hide" variant="text" onClick={handleResendClose} disabled={inProgress}>
                  {t('app.cancel')}
                </Button>
              </Box>
            </Box>
          ) : (
            <Box
              key="verify"
              component="form"
              sx={{ display: 'flex', flexDirection: 'column' }}
              onSubmit={handleVerify}
            >
              <Box
                component="img"
                src={logo}
                alt="Maya Logo"
                sx={{
                  maxWidth: 350,
                  height: 150,
                  alignSelf: 'center',
                  marginBottom: '40px'
                }}
              />
              <TextField
                data-testid="username"
                label={t('auth.username')}
                variant="filled"
                sx={{ marginBottom: '24px' }}
                defaultValue={username}
                onChange={(event) => {
                  setUsername(event.target.value);
                }}
                onKeyDown={handleKeydown}
                disabled={inProgress}
                required
              />
              <TextField
                data-testid="code"
                label={t('auth.code')}
                variant="filled"
                sx={{ marginBottom: '24px' }}
                defaultValue={code}
                onChange={(event) => {
                  setCode(event.target.value);
                }}
                onKeyDown={handleKeydown}
                disabled={inProgress}
                required
              />
              <TextField
                data-testid="password"
                label={t('auth.password')}
                variant="filled"
                type={showPassword ? 'text' : 'password'}
                sx={{ marginBottom: '24px' }}
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
                onKeyDown={handleKeydown}
                disabled={inProgress}
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                helperText={t('auth.passwordRequirements')}
              />
              <Box sx={{ display: 'flex', gap: '16px' }}>
                <Button
                  data-testid="verify"
                  variant="contained"
                  onClick={handleVerify}
                  disabled={isInvalid || inProgress}
                >
                  {t('auth.verify')}
                </Button>
                <Button data-testid="resend-show" variant="text" onClick={handleResendOpen} disabled={inProgress}>
                  {t('auth.resend')}
                </Button>
              </Box>
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default Verify;
