import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import MuiRadioGroup from '@mui/material/RadioGroup';
import { useCallback, useEffect, useMemo, useState } from 'react';

import useValidate from '@maya/hooks/useValidate';
import { isNotNullish } from '@maya/util/null.util';

import type { RadioGroupProps as MuiRadioGroupProps } from '@mui/material/RadioGroup';
import type { ChangeEvent, ChangeEventHandler, ReactNode } from 'react';

export interface RadioGroupOption<T extends string | number> {
  label: ReactNode;
  option: T;
  disabled?: boolean;
}

export interface RadioGroupProps<T extends string | number>
  extends Omit<MuiRadioGroupProps, 'onChange' | 'defaultValue' | 'value'> {
  label?: string;
  options: RadioGroupOption<T>[];
  error?: boolean;
  pattern?: string;
  required?: boolean;
  helperText?: string;
  fullWidth?: boolean;
  defaultValue?: T | null;
  value?: T | null;
  boldLabel?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  onChange: (newValue: string, event: ChangeEvent<HTMLInputElement>) => void;
  onValidate?: (valid: boolean) => void;
}

const RadioGroup = <T extends string | number>({
  label,
  required = false,
  pattern,
  error,
  helperText,
  fullWidth = true,
  defaultValue,
  value,
  options,
  boldLabel = false,
  disabled,
  readOnly,
  onChange,
  onValidate,
  ...props
}: RadioGroupProps<T>) => {
  const id = useMemo(() => (label ? label.toLowerCase().replace(/ /g, '-') : undefined), [label]);

  const [internalValue, setInternalValue] = useState(defaultValue ?? value ?? null);

  useEffect(() => {
    if (isNotNullish(value)) {
      setInternalValue(value);
    }
  }, [value]);

  const { validate, hint, hasError } = useValidate({
    defaultValue: internalValue,
    required,
    pattern,
    error,
    helperText,
    onValidate
  });

  const handleOnChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      if (readOnly) {
        return;
      }
      const value = event.target.value;
      onChange(value, event);
      validate(value);
      setInternalValue(value as T);
    },
    [onChange, readOnly, validate]
  );

  return (
    <FormControl error={hasError} required={required} fullWidth={fullWidth}>
      {label ? (
        <FormLabel id={`${id}-label`} sx={{ fontWeight: boldLabel ? 'bold' : undefined }}>
          {label}
        </FormLabel>
      ) : null}
      <MuiRadioGroup
        aria-labelledby={label ? `${id}-label` : undefined}
        value={internalValue}
        onChange={handleOnChange}
        {...props}
      >
        {options.map((option, index) => (
          <FormControlLabel
            key={index}
            value={option.option}
            disabled={option.disabled || disabled}
            control={<Radio data-testid={`radio-${option.label}`} />}
            label={option.label}
          />
        ))}
      </MuiRadioGroup>
      <FormHelperText>{hint ?? <>&nbsp;</>}</FormHelperText>
    </FormControl>
  );
};

export default RadioGroup;
