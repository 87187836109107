import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '@maya/store/hooks';
import { loadDisciplines, selectDisciplines } from '@maya/store/slices/static';
import useTranslate from '../useTranslate';

export default function useDisciplines() {
  const dispatch = useAppDispatch();
  const t = useTranslate();

  useEffect(() => {
    dispatch(loadDisciplines(t));
  }, [dispatch, t]);

  return useAppSelector(selectDisciplines);
}
