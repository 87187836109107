import { useEffect } from 'react';

import useBranchId from '@maya/hooks/useBranchId';
import useTranslate from '@maya/hooks/useTranslate';
import { useAppDispatch, useAppSelector } from '@maya/store/hooks';
import { loadPatient, selectPatient } from '@maya/store/slices/patient';

export default function usePatient(patientId?: string) {
  const branchId = useBranchId();
  const t = useTranslate();

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!patientId || !branchId) {
      return;
    }

    dispatch(loadPatient(branchId, patientId, t));
  }, [branchId, dispatch, patientId, t]);

  return useAppSelector(selectPatient);
}
