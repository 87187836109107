import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Fab from '@mui/material/Fab';
import InputAdornment from '@mui/material/InputAdornment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import Empty from '@maya/common/Empty';
import FormattedDate from '@maya/common/FormattedDate';
import Page from '@maya/common/Page';
import Loading from '@maya/common/loading/Loading';
import TableCell from '@maya/common/table/TableCell';
import { PAGE_HEIGHT } from '@maya/constants';
import useDebounce from '@maya/hooks/useDebounce';
import useTranslate from '@maya/hooks/useTranslate';
import usePatients from '@maya/patient/hooks/usePatients';
import { useAppSelector } from '@maya/store/hooks';
import { selectPatientsLoading, selectTotalPatients } from '@maya/store/slices/patient';
import { isNotEmpty } from '@maya/util/string.util';

import type { FC } from 'react';

const PatientList: FC = () => {
  const t = useTranslate();
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 200);
  const patientsLoading = useAppSelector(selectPatientsLoading);

  const patients = usePatients(debouncedSearch);
  const totalPatients = useAppSelector(selectTotalPatients);

  return (
    <Page title={t('patients.list.header')}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          height: `calc(100vh - ${PAGE_HEIGHT}px)`
        }}
      >
        <Card sx={{ flexGrow: 1 }}>
          <CardContent
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
              paddingBottom: '12px!important'
            }}
          >
            <TextField
              id="filled-basic"
              label={t('app.search')}
              variant="filled"
              role="search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
              fullWidth
              onChange={(event) => {
                setSearch(event.target.value);
              }}
            />
            {patientsLoading ? (
              <Loading />
            ) : patients.length === 0 ? (
              <Empty key="empty" image="list">
                {t(isNotEmpty(debouncedSearch) ? 'patients.list.emptySearch' : 'patients.list.empty')}
              </Empty>
            ) : (
              <>
                <TableContainer sx={{ flexGrow: 1 }}>
                  <Table aria-label="employee list" stickyHeader component="div">
                    <TableHead component="div">
                      <TableRow component="div">
                        <TableCell>{t('patients.list.column.name')}</TableCell>
                        <TableCell>{t('patients.list.column.insurance')}</TableCell>
                        <TableCell>{t('patients.list.column.dob')}</TableCell>
                        <TableCell>{t('patients.list.column.phone')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody component="div">
                      {patients.map((patient) => (
                        <TableRow
                          component={Link}
                          key={patient.id}
                          data-testid={`patient-${patient.fullName}`}
                          sx={{ textDecoration: 'none', '&:last-child td, &:last-child th': { border: 0 } }}
                          hover
                          to={`/patient/details/${patient.id}`}
                        >
                          <TableCell>{patient.fullName}</TableCell>
                          <TableCell>{patient.insuranceId}</TableCell>
                          <TableCell>
                            <FormattedDate isoDate={patient.dob} />
                          </TableCell>
                          <TableCell>{patient.phone1}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Typography variant="body2" sx={{ textAlign: 'right' }} data-testid="total-rows">
                  {t('app.totalRows')} {totalPatients}
                </Typography>
              </>
            )}
          </CardContent>
        </Card>
        <Fab
          variant="extended"
          component={Link}
          to="/patient/new"
          color="secondary"
          sx={{ alignSelf: 'flex-end', gap: '4px', minHeight: '48px' }}
        >
          <AddIcon />
          {t('patients.patient')}
        </Fab>
      </Box>
    </Page>
  );
};

export default PatientList;
