import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import MuiSelect from '@mui/material/Select';
import { useCallback, useMemo } from 'react';

import useValidate from '@maya/hooks/useValidate';

import type { SelectProps as MuiSelectProps, SelectChangeEvent } from '@mui/material/Select';
import type { ReactNode } from 'react';

export interface Option<T extends string | number | readonly string[] | undefined> {
  value: T;
  label: ReactNode;
}

export interface SelectProps<T extends string | number | readonly string[] | undefined>
  extends Omit<MuiSelectProps<T>, 'labelId' | 'id' | 'label' | 'onChange'> {
  label?: string;
  options: Option<T>[];
  defaultValue: T;
  error?: boolean;
  helperText?: ReactNode;
  onChange: (value: T) => void;
  onValidate?: (valid: boolean) => void;
}

const Select = function <T extends string | number | readonly string[] | undefined>({
  label = '',
  options,
  defaultValue,
  variant = 'filled',
  sx,
  fullWidth = true,
  required = false,
  error,
  helperText,
  onChange,
  onValidate,
  ...props
}: SelectProps<T>) {
  const id = useMemo(() => label.toLowerCase().replace(/ /g, '-'), [label]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const defaultValueMemoized = useMemo(() => (defaultValue ?? '') as T, []);

  const { validate, hint, hasError } = useValidate({
    defaultValue: defaultValueMemoized,
    required,
    error,
    helperText,
    onValidate
  });

  const handleChange = useCallback(
    (event: SelectChangeEvent<T>) => {
      const value = event.target.value as unknown as T;
      onChange(value);
      validate(value);
    },
    [validate, onChange]
  );

  return (
    <FormControl variant={variant} sx={sx} required={required} fullWidth={fullWidth} error={hasError}>
      <InputLabel id={`${id}-label`}>{label}</InputLabel>
      <MuiSelect
        labelId={`${id}-label`}
        id={id}
        defaultValue={defaultValueMemoized}
        label="Age"
        onChange={handleChange}
        {...props}
      >
        {options.map((option, index) => (
          <MenuItem data-testid={`option-${option.label}`} key={index} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </MuiSelect>
      <FormHelperText>{hint ?? <>&nbsp;</>}</FormHelperText>
    </FormControl>
  );
};

export default Select;
