import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import Loading from '@maya/common/loading/Loading';
import useTranslate from '@maya/hooks/useTranslate';
import { useAppDispatch, useAppSelector } from '@maya/store/hooks';
import {
  resendCompanyAdminInvite,
  selectCompanieResendInProgress,
  selectCompanyLoading
} from '@maya/store/slices/company';
import CompanyForm from './CompanyForm';
import useCompany from './hooks/useCompany';

import type { CompanyWithUserDTO } from '@maya/interface';
import type { FC } from 'react';

export interface CompanyViewProps {
  startEditing?: () => void;
  endEditing?: () => void;
}

const CompanyView: FC<CompanyViewProps> = ({ startEditing, endEditing }) => {
  const t = useTranslate();
  const { id } = useParams();

  const dispatch = useAppDispatch();

  const company = useCompany(id);

  const companyLoading = useAppSelector(selectCompanyLoading);
  const resendInProgress = useAppSelector(selectCompanieResendInProgress);

  const [editing, setEditing] = useState(false);
  const handleStartEditing = useCallback(() => {
    setEditing(true);
    startEditing?.();
  }, [startEditing]);
  const handleEndEditing = useCallback(() => {
    setEditing(false);
    endEditing?.();
  }, [endEditing]);

  const resendInvite = useCallback(() => {
    if (!id) {
      return;
    }

    dispatch(resendCompanyAdminInvite(id, t));
  }, [id, dispatch, t]);

  if (!company || companyLoading) {
    return <Loading />;
  }

  if (editing) {
    return <CompanyForm key="editing-form" company={company} onClose={handleEndEditing} />;
  }

  return (
    <Box data-testid="company-details" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Card key="details-card">
        <CardContent sx={{ position: 'relative', paddingTop: 0 }}>
          <IconButton
            data-testid="edit"
            sx={{ position: 'absolute', top: '16px', right: '16px', zIndex: 1 }}
            onClick={handleStartEditing}
          >
            <EditIcon />
          </IconButton>
          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            <ListItem>
              <ListItemText data-testid="name" primaryTypographyProps={{ variant: 'h6' }} primary={company.name} />
            </ListItem>
            <ListItem sx={{ flexDirection: 'column', alignItems: 'flex-start' }}>
              <ListItemText data-testid="street" primary={company.street} />
              <ListItemText data-testid="city" primary={`${company.city}, ${company.state} ${company.zip}`} />
              <ListItemText
                data-testid="phone"
                primaryTypographyProps={{ component: Link, href: `tel:${company.phone}` }}
                primary={`Tel: ${company.phone}`}
              />
            </ListItem>
          </List>
          <List
            sx={{ width: '100%', bgcolor: 'background.paper' }}
            subheader={
              <Typography variant="subtitle1" component="div" sx={{ marginLeft: '16px', fontWeight: 'bold' }}>
                {t('company.view.idNumbers')}
              </Typography>
            }
          >
            <ListItem sx={{ justifyContent: 'flex-start', gap: 8 }}>
              <Stack data-testid="federal-tax-id" sx={{ width: '100px', maxWidth: '50%' }}>
                <Typography variant="caption">{t('company.form.federalTaxId')}</Typography>
                <Typography variant="body1">{company.taxId}</Typography>
              </Stack>
              <Stack data-testid="medicare-id">
                <Typography variant="caption">{t('company.form.medicareId')}</Typography>
                <Typography variant="body1">{company.medicareId}</Typography>
              </Stack>
            </ListItem>
            <ListItem sx={{ justifyContent: 'flex-start', gap: 8 }}>
              <Stack data-testid="state-agency-id" sx={{ width: '100px', maxWidth: '50%' }}>
                <Typography variant="caption">{t('company.form.stateAgencyId')}</Typography>
                <Typography variant="body1">{company.stateAgencyId}</Typography>
              </Stack>
              <Stack data-testid="npi-number">
                <Typography variant="caption">{t('company.form.npiNumber')}</Typography>
                <Typography variant="body1">{company.npiNumber}</Typography>
              </Stack>
            </ListItem>
          </List>
        </CardContent>
      </Card>
      {id ? (
        <Card key="user-card">
          <CardContent sx={{ position: 'relative', paddingTop: 0 }}>
            <IconButton
              data-testid="user-edit"
              sx={{ position: 'absolute', top: '16px', right: '16px', zIndex: 1 }}
              onClick={handleStartEditing}
            >
              <EditIcon />
            </IconButton>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
              <ListItem>
                <ListItemText primaryTypographyProps={{ variant: 'h6' }} primary={t('company.view.administrator')} />
              </ListItem>
              <ListItem sx={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                <ListItemText
                  data-testid="names-username"
                  primary={`${(company as CompanyWithUserDTO).firstName} ${(company as CompanyWithUserDTO).lastName}`}
                  secondary={(company as CompanyWithUserDTO).username}
                />
                <ListItemText
                  data-testid="email"
                  primaryTypographyProps={{ component: Link, href: `mailto:${(company as CompanyWithUserDTO).email}` }}
                  primary={(company as CompanyWithUserDTO).email}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  data-testid="invite"
                  secondaryTypographyProps={{ component: 'div' }}
                  secondary={
                    company.verified ? (
                      `${t('app.inviteAcceptedOn')} ${dayjs(company.inviteAcceptedDate).format(
                        'MMMM DD, YYYY hh:mm A'
                      )}`
                    ) : (
                      <Box sx={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
                        {`${t('app.invited')} ${dayjs(company.inviteDate).format('MMMM DD, YYYY hh:mm A')}`}
                        <Button
                          data-testid="resend-invite"
                          size="small"
                          disabled={resendInProgress}
                          onClick={resendInvite}
                        >
                          {t('app.resend')}
                        </Button>
                      </Box>
                    )
                  }
                />
              </ListItem>
            </List>
          </CardContent>
        </Card>
      ) : null}
    </Box>
  );
};

export default CompanyView;
