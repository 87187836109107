import { createSlice } from '@reduxjs/toolkit';

import Api from '@maya/api/api';
import fetch, { fetchAllList } from '@maya/api/fetch';

import type { CreateEmployeeDTO, EmployeeDTO, EmployeesDTO } from '@maya/interface';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { t } from 'react-polyglot';
import type { AppDispatch, RootState } from '../index';

// Define a type for the slice state
export interface EmployeeState {
  employee?: EmployeeDTO;
  employees: EmployeeDTO[];
  total: number;
  isLoading: boolean;
  saveInProgress: boolean;
  resendInviteInProgress: boolean;
}

// Define the initial state using that type
const initialState: EmployeeState = {
  employees: [],
  total: 0,
  isLoading: false,
  saveInProgress: false,
  resendInviteInProgress: false
};

export const employeeSlice = createSlice({
  name: 'employee',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setEmployeeLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    updateEmployees: (state, action: PayloadAction<EmployeesDTO>) => {
      state.employees = action.payload.data;
      state.total = action.payload.total;
      state.isLoading = false;
    },
    updateEmployee: (state, action: PayloadAction<EmployeeDTO>) => {
      const employee = action.payload;

      const index = state.employees.findIndex((e) => e.id === employee.id);
      if (index >= 0) {
        state.employees[index] = employee;
      }

      state.employee = action.payload;
      state.isLoading = false;
      state.saveInProgress = false;
      state.resendInviteInProgress = false;
    },
    setSaveInProgress: (state, action: PayloadAction<boolean>) => {
      state.saveInProgress = action.payload;
    },
    setResendInviteInProgress: (state, action: PayloadAction<boolean>) => {
      state.resendInviteInProgress = action.payload;
    }
  }
});

export const { setEmployeeLoading, updateEmployees, updateEmployee, setSaveInProgress, setResendInviteInProgress } =
  employeeSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectEmployees = (state: RootState) => state.employee.employees;
export const selectTotalEmployees = (state: RootState) => state.employee.total;

export const selectEmployee = (state: RootState) => state.employee.employee;
export const selectEmployeeLoading = (state: RootState) => state.employee.isLoading;
export const selectEmployeeSaveInProgress = (state: RootState) => state.employee.saveInProgress;
export const selectEmployeeResendInviteInProgress = (state: RootState) => state.employee.resendInviteInProgress;

export interface EmployeeSearch {
  branchId: string;
  search?: string;
  visitTypeId?: string;
}

export const loadEmployees =
  ({ branchId, search, visitTypeId }: EmployeeSearch, t: t) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    if (state.employee.isLoading) {
      return;
    }

    dispatch(setEmployeeLoading(true));

    const response = await fetchAllList(Api.employees_Get, t, {
      params: { branchId },
      query: { search, visitTypeId }
    });

    if (response) {
      dispatch(updateEmployees(response));
    } else {
      dispatch(setEmployeeLoading(false));
    }
  };

export const loadEmployee =
  (branchId: string, employeeId: string, t: t) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    if (state.employee.isLoading) {
      return;
    }

    dispatch(setEmployeeLoading(true));

    const response = await fetch(Api.employee_Get, t, { params: { branchId, employeeId } });

    if (response) {
      dispatch(updateEmployee(response));
    } else {
      dispatch(setEmployeeLoading(false));
    }
  };

export const createEmployee =
  (branchId: string, employeeData: CreateEmployeeDTO, t: t) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    if (state.employee.isLoading) {
      return;
    }

    dispatch(setSaveInProgress(true));

    const employee = await fetch(Api.employee_Post, t, {
      params: { branchId },
      body: employeeData,
      defaultError: 'employees.errors.create'
    });

    if (employee) {
      dispatch(updateEmployee(employee));
      return true;
    } else {
      dispatch(setSaveInProgress(false));
      return false;
    }
  };

export const saveEmployee =
  (branchId: string, employee: EmployeeDTO, t: t) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    if (state.employee.isLoading) {
      return;
    }

    dispatch(setSaveInProgress(true));

    const updatedEmployee = await fetch(Api.employee_Put, t, {
      params: { branchId, employeeId: employee.id },
      body: employee,
      defaultError: 'employees.errors.update'
    });

    if (updatedEmployee) {
      dispatch(updateEmployee(updatedEmployee));
      return true;
    } else {
      dispatch(setSaveInProgress(false));
      return false;
    }
  };

export const resendEmployeeInvite =
  (branchId: string, employee: EmployeeDTO, t: t) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    if (state.employee.isLoading) {
      return;
    }

    dispatch(setResendInviteInProgress(true));

    const updatedEmployee = await fetch(Api.employee_InviteResendPost, t, {
      params: { branchId, employeeId: employee.id },
      body: employee
    });

    if (updatedEmployee) {
      dispatch(updateEmployee(updatedEmployee));
    } else {
      dispatch(setResendInviteInProgress(false));
    }
  };

export default employeeSlice.reducer;
