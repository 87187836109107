import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Fab from '@mui/material/Fab';
import InputAdornment from '@mui/material/InputAdornment';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';

import Empty from '@maya/common/Empty';
import Page from '@maya/common/Page';
import Loading from '@maya/common/loading/Loading';
import TableCell from '@maya/common/table/TableCell';
import { PAGE_HEIGHT } from '@maya/constants';
import useDebounce from '@maya/hooks/useDebounce';
import useTranslate from '@maya/hooks/useTranslate';
import { useAppDispatch, useAppSelector } from '@maya/store/hooks';
import { saveAdminCompanyStatus, selectCompanyLoading, selectTotalCompanies } from '@maya/store/slices/company';
import { isNotEmpty } from '@maya/util/string.util';
import useCompanies from './hooks/useAdminCompanies';

import type { CompanyDTO } from '@maya/interface';
import type { FC, MouseEvent } from 'react';

const CompanyList: FC = () => {
  const t = useTranslate();
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 200);

  const companies = useCompanies(debouncedSearch);
  const totalCompanies = useAppSelector(selectTotalCompanies);
  const companiesLoading = useAppSelector(selectCompanyLoading);

  const dispatch = useAppDispatch();
  const toggleCompanyActive = useCallback(
    (company: CompanyDTO) => (event: MouseEvent) => {
      event.stopPropagation();

      dispatch(
        saveAdminCompanyStatus(
          {
            ...company,
            active: !company.active
          },
          t
        )
      );
    },
    [dispatch, t]
  );

  return (
    <Page title={t('company.list.header')}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          height: `calc(100vh - ${PAGE_HEIGHT}px)`
        }}
      >
        <Card sx={{ flexGrow: 1 }}>
          <CardContent
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
              paddingBottom: '12px!important'
            }}
          >
            <TextField
              id="filled-basic"
              label={t('app.search')}
              variant="filled"
              role="search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
              fullWidth
              onChange={(event) => {
                setSearch(event.target.value);
              }}
            />
            {companiesLoading ? (
              <Loading />
            ) : companies.length === 0 ? (
              <Empty key="empty" image="list">
                {t(isNotEmpty(debouncedSearch) ? 'company.list.emptySearch' : 'company.list.empty')}
              </Empty>
            ) : (
              <>
                <TableContainer sx={{ flexGrow: 1 }}>
                  <Table aria-label="company list" stickyHeader component="div">
                    <TableHead component="div">
                      <TableRow component="div">
                        <TableCell>{t('company.list.column.company')}</TableCell>
                        <TableCell>{t('company.list.column.state')}</TableCell>
                        <TableCell>{t('company.list.column.active')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody component="div">
                      {companies.map((company) => (
                        <TableRow
                          component={Link}
                          key={company.id}
                          data-testid={`company-${company.name}`}
                          sx={{ textDecoration: 'none', '&:last-child td, &:last-child th': { border: 0 } }}
                          hover
                          to={`/company/details/${company.id}`}
                        >
                          <TableCell>{company.name}</TableCell>
                          <TableCell>{company.state}</TableCell>
                          <TableCell component="div" data-testid="checkbox" sx={{ padding: 0 }}>
                            <Switch checked={company.active} color="secondary" onClick={toggleCompanyActive(company)} />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Typography variant="body2" sx={{ textAlign: 'right' }}>
                  {t('app.totalRows')} {totalCompanies}
                </Typography>
              </>
            )}
          </CardContent>
        </Card>
        <Fab
          variant="extended"
          component={Link}
          to="/company/new"
          color="secondary"
          sx={{ alignSelf: 'flex-end', gap: '4px', minHeight: '48px' }}
        >
          <AddIcon />
          {t('company.view.header')}
        </Fab>
      </Box>
    </Page>
  );
};

export default CompanyList;
