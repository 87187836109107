import { useEffect } from 'react';

import useBranchId from '@maya/hooks/useBranchId';
import useTranslate from '@maya/hooks/useTranslate';
import { useAppDispatch, useAppSelector } from '@maya/store/hooks';
import { loadTaskList, selectTaskList } from '@maya/store/slices/task-list';

export default function useTaskList(visitId?: string, taskListId?: string) {
  const branchId = useBranchId();
  const dispatch = useAppDispatch();
  const t = useTranslate();

  useEffect(() => {
    if (!branchId || !visitId || !taskListId) {
      return;
    }

    dispatch(loadTaskList(branchId, visitId, taskListId, t));
  }, [branchId, dispatch, visitId, taskListId, t]);

  return useAppSelector(selectTaskList);
}
