import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';

import useTranslate from '@maya/hooks/useTranslate';

import type { FC, MouseEvent, Touch, TouchEvent } from 'react';

const velocityFilterWeight = 0.7;
const minWidth = 0.5;
const maxWidth = 2.5;
const dotSize = 1.5;
const penColor = '#000000'; // material grey-900

class Point {
  public x: number;
  public y: number;
  public time: number;

  constructor(x: number, y: number, time?: number) {
    this.x = x;
    this.y = y;
    this.time = time || new Date().getTime();
  }

  public velocityFrom(start: Point): number {
    return this.time !== start.time ? this.distanceTo(start) / (this.time - start.time) : 1;
  }

  public distanceTo(start: Point): number {
    return Math.sqrt(Math.pow(this.x - start.x, 2) + Math.pow(this.y - start.y, 2));
  }
}

class Curve {
  public startPoint: Point;
  public control1: Point;
  public control2: Point;
  public endPoint: Point;

  constructor(startPoint: Point, control1: Point, control2: Point, endPoint: Point) {
    this.startPoint = startPoint;
    this.control1 = control1;
    this.control2 = control2;
    this.endPoint = endPoint;
  }

  public length() {
    const steps = 10;
    let length = 0;
    let i: number;
    let t: number;
    let cx: number;
    let cy: number;
    let px = 0;
    let py = 0;
    let xdiff: number;
    let ydiff: number;

    for (i = 0; i <= steps; i++) {
      t = i / steps;
      cx = this.point(t, this.startPoint.x, this.control1.x, this.control2.x, this.endPoint.x);
      cy = this.point(t, this.startPoint.y, this.control1.y, this.control2.y, this.endPoint.y);
      if (i > 0) {
        xdiff = cx - px;
        ydiff = cy - py;
        length += Math.sqrt(xdiff * xdiff + ydiff * ydiff);
      }
      px = cx;
      py = cy;
    }
    return length;
  }

  public point(t: number, start: number, c1: number, c2: number, end: number): number {
    return (
      start * (1.0 - t) * (1.0 - t) * (1.0 - t) +
      3.0 * c1 * (1.0 - t) * (1.0 - t) * t +
      3.0 * c2 * (1.0 - t) * t * t +
      end * t * t * t
    );
  }
}

export interface SignatureProperties {
  height?: number;
  initialUrl?: string;
  onValue: (url: string) => void;
  required?: boolean;
  readOnly?: boolean;
  'data-testid'?: string;
}

interface SignatureState {
  points: Point[];
  mouseButtonDown: boolean;
  lastVelocity: number;
  lastWidth: number;
  url: string;
  editing: boolean;
}

const Signature: FC<SignatureProperties> = ({
  height = 120,
  initialUrl,
  onValue,
  required = false,
  readOnly,
  'data-testid': dataTestId
}) => {
  const t = useTranslate();
  const theme = useTheme();

  const rootRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const [width, setWidth] = useState(0);

  const handleSize = useCallback(() => {
    setWidth(0);

    setTimeout(() => {
      const width = rootRef.current?.scrollWidth;
      setWidth(width ? width - 2 : 0);
    });
  }, []);

  useLayoutEffect(() => {
    handleSize();

    window.addEventListener('resize', handleSize);

    return () => window.removeEventListener('resize', handleSize);
  }, [handleSize]);

  const [state, setState] = useState<SignatureState>(() => ({
    points: [],
    url: '',
    lastVelocity: 0,
    lastWidth: 0,
    mouseButtonDown: false,
    editing: !initialUrl && !readOnly
  }));

  const { points, mouseButtonDown, lastVelocity, lastWidth, url, editing } = state;

  const internalReadOnly = !editing;

  const updateState = useCallback((update: Partial<SignatureState>) => {
    setState((old) => ({ ...old, ...update }));
  }, []);

  const calculateCurveControlPoints = useCallback((s1: Point, s2: Point, s3: Point): { c1: Point; c2: Point } => {
    const dx1 = s1.x - s2.x;
    const dy1 = s1.y - s2.y;
    const dx2 = s2.x - s3.x;
    const dy2 = s2.y - s3.y;
    const m1 = {
      x: (s1.x + s2.x) / 2.0,
      y: (s1.y + s2.y) / 2.0
    };
    const m2 = {
      x: (s2.x + s3.x) / 2.0,
      y: (s2.y + s3.y) / 2.0
    };
    const l1 = Math.sqrt(dx1 * dx1 + dy1 * dy1);
    const l2 = Math.sqrt(dx2 * dx2 + dy2 * dy2);
    const dxm = m1.x - m2.x;
    const dym = m1.y - m2.y;
    const k = l2 / (l1 + l2);
    const cm = {
      x: m2.x + dxm * k,
      y: m2.y + dym * k
    };
    const tx = s2.x - cm.x;
    const ty = s2.y - cm.y;

    return {
      c1: new Point(m1.x + tx, m1.y + ty),
      c2: new Point(m2.x + tx, m2.y + ty)
    };
  }, []);

  const createPoint = useCallback((event: Touch | MouseEvent) => {
    if (!canvasRef.current) {
      return;
    }

    const rect = canvasRef.current.getBoundingClientRect();
    return new Point(event.clientX - rect.left, event.clientY - rect.top);
  }, []);

  const strokeWidth = useCallback((velocity: number): number => {
    return Math.max(maxWidth / (velocity + 1), minWidth);
  }, []);

  const drawPoint = useCallback((x: number, y: number, size: number): void => {
    const context = canvasRef.current?.getContext('2d');
    if (!context) {
      return;
    }

    context.moveTo(x, y);
    context.arc(x, y, size, 0, 2 * Math.PI, false);
  }, []);

  const drawCurve = useCallback(
    (curve: Curve, startWidth: number, endWidth: number): void => {
      const context = canvasRef.current?.getContext('2d');
      if (!context) {
        return;
      }

      const widthDelta = endWidth - startWidth;
      let width;
      let i;
      let t;
      let tt;
      let ttt;
      let u;
      let uu;
      let uuu;
      let x;
      let y;

      const drawSteps = Math.floor(curve.length());
      context.beginPath();
      for (i = 0; i < drawSteps; i++) {
        t = i / drawSteps;
        tt = t * t;
        ttt = tt * t;
        u = 1 - t;
        uu = u * u;
        uuu = uu * u;

        x = uuu * curve.startPoint.x;
        x += 3 * uu * t * curve.control1.x;
        x += 3 * u * tt * curve.control2.x;
        x += ttt * curve.endPoint.x;

        y = uuu * curve.startPoint.y;
        y += 3 * uu * t * curve.control1.y;
        y += 3 * u * tt * curve.control2.y;
        y += ttt * curve.endPoint.y;

        width = startWidth + ttt * widthDelta;
        drawPoint(x, y, width);
      }
      context.closePath();
      context.fill();
    },
    [drawPoint]
  );

  const addCurve = useCallback(
    (curve: Curve) => {
      const startPoint = curve.startPoint;
      const endPoint = curve.endPoint;

      let velocity = endPoint.velocityFrom(startPoint);
      velocity = velocityFilterWeight * velocity + (1 - velocityFilterWeight) * lastVelocity;

      const newWidth = strokeWidth(velocity);
      drawCurve(curve, lastWidth, newWidth);

      updateState({
        lastVelocity: velocity,
        lastWidth: newWidth
      });
    },
    [drawCurve, lastVelocity, lastWidth, strokeWidth, updateState]
  );

  const addPoint = useCallback(
    (point: Point, reset: boolean) => {
      const newPoints = reset ? [] : [...points];
      let c2: Point;
      let c3: Point;
      let curve;
      let tmp: {
        c1: Point;
        c2: Point;
      };
      newPoints.push(point);

      if (newPoints.length > 2) {
        if (newPoints.length === 3) {
          newPoints.unshift(newPoints[0]);
        }

        tmp = calculateCurveControlPoints(newPoints[0], newPoints[1], newPoints[2]);

        c2 = tmp.c2;
        tmp = calculateCurveControlPoints(newPoints[1], newPoints[2], newPoints[3]);
        c3 = tmp.c1;
        curve = new Curve(newPoints[1], c2, c3, newPoints[2]);
        addCurve(curve);
        newPoints.shift();
      }

      updateState({ points: newPoints });
    },
    [addCurve, calculateCurveControlPoints, points, updateState]
  );

  const strokeUpdate = useCallback(
    (event: Touch | MouseEvent, reset = false) => {
      const point = createPoint(event);
      if (point) {
        addPoint(point, reset);
      }
    },
    [addPoint, createPoint]
  );

  const reset = useCallback(
    (extra: Partial<SignatureState> = {}) => {
      updateState({
        points: [],
        lastVelocity: 0,
        lastWidth: (minWidth + maxWidth) / 2,
        ...extra
      });

      const context = canvasRef.current?.getContext('2d');
      if (context) {
        context.fillStyle = penColor;
      }
    },
    [updateState]
  );

  const strokeBegin = useCallback(
    (event: Touch | MouseEvent) => {
      reset();
      strokeUpdate(event, true);
    },
    [reset, strokeUpdate]
  );

  const onMouseDown = useCallback(
    (event: MouseEvent) => {
      if (internalReadOnly) {
        return;
      }
      updateState({ mouseButtonDown: true });
      strokeBegin(event);
    },
    [internalReadOnly, strokeBegin, updateState]
  );

  const onMouseMove = useCallback(
    (event: MouseEvent) => {
      if (internalReadOnly) {
        return;
      }

      if (mouseButtonDown) {
        strokeUpdate(event);
      }
    },
    [internalReadOnly, mouseButtonDown, strokeUpdate]
  );

  const strokeDraw = useCallback(
    (point: Point) => {
      const context = canvasRef.current?.getContext('2d');
      if (!context) {
        return;
      }

      context.beginPath();
      drawPoint(point.x, point.y, dotSize);
      context.closePath();
      context.fill();
    },
    [drawPoint]
  );

  const strokeEnd = useCallback(() => {
    const canDrawCurve = points.length > 2;
    const point = points[0];
    if (!canDrawCurve && point) {
      strokeDraw(point);
    }

    const newUrl = canvasRef.current?.toDataURL() ?? '';
    updateState({ url: newUrl });
  }, [points, strokeDraw, updateState]);

  const onMouseUp = useCallback(() => {
    if (internalReadOnly) {
      return;
    }
    updateState({ mouseButtonDown: false });
    strokeEnd();
  }, [internalReadOnly, strokeEnd, updateState]);

  const onTouchStart = useCallback(
    (event: TouchEvent) => {
      if (internalReadOnly) {
        return;
      }
      const touch = event.targetTouches[0];
      updateState({ mouseButtonDown: true });
      strokeBegin(touch);
    },
    [internalReadOnly, strokeBegin, updateState]
  );

  const onTouchMove = useCallback(
    (event: TouchEvent) => {
      event.preventDefault();
      if (internalReadOnly) {
        return;
      }
      if (mouseButtonDown) {
        const touch = event.targetTouches[0];
        strokeUpdate(touch);
      }
    },
    [internalReadOnly, mouseButtonDown, strokeUpdate]
  );

  const onTouchEnd = useCallback(() => {
    if (internalReadOnly) {
      return;
    }
    updateState({ mouseButtonDown: false });
    strokeEnd();
  }, [internalReadOnly, strokeEnd, updateState]);

  const clear = useCallback(() => {
    const context = canvasRef.current?.getContext('2d');
    if (!canvasRef.current || !context) {
      return;
    }

    context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);

    reset({
      points: [],
      url: '',
      lastVelocity: 0,
      lastWidth: (minWidth + maxWidth) / 2,
      mouseButtonDown: false,
      editing: !readOnly
    });
  }, [readOnly, reset]);

  const fromUrl = useCallback(
    (imageUrl: string) => {
      const context = canvasRef.current?.getContext('2d');
      if (!context) {
        return;
      }

      const image = new Image();
      clear();
      image.src = imageUrl;
      image.onload = () => {
        context.drawImage(image, 0, 0, width, height);
      };
    },
    [clear, height, width]
  );

  useEffect(() => {
    if (initialUrl !== undefined && width > 0 && initialUrl !== url) {
      fromUrl(initialUrl);
      updateState({ url: initialUrl, editing: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  useLayoutEffect(() => {
    const context = canvasRef.current?.getContext('2d');
    if (context) {
      context.fillStyle = penColor;
    }
  }, []);

  const handleDone = useCallback(() => {
    updateState({ editing: false });
    onValue(url);
  }, [onValue, updateState, url]);

  const handleRemove = useCallback(() => {
    clear();
    onValue('');
  }, [clear, onValue]);

  return (
    <Box data-testid={dataTestId} ref={rootRef} key="root" sx={{ display: 'flex', width: '100%', marginTop: '24px' }}>
      <Stack spacing={2} sx={{ width: '100%' }}>
        <Box>
          <strong>
            {t('signature.label')}
            {required ? ' *' : ''}
          </strong>
        </Box>
        <canvas
          key="signature"
          ref={canvasRef}
          width={width}
          height={height}
          style={{
            borderRadius: '4px',
            border: `1px solid ${theme.palette.divider}`,
            background: internalReadOnly ? theme.palette.action.disabledBackground : theme.palette.background.paper
          }}
          onMouseDown={onMouseDown}
          onMouseMove={onMouseMove}
          onMouseUp={onMouseUp}
          onTouchStart={onTouchStart}
          onTouchEnd={onTouchEnd}
          onTouchMove={onTouchMove}
        />
        {!readOnly && (
          <Box>
            {editing ? (
              <Button variant="contained" disabled={!url} onClick={handleDone}>
                {t('signature.done')}
              </Button>
            ) : (
              <Button variant="text" onClick={handleRemove}>
                {t('signature.remove')}
              </Button>
            )}
          </Box>
        )}
      </Stack>
    </Box>
  );
};

export default Signature;
