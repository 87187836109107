import { useMemo, useState } from 'react';

export type SetFormValidationData = (key: string, valid: boolean) => void;

export default function useFormValidationData(initiallyValidHint: boolean): [boolean, SetFormValidationData] {
  const [dataValid, setDataValid] = useState<Record<string, boolean>>();

  const formIsValid = useMemo(
    () =>
      dataValid
        ? Object.keys(dataValid).reduce((acc, key) => {
            return acc && dataValid[key];
          }, true)
        : dataValid === undefined
        ? initiallyValidHint
        : true,
    [initiallyValidHint, dataValid]
  );

  const setFormValidationData: SetFormValidationData = (key, valid) => {
    setDataValid((old) => ({ ...old, [key]: valid }));
  };

  return [formIsValid, setFormValidationData];
}
