import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import Page from '@maya/common/Page';
import useTranslate from '@maya/hooks/useTranslate';
import PatientOverviewForm from '@maya/patient/overview/PatientOverviewForm';

import type { FC } from 'react';

const PatientOverviewNewView: FC = () => {
  const t = useTranslate();
  const navigate = useNavigate();

  const endEditing = useCallback(() => {
    navigate('/patient/list');
  }, [navigate]);

  return (
    <Page title={t('patients.new.header')} backTo="/patient/list" color="paper">
      <PatientOverviewForm key="editing-form" onClose={endEditing} />
    </Page>
  );
};

export default PatientOverviewNewView;
