import Stack from '@mui/material/Stack';

import Empty from '@maya/common/Empty';
import Page from '@maya/common/Page';
import Loading from '@maya/common/loading/Loading';
import { PAGE_HEIGHT } from '@maya/constants';
import useTranslate from '@maya/hooks/useTranslate';
import { useAppSelector } from '@maya/store/hooks';
import { selectPayrollLoading } from '@maya/store/slices/payroll';
import VisitPayrollSummary from './VisitPayrollSummary';
import useEmployeeWages from './hooks/useEmployeeWages';

import type { FC } from 'react';

const FieldEmployeePayroll: FC = () => {
  const t = useTranslate();
  const wages = useEmployeeWages();

  const payrollLoading = useAppSelector(selectPayrollLoading);

  return (
    <Page title={t('payroll.header')} color="paper">
      <Stack gap={2} sx={{ minHeight: `calc(100vh - ${PAGE_HEIGHT}px)` }}>
        {payrollLoading ? (
          <Loading key="loading" />
        ) : wages.length === 0 ? (
          <Empty key="empty" image="list">
            {t('payroll.list.empty')}
          </Empty>
        ) : (
          wages.map((wage) => <VisitPayrollSummary key={wage.id} wage={wage} />)
        )}
      </Stack>
    </Page>
  );
};

export default FieldEmployeePayroll;
