import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import Page from '@maya/common/Page';
import useTranslate from '@maya/hooks/useTranslate';
import EmployeeForm from './EmployeeForm';

import type { FC } from 'react';

const NewEmployeeView: FC = () => {
  const t = useTranslate();
  const navigate = useNavigate();

  const endEditing = useCallback(() => {
    navigate('/employee/list');
  }, [navigate]);

  return (
    <Page title={t('employees.view.newHeader')} backTo="/employee/list" color="paper">
      <EmployeeForm key="editing-form" onClose={endEditing} />
    </Page>
  );
};

export default NewEmployeeView;
