import Box from '@mui/material/Box';
import { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Page from '@maya/common/Page';
import useTranslate from '@maya/hooks/useTranslate';
import { useAppSelector } from '@maya/store/hooks';
import { selectProfile } from '@maya/store/slices/auth';
import Schedule from './Schedule';
import Summary from './Summary';

import type { HeaderTabsConfig } from '@maya/common/Header';
import type { LoginDTO } from '@maya/interface';
import type { FC, ReactNode, SyntheticEvent } from 'react';

enum HeaderTabValue {
  DASHBOARD = 'dashboard',
  SCHEDULE = 'schedule'
}

const createActiveTabContent = (tabValue: HeaderTabValue): ReactNode => {
  switch (tabValue) {
    case HeaderTabValue.DASHBOARD:
      return <Summary />;
    case HeaderTabValue.SCHEDULE:
      return <Schedule />;
  }
};

const toTabType = (tab: string | undefined): HeaderTabValue => {
  switch (tab) {
    case HeaderTabValue.DASHBOARD:
    case HeaderTabValue.SCHEDULE:
      return tab;
    default:
      return HeaderTabValue.DASHBOARD;
  }
};

function formatName(profile: LoginDTO | undefined) {
  if (!profile) {
    return '';
  }

  let lastName = profile.lastName;
  if (lastName.endsWith('s')) {
    lastName += "'";
  } else {
    lastName += "'s";
  }

  return `${profile.firstName} ${lastName} `;
}

const Dashboard: FC = () => {
  const t = useTranslate();
  const { tab } = useParams();
  const tabValue = useMemo(() => toTabType(tab), [tab]);
  const navigate = useNavigate();

  const handleTabChange = useCallback(
    (_event: SyntheticEvent, newValue: HeaderTabValue) => {
      navigate(`/dashboard/${newValue}`);
    },
    [navigate]
  );

  const headerTabsConfig: HeaderTabsConfig<HeaderTabValue> = useMemo(
    () => ({
      value: tabValue,
      onChange: handleTabChange,
      tabs: [
        { label: t('dashboard.tabs.dashboard'), value: HeaderTabValue.DASHBOARD },
        { label: t('dashboard.tabs.schedule'), value: HeaderTabValue.SCHEDULE }
      ]
    }),
    [tabValue, handleTabChange, t]
  );

  const profile = useAppSelector(selectProfile);

  return (
    <Page title={`${formatName(profile)}${t('dashboard.header')}`} headerTabsConfig={headerTabsConfig} color="paper">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          height: '100%'
        }}
      >
        {createActiveTabContent(tabValue)}
      </Box>
    </Page>
  );
};

export default Dashboard;
