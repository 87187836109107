import { createSlice } from '@reduxjs/toolkit';
import { enqueueSnackbar } from 'notistack';

import Api from '@maya/api/api';
import fetch from '@maya/api/fetch';

import type { LoginDTO } from '@maya/interface';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { t } from 'react-polyglot';
import type { NavigateFunction } from 'react-router-dom';
import type { AppDispatch, RootState } from '../index';

// Define a type for the slice state
export interface AuthState {
  profile?: LoginDTO;
  isMayaAdmin: boolean;
  isBranchAdmin: boolean;
  isOffice: boolean;
  isField: boolean;
  isCompanyAdmin: boolean;
  inProgress: boolean;
  authCheckInProgress: boolean;
  authCheckComplete: boolean;
  failed: boolean;
}

// Define the initial state using that type
const initialState: AuthState = {
  isMayaAdmin: false,
  isBranchAdmin: false,
  isOffice: false,
  isField: false,
  isCompanyAdmin: false,
  inProgress: false,
  authCheckInProgress: false,
  authCheckComplete: false,
  failed: false
};

export const authSlice = createSlice({
  name: 'auth',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    startAuthCheck: (state) => {
      state.authCheckInProgress = true;
      state.failed = false;
    },
    endAuthCheck: (state) => {
      state.authCheckInProgress = false;
      state.authCheckComplete = true;
    },
    startAuthTransaction: (state) => {
      state.inProgress = true;
      state.failed = false;
    },
    endAuthCheckTransaction: (state) => {
      state.inProgress = false;
    },
    updateAuth: (state, action: PayloadAction<LoginDTO>) => {
      const loginResponse = action.payload;
      const has = (...roles: string[]): boolean => {
        return roles.indexOf(loginResponse.role) !== -1;
      };
      const isMayaAdmin = has('PECR_OWNER', 'PECR_ADMIN');
      const isCompanyAdmin = has('COMPANY_OWNER', 'COMPANY_ADMIN');
      const isBranchAdmin = isCompanyAdmin || has('BRANCH_ADMIN');
      const isOffice = isBranchAdmin || has('OFFICE');
      const isField = has('FIELD');

      state.isMayaAdmin = isMayaAdmin;
      state.isCompanyAdmin = isCompanyAdmin;
      state.isBranchAdmin = isBranchAdmin;
      state.isOffice = isOffice;
      state.isField = isField;

      state.profile = { ...loginResponse };

      state.inProgress = false;
      state.failed = false;
    },
    clearAuth: (state, action: PayloadAction<{ failed?: boolean } | undefined>) => {
      const { failed = false } = action.payload ?? {};

      state.profile = undefined;

      state.isMayaAdmin = false;
      state.isCompanyAdmin = false;
      state.isBranchAdmin = false;
      state.isOffice = false;
      state.isField = false;

      state.inProgress = false;

      state.failed = failed;
    }
  }
});

export const { updateAuth, clearAuth, startAuthCheck, endAuthCheck, startAuthTransaction, endAuthCheckTransaction } =
  authSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectAuthInProgress = (state: RootState) => state.auth.inProgress;
export const selectAuthCheckInProgress = (state: RootState) => state.auth.authCheckInProgress;
export const selectAuthCheckComplete = (state: RootState) => state.auth.authCheckComplete;

export const selectProfile = (state: RootState) => state.auth.profile;
export const selectIsMayaAdmin = (state: RootState) => state.auth.isMayaAdmin;
export const selectIsCompanyAdmin = (state: RootState) => state.auth.isCompanyAdmin;
export const selectIsBranchAdmin = (state: RootState) => state.auth.isBranchAdmin;
export const selectIsOffice = (state: RootState) => state.auth.isOffice;
export const selectIsField = (state: RootState) => state.auth.isField;

export const checkAuth = (t: t) => async (dispatch: AppDispatch) => {
  dispatch(startAuthCheck());

  const response = await fetch(Api.auth_Get, t, {});

  if (response) {
    dispatch(updateAuth(response));
  } else {
    dispatch(endAuthCheck());
  }
};

export const login =
  (username: string, password: string, t: t) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    if (state.auth.inProgress) {
      return;
    }

    dispatch(startAuthTransaction());

    const response = await fetch(Api.auth_LoginPost, t, {
      body: { username, password }
    });

    if (response) {
      dispatch(updateAuth(response));
    } else {
      enqueueSnackbar(t('auth.loginFailed'), { variant: 'error' });
      dispatch(clearAuth({ failed: true }));
    }
  };

export const verify =
  (username: string, token: string, password: string, navgiate: NavigateFunction, t: t) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    if (state.auth.inProgress) {
      return;
    }

    dispatch(startAuthTransaction());

    const { success } =
      (await fetch(Api.auth_VerifyPost, t, {
        body: { username, token, password },
        defaultError: 'auth.errors.verify'
      })) ?? {};

    dispatch(endAuthCheckTransaction());

    if (success) {
      navgiate('/');
    }
  };

export const resend =
  (username: string, navgiate: NavigateFunction, t: t) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    if (state.auth.inProgress) {
      return;
    }

    dispatch(startAuthTransaction());

    const { success } =
      (await fetch(Api.auth_ResendPost, t, {
        body: { username },
        defaultError: 'auth.errors.resend'
      })) ?? {};

    dispatch(endAuthCheckTransaction());

    if (success) {
      navgiate(`/verify?username=${username}`);
    }
  };

export const forgotPassword =
  (username: string, navgiate: NavigateFunction, t: t) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    if (state.auth.inProgress) {
      return;
    }

    dispatch(startAuthTransaction());

    const { success } =
      (await fetch(Api.auth_ForgotPasswordPost, t, {
        body: { username },
        defaultError: 'auth.errors.forgotPassword'
      })) ?? {};

    dispatch(endAuthCheckTransaction());

    if (success) {
      navgiate(`/reset-password?username=${username}`);
    }
  };

export const resetPassword =
  (username: string, token: string, password: string, navgiate: NavigateFunction, t: t) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    if (state.auth.inProgress) {
      return;
    }

    dispatch(startAuthTransaction());

    const { success } =
      (await fetch(Api.auth_ResetPasswordPost, t, {
        body: { username, token, password },
        defaultError: 'auth.errors.resetPassword'
      })) ?? {};

    dispatch(endAuthCheckTransaction());

    if (success) {
      navgiate('/');
    }
  };

export const logout = (navgiate: NavigateFunction, t: t) => async (dispatch: AppDispatch) => {
  await fetch(Api.auth_LogoutDelete, t, {});

  dispatch(clearAuth());

  navgiate('/');
};

export default authSlice.reducer;
