import { useEffect } from 'react';

import useBranchId from '@maya/hooks/useBranchId';
import useTranslate from '@maya/hooks/useTranslate';
import { useAppDispatch, useAppSelector } from '@maya/store/hooks';
import { loadAdminCompany, loadCompany, selectCompany } from '@maya/store/slices/company';

export default function useCompany(companyId: string | undefined) {
  const dispatch = useAppDispatch();
  const branchId = useBranchId();
  const t = useTranslate();

  useEffect(() => {
    if (!companyId) {
      if (!branchId) {
        return;
      }

      dispatch(loadCompany(branchId, t));
      return;
    }

    dispatch(loadAdminCompany(companyId, t));
  }, [dispatch, companyId, branchId, t]);

  return useAppSelector(selectCompany);
}
