import { useCallback, useMemo } from 'react';

import Autocomplete from '@maya/common/auto-complete/Autocomplete';
import useEmployees from '@maya/employee/hooks/useEmployees';

import type { EmployeeDTO } from '@maya/interface';
import type { FC, ReactNode } from 'react';

export interface EmployeeAutocompleteProps {
  'data-testid'?: string;
  label: string;
  defaultValue?: string | null;
  value?: string | null;
  onChange: (value?: string | null) => void;
  onValidate?: (valid: boolean) => void;
  required?: boolean;
  visitTypeId?: string;
  disabled?: boolean;
  helperText?: ReactNode;
}

type EmployeeMap = { [key: string]: EmployeeDTO };

const EmployeeAutocomplete: FC<EmployeeAutocompleteProps> = (props: EmployeeAutocompleteProps) => {
  const {
    disabled,
    required = false,
    label,
    value,
    defaultValue,
    onChange,
    onValidate,
    visitTypeId,
    helperText
  } = props;
  const employees = useEmployees('', visitTypeId);

  const employeeMap = employees.reduce((map, employee) => {
    map[employee.id] = employee;
    return map;
  }, {} as EmployeeMap);

  const employeeIdOptions = useMemo(() => Object.keys(employeeMap), [employeeMap]);
  const getOptionLabel = useCallback((value: string) => employeeMap[value]?.fullName ?? '', [employeeMap]);

  return employeeIdOptions.length ? (
    <Autocomplete
      data-testid={props['data-testid'] ?? 'employee-id'}
      required={required}
      label={label}
      getOptionLabel={getOptionLabel}
      defaultValue={defaultValue}
      value={value}
      options={employeeIdOptions}
      disabled={disabled}
      helperText={helperText}
      onChange={onChange}
      onValidate={onValidate}
    />
  ) : null;
};

export default EmployeeAutocomplete;
