import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

import FormattedDate from '@maya/common/FormattedDate';
import Loading from '@maya/common/loading/Loading';
import TableCell from '@maya/common/table/TableCell';
import useTranslate from '@maya/hooks/useTranslate';
import useRecords from '@maya/patient/hooks/useRecords';
import { useAppSelector } from '@maya/store/hooks';
import { selectRecordsTotal } from '@maya/store/slices/record';
import { transformFormCode } from '@maya/util/forms';

import type { PatientDTO } from '@maya/interface';
import type { FC } from 'react';

export interface RecordsProps {
  patient?: PatientDTO;
}
const Records: FC<RecordsProps> = ({ patient }) => {
  const t = useTranslate();
  const records = useRecords(patient?.id);
  const totalRecords = useAppSelector(selectRecordsTotal);

  return !patient ? (
    <Loading />
  ) : (
    <Card key="records-card" data-testid="patient-records-view">
      <CardContent
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          paddingBottom: '12px!important'
        }}
      >
        <TableContainer>
          <Table aria-label="record list" stickyHeader component="div">
            <TableHead component="div">
              <TableRow component="div">
                <TableCell>{t('patients.records.column.date')}</TableCell>
                <TableCell>{t('patients.records.column.document')}</TableCell>
                <TableCell>{t('patients.records.column.assignee')}</TableCell>
                <TableCell>{t('patients.records.column.discipline')}</TableCell>
                <TableCell>{t('patients.records.column.status')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody component="div">
              {records.map((record) => {
                const recordType = transformFormCode(record.type);
                return (
                  <TableRow
                    component={Link}
                    key={record.id}
                    data-testid={`record-${record.id}`}
                    sx={{ textDecoration: 'none', '&:last-child td, &:last-child th': { border: 0 } }}
                    hover
                    to={`/visit/${record.visitId}/${recordType}/${record.id}?backTo=${encodeURIComponent(
                      `/patient/details/${patient.id}/records`
                    )}`}
                  >
                    <TableCell>
                      <FormattedDate timestamp={record.scheduledDate} />
                    </TableCell>
                    <TableCell>{t(`forms.${record.type.toLowerCase()}`)}</TableCell>
                    <TableCell>{record.assignedTo}</TableCell>
                    <TableCell>{record.discipline}</TableCell>
                    <TableCell>{t(`patients.records.status.${record.status}`)}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Typography variant="body2" sx={{ textAlign: 'right' }} data-testid="total-rows">
          {t('app.totalRows')} {totalRecords}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default Records;
