import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Fab from '@mui/material/Fab';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Checkbox from '@maya/common/checkbox/Checkbox';
import DateField from '@maya/common/date/DateField';
import RadioGroup from '@maya/common/radio-group/RadioGroup';
import Select from '@maya/common/select/Select';
import NumberField from '@maya/common/textfield/NumberField';
import TextField from '@maya/common/textfield/TextField';
import { HEADER_WITH_TABS_HEIGHT } from '@maya/constants';
import useBranchId from '@maya/hooks/useBranchId';
import { useIsSmallScreen } from '@maya/hooks/useMediaQuery';
import useTranslate from '@maya/hooks/useTranslate';
import {
  Attentiveness,
  BathingDressing,
  BathingEntirety,
  BathingLowerSafeLevelIf,
  BathingType,
  BedLinensChanged,
  BladderBowelUsability,
  CareNeeds,
  Frequency,
  ROM,
  RecordStatus,
  TemperatureUnit,
  UsesOxygenType,
  WeightBearing,
  WeightUnit
} from '@maya/interface';
import { useAppDispatch, useAppSelector } from '@maya/store/hooks';
import { saveTaskList, selectTaskListSaveInProgress } from '@maya/store/slices/task-list';

import type { Option, SelectProps } from '@maya/common/select/Select';
import type { TextFieldProps } from '@maya/common/textfield/TextField';
import type { TaskListDTO, UpdateTaskListDTO, VisitDTO } from '@maya/interface';
import type { StackProps } from '@mui/material/Stack/Stack';
import type { TypographyProps } from '@mui/material/Typography';
import type { SxProps } from '@mui/material/styles';
import type { Theme } from '@mui/material/styles/createTheme';
import type { ElementType, FC } from 'react';

const toUpdateDTO = (taskList?: TaskListDTO): UpdateTaskListDTO => {
  const {
    id: _id,
    employeeId: _employeeId,
    infoShoulderSurgery,
    infoShoulderSurgeryLeft,
    infoShoulderSurgeryRight,
    infoSpecialPrecautions,
    infoSpecialPrecautionsGloves,
    infoSpecialPrecautionsGown,
    infoSpecialPrecautionsMask,
    status,
    urineOtherText,
    usesOxygen,
    usesOxygenType,
    usesOxygenTypeOtherText,
    temperatureUnit,
    weightUnit,
    ...rest
  } = taskList ?? {};

  return {
    infoShoulderSurgery: Boolean(infoShoulderSurgery),
    infoShoulderSurgeryLeft: Boolean(infoShoulderSurgeryLeft),
    infoShoulderSurgeryRight: Boolean(infoShoulderSurgeryRight),
    infoSpecialPrecautions: Boolean(infoSpecialPrecautions),
    infoSpecialPrecautionsGloves: Boolean(infoSpecialPrecautionsGloves),
    infoSpecialPrecautionsGown: Boolean(infoSpecialPrecautionsGown),
    infoSpecialPrecautionsMask: Boolean(infoSpecialPrecautionsMask),
    status: status ?? RecordStatus.pending,
    urineOtherText: urineOtherText ?? '',
    usesOxygen: Boolean(usesOxygen),
    usesOxygenType: usesOxygenType ?? undefined,
    usesOxygenTypeOtherText: usesOxygenTypeOtherText ?? '',
    temperatureUnit: temperatureUnit ?? TemperatureUnit.FAHRENHEIT,
    weightUnit: weightUnit ?? WeightUnit.POUND,
    ...rest
  };
};

const TabHeading: FC<TypographyProps<ElementType> & { noPadding?: boolean }> = ({
  noPadding = false,
  children,
  ...props
}) => (
  <Typography component="h3" variant="subtitle1" sx={{ padding: noPadding ? undefined : '16px 0' }} {...props}>
    {children}
  </Typography>
);

const SectionHeading: FC<TypographyProps<ElementType>> = ({ children, ...props }) => (
  <Typography component="h4" variant="subtitle1" {...props}>
    {children}
  </Typography>
);

export const headerTabValues = [
  'information',
  'vitals',
  'bathing',
  'personal-care',
  'ambulation-locomotion',
  'other',
  'report'
] as const;
export type HeaderTabValue = (typeof headerTabValues)[number];

export const makeTabAnchorId = (tabValue: HeaderTabValue) => `tab-anchor-${tabValue}`;

const fromTabAnchorId = (tabValue?: string): HeaderTabValue | undefined => {
  const value = tabValue?.replace('tab-anchor-', '') as HeaderTabValue | undefined;
  return value && headerTabValues.includes(value) ? value : undefined;
};

const SectionStack: FC<
  StackProps & {
    heading?: string;
    variant?: 'tab-anchor';
    noIndent?: boolean;
    topBorder?: boolean;
    tabValue?: HeaderTabValue;
    noHeaderPadding?: boolean;
  }
> = ({ heading, variant, noIndent, children, topBorder, tabValue, noHeaderPadding, ...props }) => {
  const tabAnchor = variant === 'tab-anchor';
  const containerSx: SxProps<Theme> = (theme) =>
    topBorder
      ? {
          borderTop: `1px solid ${theme.palette.divider}`
        }
      : null;

  return (
    <Box sx={containerSx}>
      {heading ? (
        tabAnchor ? (
          <TabHeading id={tabValue ? makeTabAnchorId(tabValue) : null} noPadding={noHeaderPadding}>
            {heading}
          </TabHeading>
        ) : (
          <SectionHeading>{heading}</SectionHeading>
        )
      ) : null}
      <Stack sx={{ paddingLeft: noIndent ? 0 : '11px' }} {...props}>
        {children}
      </Stack>
    </Box>
  );
};

const NestedStack: FC<StackProps> = ({ children, ...props }) => (
  <Stack pl="32px" {...props}>
    {children}
  </Stack>
);

interface OtherTextFieldProps extends TextFieldProps {
  show: boolean;
}
const HideableTextField: FC<OtherTextFieldProps> = ({ show, ...props }) =>
  show ? <TextField helperText="" {...props} /> : null;

type FrequencySelectProps = Omit<SelectProps<Frequency | undefined>, 'label' | 'options'>;

const FrequencySelect: FC<FrequencySelectProps> = ({ ...props }: FrequencySelectProps) => {
  const t = useTranslate();
  const frequencySelectOptions = useMemo(
    (): Option<Frequency>[] => [
      {
        label: t('taskList.form.frequencies.NA'),
        value: Frequency.NA
      },
      {
        label: t('taskList.form.frequencies.ONCE_A_WEEK'),
        value: Frequency.ONCE_A_WEEK
      },
      {
        label: t('taskList.form.frequencies.BI_WEEKLY'),
        value: Frequency.BI_WEEKLY
      },
      {
        label: t('taskList.form.frequencies.EACH_VISIT'),
        value: Frequency.EACH_VISIT
      },
      {
        label: t('taskList.form.frequencies.OTHER'),
        value: Frequency.OTHER
      }
    ],
    [t]
  );

  return <Select label={t('taskList.form.frequency')} options={frequencySelectOptions} {...props} />;
};

const useIntersectionObserver = (setActiveId: (activeId: string) => void) => {
  const headingElementsRef = useRef<Record<string, IntersectionObserverEntry>>({});

  useLayoutEffect(() => {
    const headingElements = Array.from(document.querySelectorAll<HTMLHeadingElement>('h3[id]'));

    if (headingElementsRef.current) {
      headingElementsRef.current = {};
    }

    const callback: IntersectionObserverCallback = (headings) => {
      headingElementsRef.current = headings.reduce((map, headingElement) => {
        map[headingElement.target.id] = headingElement;
        return map;
      }, headingElementsRef.current);

      // Get all headings that are currently visible on the page
      const visibleHeadings: IntersectionObserverEntry[] = [];
      Object.keys(headingElementsRef.current).forEach((key) => {
        const headingElement = headingElementsRef.current[key];
        if (headingElement.isIntersecting) {
          visibleHeadings.push(headingElement);
        }
      });

      const getIndexFromId = (id: string) => headingElements.findIndex((heading) => heading.id === id);

      // If there is only one visible heading, this is our "active" heading
      if (visibleHeadings.length === 1) {
        setActiveId(visibleHeadings[0].target.id);
        // If there is more than one visible heading,
        // choose the one that is closest to the top of the page
      } else if (visibleHeadings.length > 1) {
        const sortedVisibleHeadings = visibleHeadings.sort((a, b) =>
          getIndexFromId(a.target.id) > getIndexFromId(b.target.id) ? 1 : -1
        );

        setActiveId(sortedVisibleHeadings[0].target.id);
      }
    };

    const observer = new IntersectionObserver(callback, {
      rootMargin: '0px 0px -36px 0px'
    });

    headingElements.forEach((element) => observer.observe(element));

    return () => {
      observer.disconnect();
    };
  }, [setActiveId]);
};

const NOTES_WIDTH = 336;
const NOTES_TOP_OFFSET = 24;
const FORM_WIDTH_OFFSET = 360;

export interface TaskListFormProps {
  id: string;
  taskList: TaskListDTO;
  visit: VisitDTO;
  readOnly?: boolean;
  onTabChange: (tab: HeaderTabValue) => void;
}

const TaskListForm: FC<TaskListFormProps> = ({ visit, id, taskList, readOnly, onTabChange }) => {
  const t = useTranslate();
  const [data, setData] = useState<UpdateTaskListDTO>(toUpdateDTO(taskList));

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const branchId = useBranchId();
  const saving = useAppSelector(selectTaskListSaveInProgress);

  const disabled = saving;

  const saveForm = useCallback(
    async (status: RecordStatus) => {
      if (!branchId) {
        return;
      }

      await dispatch(saveTaskList(branchId, visit.id, id, { ...data, status }, t));
      navigate(`/visit/${visit.id}`);
    },
    [branchId, dispatch, visit.id, id, data, t, navigate]
  );

  const handleSubmit = useCallback(() => {
    saveForm(RecordStatus.submitted);
  }, [saveForm]);

  const handleSave = useCallback(() => {
    saveForm(RecordStatus.saved);
  }, [saveForm]);

  const updateData = useCallback(
    (newData: Partial<UpdateTaskListDTO>) => {
      setData((oldData) => ({
        ...oldData,
        ...newData
      }));
    },
    [setData]
  );

  const handleInfoSpecialPrecautionsChange = (
    fieldName:
      | 'infoSpecialPrecautions'
      | 'infoSpecialPrecautionsMask'
      | 'infoSpecialPrecautionsGown'
      | 'infoSpecialPrecautionsGloves',
    value: boolean
  ) => {
    const updates: Partial<UpdateTaskListDTO> = { [fieldName]: value };
    if (value) {
      updates.infoSpecialPrecautions = true;
    } else {
      if (fieldName === 'infoSpecialPrecautions') {
        updates.infoSpecialPrecautionsMask = false;
        updates.infoSpecialPrecautionsGown = false;
        updates.infoSpecialPrecautionsGloves = false;
      }
    }
    updateData(updates);
  };

  const handleInfoShoulderSurgeryChange = (
    fieldName: 'infoShoulderSurgery' | 'infoShoulderSurgeryLeft' | 'infoShoulderSurgeryRight',
    value: boolean
  ) => {
    const updates: Partial<UpdateTaskListDTO> = { [fieldName]: value };
    if (value) {
      updates.infoShoulderSurgery = true;
    } else {
      if (fieldName === 'infoShoulderSurgery') {
        updates.infoShoulderSurgeryLeft = false;
        updates.infoShoulderSurgeryRight = false;
      }
    }
    updateData(updates);
  };

  const handleUsesOxygenChange = (fieldName: 'usesOxygen' | 'usesOxygenType', value: boolean | string) => {
    const updates: Partial<UpdateTaskListDTO> = { [fieldName]: typeof value === 'boolean' ? value : +value };
    if (value) {
      updates.usesOxygen = true;
    } else {
      if (fieldName === 'usesOxygen') {
        updates.usesOxygenType = undefined;
        updates.usesOxygenTypeOtherText = '';
      }
    }
    updateData(updates);
  };

  const [activeId, setActiveId] = useState<string>();
  useIntersectionObserver(setActiveId);

  useEffect(() => {
    const newTab = fromTabAnchorId(activeId);
    if (newTab) {
      onTabChange(newTab);
    }
  }, [activeId, onTabChange]);

  const isSmallScreen = useIsSmallScreen();

  const [visitNotesOpen, setVisitNotesOpen] = useState(false);
  const handleVisitNotesOpen = () => {
    setVisitNotesOpen(true);
  };

  const handleVisitNotesClose = () => {
    setVisitNotesOpen(false);
  };

  return (
    <Stack
      sx={{
        width: isSmallScreen ? '100%' : `calc(100% - ${FORM_WIDTH_OFFSET}px)`,
        gap: isSmallScreen ? '16px' : undefined
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          position: isSmallScreen ? undefined : 'fixed',
          right: '12px',
          top: HEADER_WITH_TABS_HEIGHT + NOTES_TOP_OFFSET,
          gap: '4px',
          width: isSmallScreen ? '100%' : NOTES_WIDTH,
          borderRadius: '6px',
          padding: '24px',
          background: 'linear-gradient(0deg, rgba(103, 58, 183, 0.08), rgba(103, 58, 183, 0.08) ), #ffffff'
        }}
      >
        <Typography variant="subtitle1">{t('patients.visit.notes')}</Typography>
        {visit.notes ?? t('app.noNotes')}
      </Box>
      {isSmallScreen ? (
        <>
          <Fab
            color="secondary"
            variant="extended"
            sx={{ position: 'fixed', bottom: '12px', right: '12px' }}
            onClick={handleVisitNotesOpen}
          >
            {t('patients.visit.notes')}
          </Fab>
          <Dialog
            open={visitNotesOpen}
            onClose={handleVisitNotesClose}
            aria-labelledby="visit-notes-dialog-title"
            aria-describedby="visit-notes-dialog-description"
            fullWidth
          >
            <DialogTitle id="visit-notes-dialog-title">{t('patients.visit.notes')}</DialogTitle>
            <IconButton onClick={handleVisitNotesClose} sx={{ position: 'absolute', top: '12px', right: '12px' }}>
              <CloseIcon />
            </IconButton>
            <DialogContent sx={{ paddingTop: 0 }}>
              <DialogContentText id="visit-notes-dialog-description">
                {visit.notes ?? t('app.noNotes')}
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </>
      ) : null}
      <Stack gap="16px" paddingBottom="16px">
        <SectionStack
          heading={t('taskList.heading.information')}
          variant="tab-anchor"
          tabValue="information"
          noHeaderPadding
        >
          <Checkbox
            data-testid="infoUniversalPrecautions"
            label={t('taskList.form.universalPrecautions')}
            defaultChecked={Boolean(data?.infoUniversalPrecautions)}
            onChange={(newValue) => updateData({ infoUniversalPrecautions: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          <Checkbox
            data-testid="infoSpecialPrecautions"
            label={t('taskList.form.specialPrecautions')}
            checked={data.infoSpecialPrecautions}
            onChange={(newValue) => handleInfoSpecialPrecautionsChange('infoSpecialPrecautions', newValue)}
            disabled={disabled}
            readOnly={readOnly}
          />
          <NestedStack>
            <Checkbox
              data-testid="infoSpecialPrecautionsMask"
              label={t('taskList.form.specialPrecautionsMask')}
              checked={Boolean(data.infoSpecialPrecautionsMask)}
              onChange={(newValue) => handleInfoSpecialPrecautionsChange('infoSpecialPrecautionsMask', newValue)}
              disabled={disabled}
              readOnly={readOnly}
            />
            <Checkbox
              data-testid="infoSpecialPrecautionsGloves"
              label={t('taskList.form.specialPrecautionsGloves')}
              checked={Boolean(data.infoSpecialPrecautionsGloves)}
              onChange={(newValue) => handleInfoSpecialPrecautionsChange('infoSpecialPrecautionsGloves', newValue)}
              disabled={disabled}
              readOnly={readOnly}
            />
            <Checkbox
              data-testid="infoSpecialPrecautionsGown"
              label={t('taskList.form.specialPrecautionsGown')}
              checked={Boolean(data.infoSpecialPrecautionsGown)}
              onChange={(newValue) => handleInfoSpecialPrecautionsChange('infoSpecialPrecautionsGown', newValue)}
              disabled={disabled}
              readOnly={readOnly}
            />
          </NestedStack>
          <Checkbox
            data-testid="infoDoNotResuscitate"
            label={t('taskList.form.doNotResuscitate')}
            defaultChecked={data.infoDoNotResuscitate}
            onChange={(newValue) => updateData({ infoDoNotResuscitate: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          <Checkbox
            data-testid="infoHeartCondition"
            label={t('taskList.form.heartCondition')}
            defaultChecked={data.infoHeartCondition}
            onChange={(newValue) => updateData({ infoHeartCondition: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          <Checkbox
            data-testid="infoRespiratoryCondition"
            label={t('taskList.form.respiratoryCondition')}
            defaultChecked={data.infoRespiratoryCondition}
            onChange={(newValue) => updateData({ infoRespiratoryCondition: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          <Checkbox
            data-testid="infoParesis"
            label={t('taskList.form.paresis')}
            defaultChecked={data.infoParesis}
            onChange={(newValue) => updateData({ infoParesis: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          <Checkbox
            data-testid="infoDiabetes"
            label={t('taskList.form.diabetes')}
            defaultChecked={data.infoDiabetes}
            onChange={(newValue) => updateData({ infoDiabetes: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          <Checkbox
            data-testid="infoKnee"
            label={t('taskList.form.knee')}
            defaultChecked={data.infoKnee}
            onChange={(newValue) => updateData({ infoKnee: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          <Checkbox
            data-testid="infoHip"
            label={t('taskList.form.hip')}
            defaultChecked={data.infoHip}
            onChange={(newValue) => updateData({ infoHip: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          <Checkbox
            data-testid="infoShoulderSurgery"
            label={t('taskList.form.shoulderSurgery')}
            checked={data.infoShoulderSurgery}
            onChange={(newValue) => handleInfoShoulderSurgeryChange('infoShoulderSurgery', newValue)}
            disabled={disabled}
            readOnly={readOnly}
          />
          <NestedStack>
            <Checkbox
              data-testid="infoShoulderSurgeryLeft"
              label={t('taskList.form.shoulderSurgeryLeft')}
              checked={data.infoShoulderSurgeryLeft}
              onChange={(newValue) => handleInfoShoulderSurgeryChange('infoShoulderSurgeryLeft', newValue)}
              disabled={disabled}
              readOnly={readOnly}
            />
            <Checkbox
              data-testid="infoShoulderSurgeryRight"
              label={t('taskList.form.shoulderSurgeryRight')}
              checked={data.infoShoulderSurgeryRight}
              onChange={(newValue) => handleInfoShoulderSurgeryChange('infoShoulderSurgeryRight', newValue)}
              disabled={disabled}
              readOnly={readOnly}
            />
          </NestedStack>
          <Checkbox
            data-testid="infoWearsGlasses"
            label={t('taskList.form.wearsGlasses')}
            defaultChecked={data.infoWearsGlasses}
            onChange={(newValue) => updateData({ infoWearsGlasses: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          <Checkbox
            data-testid="infoWearsContacts"
            label={t('taskList.form.wearsContacts')}
            defaultChecked={data.infoWearsContacts}
            onChange={(newValue) => updateData({ infoWearsContacts: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          <Box sx={{ padding: '8px 0' }}>
            <TextField
              data-testid="infoOtherText"
              label={t('taskList.form.infoOther')}
              defaultValue={data.infoOtherText}
              onChange={(newValue) => updateData({ infoOtherText: newValue })}
              disabled={disabled}
              readOnly={readOnly}
              helperText=""
            />
          </Box>
        </SectionStack>
        <SectionStack heading={t('taskList.heading.attentiveness')}>
          <RadioGroup
            data-testid="attentiveness"
            options={[
              {
                label: t(`taskList.form.attentiveness.ALERT_AND_ORIENTED`),
                option: Attentiveness.ALERT_AND_ORIENTED,
                disabled
              },
              {
                label: t(`taskList.form.attentiveness.CONFUSED`),
                option: Attentiveness.CONFUSED,
                disabled
              },
              {
                label: t(`taskList.form.attentiveness.OTHER`),
                option: Attentiveness.OTHER,
                disabled
              }
            ]}
            defaultValue={data.attentiveness}
            onChange={(newValue) => updateData({ attentiveness: +newValue as Attentiveness })}
            disabled={disabled}
            readOnly={readOnly}
            helperText=""
          />
          <HideableTextField
            show={data.attentiveness === Attentiveness.OTHER}
            data-testid="attentivenessOtherText"
            label={t('taskList.form.attentivenessOtherTextLabel')}
            defaultValue={data.attentivenessOtherText}
            onChange={(newValue) => updateData({ attentivenessOtherText: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
        </SectionStack>
        <SectionStack heading={t('taskList.heading.amputation')}>
          <Checkbox
            data-testid="amputationAKALeft"
            label={t('taskList.form.amputationAKALeft')}
            defaultChecked={data.amputationAKALeft}
            onChange={(newValue) => updateData({ amputationAKALeft: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          <Checkbox
            data-testid="amputationAKARight"
            label={t('taskList.form.amputationAKARight')}
            defaultChecked={data.amputationAKARight}
            onChange={(newValue) => updateData({ amputationAKARight: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          <Checkbox
            data-testid="amputationBKALeft"
            label={t('taskList.form.amputationBKALeft')}
            defaultChecked={data.amputationBKALeft}
            onChange={(newValue) => updateData({ amputationBKALeft: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          <Checkbox
            data-testid="amputationBKARight"
            label={t('taskList.form.amputationBKARight')}
            defaultChecked={data.amputationBKARight}
            onChange={(newValue) => updateData({ amputationBKARight: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
        </SectionStack>
        <SectionStack heading={t('taskList.heading.paresis')}>
          <Checkbox
            data-testid="paresisAKALeft"
            label={t('taskList.form.paresisAKALeft')}
            defaultChecked={data.paresisAKALeft}
            onChange={(newValue) => updateData({ paresisAKALeft: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          <Checkbox
            data-testid="paresisAKARight"
            label={t('taskList.form.paresisAKARight')}
            defaultChecked={data.paresisAKARight}
            onChange={(newValue) => updateData({ paresisAKARight: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          <Checkbox
            data-testid="paresisBKALeft"
            label={t('taskList.form.paresisBKALeft')}
            defaultChecked={data.paresisBKALeft}
            onChange={(newValue) => updateData({ paresisBKALeft: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          <Checkbox
            data-testid="paresisBKARight"
            label={t('taskList.form.paresisBKARight')}
            defaultChecked={data.paresisBKARight}
            onChange={(newValue) => updateData({ paresisBKARight: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
        </SectionStack>
        <SectionStack heading={t('taskList.heading.hearingAids')}>
          <Checkbox
            data-testid="hearingAidsLeft"
            label={t('taskList.form.hearingAidsLeft')}
            defaultChecked={data.hearingAidsLeft}
            onChange={(newValue) => updateData({ hearingAidsLeft: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          <Checkbox
            data-testid="hearingAidsRight"
            label={t('taskList.form.hearingAidsRight')}
            defaultChecked={data.hearingAidsRight}
            onChange={(newValue) => updateData({ hearingAidsRight: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
        </SectionStack>
        <SectionStack heading={t('taskList.heading.dentures')}>
          <Checkbox
            data-testid="denturesUpper"
            label={t('taskList.form.denturesUpper')}
            defaultChecked={data.denturesUpper}
            onChange={(newValue) => updateData({ denturesUpper: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          <Checkbox
            data-testid="denturesLower"
            label={t('taskList.form.denturesLower')}
            defaultChecked={data.denturesLower}
            onChange={(newValue) => updateData({ denturesLower: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
        </SectionStack>
        <SectionStack heading={t('taskList.heading.oxygen')}>
          <Checkbox
            data-testid="usesOxygen"
            label={t('taskList.form.usesOxygen')}
            checked={data.usesOxygen}
            onChange={(newValue) => handleUsesOxygenChange('usesOxygen', newValue)}
            disabled={disabled}
            readOnly={readOnly}
          />
          <NestedStack>
            <RadioGroup
              data-testid="usesOxygenType"
              options={[
                {
                  label: t(`taskList.form.usesOxygenType.CONTINUOUS`),
                  option: UsesOxygenType.CONTINUOUS,
                  disabled
                },
                {
                  label: t(`taskList.form.usesOxygenType.PRN`),
                  option: UsesOxygenType.PRN,
                  disabled
                },
                {
                  label: t(`taskList.form.usesOxygenType.OTHER`),
                  option: UsesOxygenType.OTHER,
                  disabled
                }
              ]}
              value={data.usesOxygenType ?? null}
              onChange={(newValue) => handleUsesOxygenChange('usesOxygenType', newValue)}
              disabled={disabled}
              readOnly={readOnly}
              helperText=""
            />
            <HideableTextField
              data-testid="usesOxygenTypeOtherText"
              show={data.usesOxygenType === UsesOxygenType.OTHER}
              label={t('taskList.form.usesOxygenOtherTextLabel')}
              value={data.usesOxygenTypeOtherText}
              onChange={(newValue) => updateData({ usesOxygenTypeOtherText: newValue })}
              disabled={disabled}
              readOnly={readOnly}
            />
          </NestedStack>
        </SectionStack>
        <SectionStack heading={t('taskList.heading.urinaryBladder')}>
          <RadioGroup
            data-testid="urinaryBladder"
            options={[
              {
                label: t(`taskList.form.bladderBowelUsability.CONTINENT`),
                option: BladderBowelUsability.CONTINENT,
                disabled
              },
              {
                label: t(`taskList.form.bladderBowelUsability.INCONTINENT`),
                option: BladderBowelUsability.INCONTINENT,
                disabled
              }
            ]}
            defaultValue={data.urinaryBladder}
            onChange={(newValue) => updateData({ urinaryBladder: +newValue as BladderBowelUsability })}
            disabled={disabled}
            readOnly={readOnly}
            helperText=""
          />
        </SectionStack>
        <SectionStack heading={t('taskList.heading.bowel')}>
          <RadioGroup
            data-testid="bowel"
            options={[
              {
                label: t(`taskList.form.bladderBowelUsability.CONTINENT`),
                option: BladderBowelUsability.CONTINENT,
                disabled
              },
              {
                label: t(`taskList.form.bladderBowelUsability.INCONTINENT`),
                option: BladderBowelUsability.INCONTINENT,
                disabled
              }
            ]}
            defaultValue={data.bowel}
            onChange={(newValue) => updateData({ bowel: +newValue as BladderBowelUsability })}
            disabled={disabled}
            readOnly={readOnly}
            helperText=""
          />
        </SectionStack>
        <SectionStack heading={t('taskList.heading.urine')}>
          <Checkbox
            data-testid="urineUrinaryCatheter"
            label={t('taskList.form.urineUrinaryCatheter')}
            defaultChecked={data.urineUrinaryCatheter}
            onChange={(newValue) => updateData({ urineUrinaryCatheter: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          <Checkbox
            data-testid="urineOstomy"
            label={t('taskList.form.urineOstomy')}
            defaultChecked={data.urineOstomy}
            onChange={(newValue) => updateData({ urineOstomy: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          <Checkbox
            data-testid="urineOther"
            label={t('taskList.form.urineOther')}
            defaultChecked={data.urineOther}
            onChange={(newValue) => updateData({ urineOther: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          <HideableTextField
            data-testid="urineOtherText"
            show={Boolean(data.urineOther)}
            label={t('taskList.form.urineOtherTextLabel')}
            value={data.urineOtherText}
            onChange={(newValue) => updateData({ urineOtherText: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
        </SectionStack>
        <TextField
          data-testid="surgeryProcedure"
          label={t('taskList.form.surgeryProcedure')}
          defaultValue={data.surgeryProcedure}
          onChange={(newValue) => updateData({ surgeryProcedure: newValue })}
          multiline={true}
          minRows={3}
          disabled={disabled}
          readOnly={readOnly}
        />
        <TextField
          data-testid="dressingBandage"
          label={t('taskList.form.dressingBandage')}
          defaultValue={data.dressingBandage}
          onChange={(newValue) => updateData({ dressingBandage: newValue })}
          multiline={true}
          minRows={3}
          disabled={disabled}
          readOnly={readOnly}
        />
        <TextField
          data-testid="diet"
          label={t('taskList.form.diet')}
          defaultValue={data.diet}
          onChange={(newValue) => updateData({ diet: newValue })}
          multiline={true}
          minRows={3}
          disabled={disabled}
          readOnly={readOnly}
        />
        <TextField
          data-testid="fluids"
          label={t('taskList.form.fluids')}
          defaultValue={data.fluids}
          onChange={(newValue) => updateData({ fluids: newValue })}
          multiline={true}
          minRows={3}
          disabled={disabled}
          readOnly={readOnly}
        />
        <SectionStack heading={t('taskList.heading.activity')}>
          <Checkbox
            data-testid="activityUpAsTolerated"
            label={t('taskList.form.activityUpAsTolerated')}
            defaultChecked={data.activityUpAsTolerated}
            onChange={(newValue) => updateData({ activityUpAsTolerated: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          <Checkbox
            data-testid="activityBedbound"
            label={t('taskList.form.activityBedbound')}
            defaultChecked={data.activityBedbound}
            onChange={(newValue) => updateData({ activityBedbound: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          <Checkbox
            data-testid="activityBedrestWithBRP"
            label={t('taskList.form.activityBedrestWithBRP')}
            defaultChecked={data.activityBedrestWithBRP}
            onChange={(newValue) => updateData({ activityBedrestWithBRP: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          <Checkbox
            data-testid="activityUpInChair"
            label={t('taskList.form.activityUpInChair')}
            defaultChecked={data.activityUpInChair}
            onChange={(newValue) => updateData({ activityUpInChair: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
        </SectionStack>
      </Stack>
      <SectionStack
        heading={t('taskList.heading.vitals')}
        variant="tab-anchor"
        noIndent={true}
        topBorder={true}
        tabValue="vitals"
      >
        <Stack direction="row" spacing={2}>
          <NumberField
            data-testid="temperature"
            label={t('taskList.form.temperature')}
            defaultValue={data.temperature}
            onChange={(temperature) => updateData({ temperature })}
            disabled={disabled}
            readOnly={readOnly}
          />
          <Select
            data-testid="temperatureUnit"
            options={[
              {
                label: t(`taskList.form.temperatureUnit.FAHRENHEIT`),
                value: TemperatureUnit.FAHRENHEIT
              },
              {
                label: t(`taskList.form.temperatureUnit.CELSIUS`),
                value: TemperatureUnit.CELSIUS
              }
            ]}
            defaultValue={data.temperatureUnit}
            onChange={(temperatureUnit) => updateData({ temperatureUnit })}
            disabled={disabled}
            readOnly={readOnly}
          />
          <FrequencySelect
            data-testid="temperatureFrequency"
            defaultValue={data.temperatureFrequency}
            onChange={(temperatureFrequency) => updateData({ temperatureFrequency })}
            disabled={disabled}
            readOnly={readOnly}
          />
        </Stack>
        {data.temperatureFrequency === Frequency.OTHER ? (
          <Stack direction="row" spacing={2}>
            <TextField
              data-testid="temperatureFrequencyOtherText"
              label={t('taskList.form.temperatureOtherText')}
              defaultValue={data.temperatureFrequencyOtherText}
              onChange={(newValue) => updateData({ temperatureFrequencyOtherText: newValue })}
              disabled={disabled}
              readOnly={readOnly}
            />
          </Stack>
        ) : null}
        <Stack direction="row" spacing={2}>
          <NumberField
            data-testid="weight"
            label={t('taskList.form.weight')}
            defaultValue={data.weight}
            onChange={(newValue) => updateData({ weight: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          <Select
            data-testid="weightUnit"
            options={[
              {
                label: t(`taskList.form.weightUnit.POUND`),
                value: WeightUnit.POUND
              },
              {
                label: t(`taskList.form.weightUnit.KILOGRAM`),
                value: WeightUnit.KILOGRAM
              }
            ]}
            defaultValue={data.weightUnit}
            onChange={(newValue) => updateData({ weightUnit: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          <FrequencySelect
            data-testid="weightFrequency"
            defaultValue={data.weightFrequency}
            onChange={(newValue) => updateData({ weightFrequency: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
        </Stack>
        {data.weightFrequency === Frequency.OTHER ? (
          <Stack direction="row" spacing={2}>
            <TextField
              data-testid="weightFrequencyOtherText"
              label={t('taskList.form.weightOtherText')}
              defaultValue={data.weightFrequencyOtherText}
              onChange={(newValue) => updateData({ weightFrequencyOtherText: newValue })}
              disabled={disabled}
              readOnly={readOnly}
            />
          </Stack>
        ) : null}
        <Stack direction="row" spacing={2}>
          <TextField
            data-testid="bloodPressure"
            label={t('taskList.form.bloodPressure')}
            defaultValue={data.bloodPressure}
            onChange={(newValue) => updateData({ bloodPressure: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          <FrequencySelect
            data-testid="bloodPressureFrequency"
            defaultValue={data.bloodPressureFrequency}
            onChange={(newValue) => updateData({ bloodPressureFrequency: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
        </Stack>
        {data.bloodPressureFrequency === Frequency.OTHER ? (
          <Stack direction="row" spacing={2}>
            <TextField
              data-testid="bloodPressureFrequencyOtherText"
              label={t('taskList.form.bloodPressureFrequencyOtherText')}
              defaultValue={data.bloodPressureFrequencyOtherText}
              onChange={(newValue) => updateData({ bloodPressureFrequencyOtherText: newValue })}
              disabled={disabled}
              readOnly={readOnly}
            />
          </Stack>
        ) : null}
        <Stack direction="row" spacing={2}>
          <NumberField
            data-testid="pulse"
            label={t('taskList.form.pulse')}
            defaultValue={data.pulse}
            onChange={(newValue) => updateData({ pulse: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          <FrequencySelect
            data-testid="pulseFrequency"
            defaultValue={data.pulseFrequency}
            onChange={(newValue) => updateData({ pulseFrequency: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
        </Stack>
        {data.pulseFrequency === Frequency.OTHER ? (
          <Stack direction="row" spacing={2}>
            <TextField
              data-testid="pulseFrequencyOtherText"
              label={t('taskList.form.pulseFrequencyOtherText')}
              defaultValue={data.pulseFrequencyOtherText}
              onChange={(newValue) => updateData({ pulseFrequencyOtherText: newValue })}
              disabled={disabled}
              readOnly={readOnly}
            />
          </Stack>
        ) : null}
        <Stack direction="row" spacing={2}>
          <NumberField
            data-testid="respiration"
            label={t('taskList.form.respiration')}
            defaultValue={data.respiration}
            onChange={(newValue) => updateData({ respiration: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          <FrequencySelect
            data-testid="respirationFrequency"
            defaultValue={data.respirationFrequency}
            onChange={(newValue) => updateData({ respirationFrequency: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
        </Stack>
        {data.respirationFrequency === Frequency.OTHER ? (
          <Stack direction="row" spacing={2}>
            <TextField
              data-testid="respirationFrequencyOtherText"
              label={t('taskList.form.respirationFrequencyOtherText')}
              defaultValue={data.respirationFrequencyOtherText}
              onChange={(newValue) => updateData({ respirationFrequencyOtherText: newValue })}
              disabled={disabled}
              readOnly={readOnly}
            />
          </Stack>
        ) : null}
      </SectionStack>
      <Stack gap="16px" paddingBottom="16px">
        <SectionStack
          heading={t('taskList.heading.bathing')}
          variant="tab-anchor"
          noIndent={true}
          topBorder={true}
          tabValue="bathing"
        >
          <FrequencySelect
            data-testid="bathingFrequency"
            defaultValue={data.bathingFrequency}
            onChange={(newValue) => updateData({ bathingFrequency: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          {data.bathingFrequency === Frequency.OTHER ? (
            <Stack direction="row" spacing={2}>
              <TextField
                data-testid="bathingFrequencyOtherText"
                label={t('taskList.form.bathingFrequencyOtherText')}
                defaultValue={data.bathingFrequencyOtherText}
                onChange={(newValue) => updateData({ bathingFrequencyOtherText: newValue })}
                disabled={disabled}
                readOnly={readOnly}
              />
            </Stack>
          ) : null}
        </SectionStack>
        <SectionStack heading={t('taskList.heading.bathingNeeds')}>
          <RadioGroup
            data-testid="bathingNeeds"
            options={[
              {
                label: t(`taskList.form.careNeeds.ASSISTANCE`),
                option: CareNeeds.ASSISTANCE,
                disabled
              },
              {
                label: t(`taskList.form.careNeeds.SUPERVISION_OR_STANDBY_FOR_SAFETY`),
                option: CareNeeds.SUPERVISION_OR_STANDBY_FOR_SAFETY,
                disabled
              }
            ]}
            defaultValue={data.bathingNeeds}
            onChange={(newValue) => updateData({ bathingNeeds: +newValue as CareNeeds })}
            disabled={disabled}
            readOnly={readOnly}
            helperText=""
          />
        </SectionStack>
        <SectionStack heading={t('taskList.heading.primaryBathType')}>
          <RadioGroup
            data-testid="bathingPrimaryBathType"
            options={[
              {
                label: t(`taskList.form.bathingType.SHOWER`),
                option: BathingType.SHOWER,
                disabled
              },
              {
                label: t(`taskList.form.bathingType.TUB`),
                option: BathingType.TUB,
                disabled
              },
              {
                label: t(`taskList.form.bathingType.CHAIR`),
                option: BathingType.CHAIR,
                disabled
              },
              {
                label: t(`taskList.form.bathingType.BED`),
                option: BathingType.BED,
                disabled
              },
              {
                label: t(`taskList.form.bathingType.OTHER`),
                option: BathingType.OTHER,
                disabled
              }
            ]}
            defaultValue={data.bathingPrimaryBathType}
            onChange={(newValue) => updateData({ bathingPrimaryBathType: +newValue as BathingType })}
            disabled={disabled}
            readOnly={readOnly}
            helperText=""
          />
          {data.bathingPrimaryBathType === BathingType.OTHER ? (
            <Stack direction="row" spacing={2}>
              <TextField
                data-testid="bathingPrimaryBathTypeOtherText"
                label={t('taskList.form.bathTypeOtherText')}
                defaultValue={data.bathingPrimaryBathTypeOtherText}
                onChange={(newValue) => updateData({ bathingPrimaryBathTypeOtherText: newValue })}
                disabled={disabled}
                readOnly={readOnly}
              />
            </Stack>
          ) : null}
        </SectionStack>
        <SectionStack heading={t('taskList.heading.entirety')}>
          <RadioGroup
            data-testid="bathingEntirety"
            options={[
              {
                label: t(`taskList.form.bathingEntirety.COMPLETE`),
                option: BathingEntirety.COMPLETE,
                disabled
              },
              {
                label: t(`taskList.form.bathingEntirety.PARTIAL`),
                option: BathingEntirety.PARTIAL,
                disabled
              },
              {
                label: t(`taskList.form.bathingEntirety.PERINEAL_CARE`),
                option: BathingEntirety.PERINEAL_CARE,
                disabled
              }
            ]}
            defaultValue={data.bathingEntirety}
            onChange={(newValue) => updateData({ bathingEntirety: +newValue as BathingEntirety })}
            disabled={disabled}
            readOnly={readOnly}
            helperText=""
          />
        </SectionStack>
        <SectionStack heading={t('taskList.heading.bathingMayChooseLowerSafeLevelIf')}>
          <RadioGroup
            data-testid="bathingMayChooseLowerSafeLevelIf"
            options={[
              {
                label: t(`taskList.form.bathingMayChooseLowerSafeLevelIf.NECESSARY_FOR_SAFETY`),
                option: BathingLowerSafeLevelIf.NECESSARY_FOR_SAFETY,
                disabled
              },
              {
                label: t(`taskList.form.bathingMayChooseLowerSafeLevelIf.PATIENT_REQUESTS`),
                option: BathingLowerSafeLevelIf.PATIENT_REQUESTS,
                disabled
              }
            ]}
            defaultValue={data.bathingMayChooseLowerSafeLevelIf}
            onChange={(newValue) =>
              updateData({ bathingMayChooseLowerSafeLevelIf: +newValue as BathingLowerSafeLevelIf })
            }
            disabled={disabled}
            readOnly={readOnly}
            helperText=""
          />
        </SectionStack>
        <SectionStack heading={t('taskList.heading.dressing')}>
          <RadioGroup
            data-testid="bathingDressing"
            options={[
              {
                label: t(`taskList.form.bathingDressing.REMOVED_AND_DRY_REAPPLIED`),
                option: BathingDressing.REMOVED_AND_DRY_REAPPLIED,
                disabled
              },
              {
                label: t(`taskList.form.bathingDressing.KEPT_DRY`),
                option: BathingDressing.KEPT_DRY,
                disabled
              }
            ]}
            defaultValue={data.bathingDressing}
            onChange={(newValue) => updateData({ bathingDressing: +newValue as BathingDressing })}
            disabled={disabled}
            readOnly={readOnly}
            helperText=""
          />
        </SectionStack>
        <SectionStack heading={t('taskList.heading.bathingAvailableEquipment')}>
          <Checkbox
            data-testid="bathingAvailableEquipmentShowerSeat"
            label={t('taskList.form.bathingAvailableEquipmentShowerSeat')}
            defaultChecked={data.bathingAvailableEquipmentShowerSeat}
            onChange={(newValue) => updateData({ bathingAvailableEquipmentShowerSeat: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          <Checkbox
            data-testid="bathingAvailableEquipmentTubBench"
            label={t('taskList.form.bathingAvailableEquipmentTubBench')}
            defaultChecked={data.bathingAvailableEquipmentTubBench}
            onChange={(newValue) => updateData({ bathingAvailableEquipmentTubBench: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          <Checkbox
            data-testid="bathingAvailableEquipmentHandheldShower"
            label={t('taskList.form.bathingAvailableEquipmentHandheldShower')}
            defaultChecked={data.bathingAvailableEquipmentHandheldShower}
            onChange={(newValue) => updateData({ bathingAvailableEquipmentHandheldShower: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          <Checkbox
            data-testid="bathingAvailableEquipmentGrabBar"
            label={t('taskList.form.bathingAvailableEquipmentGrabBar')}
            defaultChecked={data.bathingAvailableEquipmentGrabBar}
            onChange={(newValue) => updateData({ bathingAvailableEquipmentGrabBar: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          <Checkbox
            data-testid="bathingAvailableEquipmentOther"
            label={t('taskList.form.bathingAvailableEquipmentOther')}
            defaultChecked={data.bathingAvailableEquipmentOther}
            onChange={(newValue) => updateData({ bathingAvailableEquipmentOther: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          {data.bathingAvailableEquipmentOther ? (
            <Stack direction="row" spacing={2}>
              <TextField
                data-testid="bathingAvailableEquipmentOtherText"
                label={t('taskList.form.bathingAvailableEquipmentOtherText')}
                defaultValue={data.bathingAvailableEquipmentOtherText}
                onChange={(newValue) => updateData({ bathingAvailableEquipmentOtherText: newValue })}
                disabled={disabled}
                readOnly={readOnly}
              />
            </Stack>
          ) : null}
        </SectionStack>
      </Stack>
      <Stack gap="16px" paddingBottom="16px">
        <SectionStack
          heading={t('taskList.heading.personalCare')}
          variant="tab-anchor"
          noIndent={true}
          topBorder={true}
          tabValue="personal-care"
        >
          <FrequencySelect
            data-testid="personalCareFrequency"
            defaultValue={data.personalCareFrequency}
            onChange={(newValue) => updateData({ personalCareFrequency: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          {data.personalCareFrequency === Frequency.OTHER ? (
            <Stack direction="row" spacing={2}>
              <TextField
                data-testid="personalCareFrequencyOtherText"
                label={t('taskList.form.personalCareFrequencyOtherText')}
                defaultValue={data.personalCareFrequencyOtherText}
                onChange={(newValue) => updateData({ personalCareFrequencyOtherText: newValue })}
                disabled={disabled}
                readOnly={readOnly}
              />
            </Stack>
          ) : null}
        </SectionStack>
        <SectionStack heading={t('taskList.heading.needs')}>
          <RadioGroup
            data-testid="personalCareNeeds"
            options={[
              {
                label: t(`taskList.form.careNeeds.ASSISTANCE`),
                option: CareNeeds.ASSISTANCE,
                disabled
              },
              {
                label: t(`taskList.form.careNeeds.SUPERVISION_OR_STANDBY_FOR_SAFETY`),
                option: CareNeeds.SUPERVISION_OR_STANDBY_FOR_SAFETY,
                disabled
              }
            ]}
            defaultValue={data.personalCareNeeds}
            onChange={(newValue) => updateData({ personalCareNeeds: +newValue as CareNeeds })}
            disabled={disabled}
            readOnly={readOnly}
            helperText=""
          />
        </SectionStack>
        <SectionStack heading={t('taskList.heading.hair')}>
          <Checkbox
            data-testid="personalCareHairBrush"
            label={t('taskList.form.personalCareHairBrush')}
            defaultChecked={data.personalCareHairBrush}
            onChange={(newValue) => updateData({ personalCareHairBrush: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          <Checkbox
            data-testid="personalCareHairComb"
            label={t('taskList.form.personalCareHairComb')}
            defaultChecked={data.personalCareHairComb}
            onChange={(newValue) => updateData({ personalCareHairComb: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          <Checkbox
            data-testid="personalCareHairShampoo"
            label={t('taskList.form.personalCareHairShampoo')}
            defaultChecked={data.personalCareHairShampoo}
            onChange={(newValue) => updateData({ personalCareHairShampoo: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
        </SectionStack>
        <SectionStack heading={t('taskList.heading.teeth')}>
          <Checkbox
            data-testid="personalCareTeethBrush"
            label={t('taskList.form.personalCareTeethBrush')}
            defaultChecked={data.personalCareTeethBrush}
            onChange={(newValue) => updateData({ personalCareTeethBrush: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          <Checkbox
            data-testid="personalCareTeethDentureCare"
            label={t('taskList.form.personalCareTeethDentureCare')}
            defaultChecked={data.personalCareTeethDentureCare}
            onChange={(newValue) => updateData({ personalCareTeethDentureCare: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          <Checkbox
            data-testid="personalCareTeethUseOralSwabs"
            label={t('taskList.form.personalCareTeethUseOralSwabs')}
            defaultChecked={data.personalCareTeethUseOralSwabs}
            onChange={(newValue) => updateData({ personalCareTeethUseOralSwabs: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
        </SectionStack>
        <SectionStack heading={t('taskList.heading.personalCareOther')}>
          <Checkbox
            data-testid="personalCareOtherShaving"
            label={t('taskList.form.personalCareOtherShaving')}
            defaultChecked={data.personalCareOtherShaving}
            onChange={(newValue) => updateData({ personalCareOtherShaving: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          <Checkbox
            data-testid="personalCareOtherSkinCare"
            label={t('taskList.form.personalCareOtherSkinCare')}
            defaultChecked={data.personalCareOtherSkinCare}
            onChange={(newValue) => updateData({ personalCareOtherSkinCare: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          <Checkbox
            data-testid="personalCareOtherDressing"
            label={t('taskList.form.personalCareOtherDressing')}
            defaultChecked={data.personalCareOtherDressing}
            onChange={(newValue) => updateData({ personalCareOtherDressing: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          <Checkbox
            data-testid="personalCareOtherFootCare"
            label={t('taskList.form.personalCareOtherFootCare')}
            defaultChecked={data.personalCareOtherFootCare}
            onChange={(newValue) => updateData({ personalCareOtherFootCare: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          <Checkbox
            data-testid="personalCareOtherNailCare"
            label={t('taskList.form.personalCareOtherNailCare')}
            defaultChecked={data.personalCareOtherNailCare}
            onChange={(newValue) => updateData({ personalCareOtherNailCare: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          <Checkbox
            data-testid="personalCareOtherPressureAreasAndSkin"
            label={t('taskList.form.personalCareOtherPressureAreasAndSkin')}
            defaultChecked={data.personalCareOtherPressureAreasAndSkin}
            onChange={(newValue) => updateData({ personalCareOtherPressureAreasAndSkin: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
        </SectionStack>
        <TextField
          data-testid="personalCareOtherFindings"
          label={t('taskList.form.personalCareOtherFindings')}
          defaultValue={data.personalCareOtherFindings}
          onChange={(newValue) => updateData({ personalCareOtherFindings: newValue })}
          multiline={true}
          minRows={3}
          disabled={disabled}
          readOnly={readOnly}
        />
      </Stack>
      <Stack gap="16px" paddingBottom="16px">
        <SectionStack
          heading={t('taskList.heading.ambulationLocomotion')}
          variant="tab-anchor"
          noIndent={true}
          topBorder={true}
          tabValue="ambulation-locomotion"
        >
          <FrequencySelect
            data-testid="ambulationLocomotionFrequency"
            defaultValue={data.ambulationLocomotionFrequency}
            onChange={(newValue) => updateData({ ambulationLocomotionFrequency: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          {data.ambulationLocomotionFrequency === Frequency.OTHER ? (
            <Stack direction="row" spacing={2}>
              <TextField
                data-testid="ambulationLocomotionFrequencyOtherText"
                label={t('taskList.form.ambulationLocomotionFrequencyOtherText')}
                defaultValue={data.ambulationLocomotionFrequencyOtherText}
                onChange={(newValue) => updateData({ ambulationLocomotionFrequencyOtherText: newValue })}
                disabled={disabled}
                readOnly={readOnly}
              />
            </Stack>
          ) : null}
        </SectionStack>
        <SectionStack heading={t('taskList.heading.ambulationLocomotionNeeds')}>
          <RadioGroup
            data-testid="ambulationLocomotionNeeds"
            options={[
              {
                label: t(`taskList.form.careNeeds.ASSISTANCE`),
                option: CareNeeds.ASSISTANCE,
                disabled
              },
              {
                label: t(`taskList.form.careNeeds.SUPERVISION_OR_STANDBY_FOR_SAFETY`),
                option: CareNeeds.SUPERVISION_OR_STANDBY_FOR_SAFETY,
                disabled
              }
            ]}
            defaultValue={data.ambulationLocomotionNeeds}
            onChange={(newValue) => updateData({ ambulationLocomotionNeeds: +newValue as CareNeeds })}
            disabled={disabled}
            readOnly={readOnly}
            helperText=""
          />
        </SectionStack>
        <SectionStack heading={t('taskList.heading.ambulationLocomotionDevice')}>
          <Checkbox
            data-testid="ambulationLocomotionDeviceNoAssistiveDevice"
            label={t('taskList.form.ambulationLocomotionDeviceNoAssistiveDevice')}
            defaultChecked={data.ambulationLocomotionDeviceNoAssistiveDevice}
            onChange={(newValue) => updateData({ ambulationLocomotionDeviceNoAssistiveDevice: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          <Checkbox
            data-testid="ambulationLocomotionDeviceGaitBelt"
            label={t('taskList.form.ambulationLocomotionDeviceGaitBelt')}
            defaultChecked={data.ambulationLocomotionDeviceGaitBelt}
            onChange={(newValue) => updateData({ ambulationLocomotionDeviceGaitBelt: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          <Checkbox
            data-testid="ambulationLocomotionDeviceCane"
            label={t('taskList.form.ambulationLocomotionDeviceCane')}
            defaultChecked={data.ambulationLocomotionDeviceCane}
            onChange={(newValue) => updateData({ ambulationLocomotionDeviceCane: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          <Checkbox
            data-testid="ambulationLocomotionDeviceWalker"
            label={t('taskList.form.ambulationLocomotionDeviceWalker')}
            defaultChecked={data.ambulationLocomotionDeviceWalker}
            onChange={(newValue) => updateData({ ambulationLocomotionDeviceWalker: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          <Checkbox
            data-testid="ambulationLocomotionDeviceWheelchair"
            label={t('taskList.form.ambulationLocomotionDeviceWheelchair')}
            defaultChecked={data.ambulationLocomotionDeviceWheelchair}
            onChange={(newValue) => updateData({ ambulationLocomotionDeviceWheelchair: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          <Checkbox
            data-testid="ambulationLocomotionDeviceMechanicalLift"
            label={t('taskList.form.ambulationLocomotionDeviceMechanicalLift')}
            defaultChecked={data.ambulationLocomotionDeviceMechanicalLift}
            onChange={(newValue) => updateData({ ambulationLocomotionDeviceMechanicalLift: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          <Checkbox
            data-testid="ambulationLocomotionDeviceOther"
            label={t('taskList.form.ambulationLocomotionDeviceOther')}
            defaultChecked={data.ambulationLocomotionDeviceOther}
            onChange={(newValue) => updateData({ ambulationLocomotionDeviceOther: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          {data.ambulationLocomotionDeviceOther ? (
            <Stack direction="row" spacing={2}>
              <TextField
                data-testid="ambulationLocomotionDeviceOtherText"
                label={t('taskList.form.ambulationLocomotionDeviceOtherText')}
                defaultValue={data.ambulationLocomotionDeviceOtherText}
                onChange={(newValue) => updateData({ ambulationLocomotionDeviceOtherText: newValue })}
                disabled={disabled}
                readOnly={readOnly}
              />
            </Stack>
          ) : null}
        </SectionStack>
        <SectionStack heading={t('taskList.heading.weightBearing')}>
          <RadioGroup
            data-testid="ambulationLocomotionWeightBearing"
            options={[
              {
                label: t(`taskList.form.weightBearing.FULL`),
                option: WeightBearing.FULL,
                disabled
              },
              {
                label: t(`taskList.form.weightBearing.PARTIAL`),
                option: WeightBearing.PARTIAL,
                disabled
              },
              {
                label: t(`taskList.form.weightBearing.NONE`),
                option: WeightBearing.NONE,
                disabled
              }
            ]}
            defaultValue={data.ambulationLocomotionWeightBearing}
            onChange={(newValue) => updateData({ ambulationLocomotionWeightBearing: +newValue as WeightBearing })}
            disabled={disabled}
            readOnly={readOnly}
            helperText=""
          />
        </SectionStack>
        <SectionStack heading={t('taskList.heading.ambulationAssistWith')}>
          <Checkbox
            data-testid="ambulationLocomotionAssistWithTransfers"
            label={t('taskList.form.ambulationLocomotionAssistWithTransfers')}
            defaultChecked={data.ambulationLocomotionAssistWithTransfers}
            onChange={(newValue) => updateData({ ambulationLocomotionAssistWithTransfers: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          <Checkbox
            data-testid="ambulationLocomotionAssistWithRemainedInBed"
            label={t('taskList.form.ambulationLocomotionAssistWithRemainedInBed')}
            defaultChecked={data.ambulationLocomotionAssistWithRemainedInBed}
            onChange={(newValue) => updateData({ ambulationLocomotionAssistWithRemainedInBed: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          <Checkbox
            data-testid="ambulationLocomotionAssistWithTurnedAndRepositioned"
            label={t('taskList.form.ambulationLocomotionAssistWithTurnedAndRepositioned')}
            defaultChecked={data.ambulationLocomotionAssistWithTurnedAndRepositioned}
            onChange={(newValue) => updateData({ ambulationLocomotionAssistWithTurnedAndRepositioned: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          <Checkbox
            data-testid="ambulationLocomotionAssistWithOther"
            label={t('taskList.form.ambulationLocomotionAssistWithOther')}
            defaultChecked={data.ambulationLocomotionAssistWithOther}
            onChange={(newValue) => updateData({ ambulationLocomotionAssistWithOther: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          {data.ambulationLocomotionAssistWithOther ? (
            <Stack direction="row" spacing={2}>
              <TextField
                data-testid="ambulationLocomotionAssistWithOtherText"
                label={t('taskList.form.ambulationLocomotionAssistWithOtherText')}
                defaultValue={data.ambulationLocomotionAssistWithOtherText}
                onChange={(newValue) => updateData({ ambulationLocomotionAssistWithOtherText: newValue })}
                disabled={disabled}
                readOnly={readOnly}
              />
            </Stack>
          ) : null}
        </SectionStack>
      </Stack>
      <Stack gap="16px" paddingBottom="16px">
        <SectionStack
          heading={t('taskList.heading.other')}
          variant="tab-anchor"
          noIndent={true}
          topBorder={true}
          tabValue="other"
        >
          <Checkbox
            data-testid="otherCough"
            label={t('taskList.form.otherCough')}
            defaultChecked={data.otherCough}
            onChange={(newValue) => updateData({ otherCough: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          <Checkbox
            data-testid="otherDeepBreath"
            label={t('taskList.form.otherDeepBreath')}
            defaultChecked={data.otherDeepBreath}
            onChange={(newValue) => updateData({ otherDeepBreath: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          <Checkbox
            data-testid="otherHomeExercisePerTherapy"
            label={t('taskList.form.otherHomeExercisePerTherapy')}
            defaultChecked={data.otherHomeExercisePerTherapy}
            onChange={(newValue) => updateData({ otherHomeExercisePerTherapy: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          <Checkbox
            data-testid="otherAssistedFeeding"
            label={t('taskList.form.otherAssistedFeeding')}
            defaultChecked={data.otherAssistedFeeding}
            onChange={(newValue) => updateData({ otherAssistedFeeding: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          <Checkbox
            data-testid="otherEncouragedFluids"
            label={t('taskList.form.otherEncouragedFluids')}
            defaultChecked={data.otherEncouragedFluids}
            onChange={(newValue) => updateData({ otherEncouragedFluids: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          <Checkbox
            data-testid="otherRecordedIandO"
            label={t('taskList.form.otherRecordedIandO')}
            defaultChecked={data.otherRecordedIandO}
            onChange={(newValue) => updateData({ otherRecordedIandO: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          <Checkbox
            data-testid="otherCleanedCareAreas"
            label={t('taskList.form.otherCleanedCareAreas')}
            defaultChecked={data.otherCleanedCareAreas}
            onChange={(newValue) => updateData({ otherCleanedCareAreas: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          <Checkbox
            data-testid="otherOther"
            label={t('taskList.form.otherOther')}
            defaultChecked={data.otherOther}
            onChange={(newValue) => updateData({ otherOther: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          {data.otherOther ? (
            <Stack direction="row" spacing={2}>
              <TextField
                data-testid="otherOtherText"
                label={t('taskList.form.otherOtherText')}
                defaultValue={data.otherOtherText}
                onChange={(newValue) => updateData({ otherOtherText: newValue })}
                disabled={disabled}
                readOnly={readOnly}
              />
            </Stack>
          ) : null}
        </SectionStack>
        <SectionStack heading={t('taskList.heading.rom')}>
          <RadioGroup
            data-testid="otherROM"
            options={[
              {
                label: t(`taskList.form.rom.ACTIVE`),
                option: ROM.ACTIVE,
                disabled
              },
              {
                label: t(`taskList.form.rom.PASSIVE`),
                option: ROM.PASSIVE,
                disabled
              }
            ]}
            defaultValue={data.otherROM}
            onChange={(newValue) => updateData({ otherROM: +newValue as ROM })}
            disabled={disabled}
            readOnly={readOnly}
            helperText=""
          />
        </SectionStack>
        <SectionStack heading={t('taskList.heading.otherPrepared')}>
          <Checkbox
            data-testid="otherPreparedBreakfast"
            label={t('taskList.form.otherPreparedBreakfast')}
            defaultChecked={data.otherPreparedBreakfast}
            onChange={(newValue) => updateData({ otherPreparedBreakfast: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          <Checkbox
            data-testid="otherPreparedLunch"
            label={t('taskList.form.otherPreparedLunch')}
            defaultChecked={data.otherPreparedLunch}
            onChange={(newValue) => updateData({ otherPreparedLunch: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          <Checkbox
            data-testid="otherPreparedDinner"
            label={t('taskList.form.otherPreparedDinner')}
            defaultChecked={data.otherPreparedDinner}
            onChange={(newValue) => updateData({ otherPreparedDinner: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          <Checkbox
            data-testid="otherPreparedOther"
            label={t('taskList.form.otherPreparedOther')}
            defaultChecked={data.otherPreparedOther}
            onChange={(newValue) => updateData({ otherPreparedOther: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          {data.otherPreparedOther ? (
            <Stack direction="row" spacing={2}>
              <TextField
                data-testid="otherPreparedOtherText"
                label={t('taskList.form.otherPreparedOtherText')}
                defaultValue={data.otherPreparedOtherText}
                onChange={(newValue) => updateData({ otherPreparedOtherText: newValue })}
                disabled={disabled}
                readOnly={readOnly}
              />
            </Stack>
          ) : null}
        </SectionStack>
        <SectionStack heading={t('taskList.heading.bedLinensChanged')}>
          <RadioGroup
            data-testid="otherChangedBedLinens"
            options={[
              {
                label: t(`taskList.form.bedLinensChanged.PRN`),
                option: BedLinensChanged.PRN,
                disabled
              },
              {
                label: t(`taskList.form.bedLinensChanged.ONCE_A_WEEK`),
                option: BedLinensChanged.ONCE_A_WEEK,
                disabled
              }
            ]}
            defaultValue={data.otherChangedBedLinens}
            onChange={(newValue) => updateData({ otherChangedBedLinens: +newValue as BedLinensChanged })}
            disabled={disabled}
            readOnly={readOnly}
            helperText=""
          />
        </SectionStack>
        <SectionStack heading={t('taskList.heading.otherAssistedWithSelfMedications')}>
          <Checkbox
            data-testid="otherAssistedWithSelfMedications"
            label={t('taskList.form.otherAssistedWithSelfMedications')}
            defaultChecked={data.otherAssistedWithSelfMedications}
            onChange={(newValue) => updateData({ otherAssistedWithSelfMedications: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          <TextField
            data-testid="otherAssistedWithSelfMedicationsText"
            label={t('taskList.form.otherAssistedWithSelfMedicationsText')}
            defaultValue={data.otherAssistedWithSelfMedicationsText}
            onChange={(newValue) => updateData({ otherAssistedWithSelfMedicationsText: newValue })}
            disabled={disabled}
            readOnly={readOnly}
            multiline={true}
            minRows={3}
          />
          <Checkbox
            data-testid="otherOstomyCare"
            label={t('taskList.form.otherOstomyCare')}
            defaultChecked={data.otherOstomyCare}
            onChange={(newValue) => updateData({ otherOstomyCare: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          <TextField
            data-testid="otherOstomyCareText"
            label={t('taskList.form.otherOstomyCareText')}
            defaultValue={data.otherOstomyCareText}
            onChange={(newValue) => updateData({ otherOstomyCareText: newValue })}
            disabled={disabled}
            readOnly={readOnly}
            multiline={true}
            minRows={3}
          />
          <Select
            data-testid="otherPainLevel"
            label={t('taskList.form.otherPainLevel')}
            options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((level) => ({
              label: level,
              value: level
            }))}
            defaultValue={data.otherPainLevel}
            onChange={(newValue) => updateData({ otherPainLevel: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
          <TextField
            data-testid="otherActivityAtTimeOfInquiry"
            label={t('taskList.form.otherActivityAtTimeOfInquiry')}
            defaultValue={data.otherActivityAtTimeOfInquiry}
            onChange={(newValue) => updateData({ otherActivityAtTimeOfInquiry: newValue })}
            disabled={disabled}
            readOnly={readOnly}
            multiline={true}
            minRows={3}
          />
          <DateField
            data-testid="otherLastBowelMovement"
            label={t('taskList.form.otherLastBowelMovement')}
            defaultValue={data.otherLastBowelMovement}
            onChange={(newValue) => updateData({ otherLastBowelMovement: newValue })}
            disabled={disabled}
            readOnly={readOnly}
          />
        </SectionStack>
      </Stack>
      <Stack gap="16px" paddingBottom="16px">
        <SectionStack
          heading={t('taskList.heading.report')}
          variant="tab-anchor"
          noIndent={true}
          topBorder={true}
          tabValue="report"
        >
          <TextField
            data-testid="report"
            label={t('taskList.form.report')}
            defaultValue={data.report}
            onChange={(newValue) => updateData({ report: newValue })}
            disabled={disabled}
            readOnly={readOnly}
            multiline={true}
            minRows={7}
          />
        </SectionStack>
      </Stack>
      {!readOnly ? (
        <>
          <Divider />
          <Box sx={{ display: 'flex', gap: '8px', marginTop: '16px' }}>
            <Button data-testid="submit" variant="contained" disabled={saving} onClick={handleSubmit}>
              {t('app.submit')}
            </Button>
            <Button data-testid="save" variant="text" disabled={saving} onClick={handleSave}>
              {t('app.save')}
            </Button>
          </Box>
        </>
      ) : null}
    </Stack>
  );
};

export default TaskListForm;
