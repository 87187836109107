import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import Page from '@maya/common/Page';
import useTranslate from '@maya/hooks/useTranslate';
import VisitTypeForm from './VisitTypeForm';

import type { FC } from 'react';

const VisitTypeView: FC = () => {
  const t = useTranslate();
  const navigate = useNavigate();

  const endEditing = useCallback(() => {
    navigate('/settings/visit-type');
  }, [navigate]);

  return (
    <Page title={t('visitTypes.view.newHeader')} backTo="/settings/visit-type" color="paper">
      <VisitTypeForm key="editing-form" onClose={endEditing} />
    </Page>
  );
};

export default VisitTypeView;
