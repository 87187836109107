import { useEffect } from 'react';

import useBranchId from '@maya/hooks/useBranchId';
import useTranslate from '@maya/hooks/useTranslate';
import { useAppDispatch, useAppSelector } from '@maya/store/hooks';
import { loadEmployees, selectEmployees } from '@maya/store/slices/employee';

export default function useEmployees(search = '', visitTypeId?: string) {
  const branchId = useBranchId();
  const t = useTranslate();

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!branchId) {
      return;
    }

    dispatch(loadEmployees({ branchId, search, visitTypeId }, t));
  }, [branchId, dispatch, search, t, visitTypeId]);

  return useAppSelector(selectEmployees);
}
