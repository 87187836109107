export interface ListDTO<T> {
  data: T[];
  total: number;
}

export function isListDTO(obj?: object): obj is ListDTO<unknown> {
  return obj != null && 'data' in obj && 'total' in obj;
}

export interface LoginDTO {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  companyId: string;
  agencyIds: string[];
  branchIds: string[];
  role: string;
}

export interface CreateEmployeeDTO {
  email: string;
  username: string;
  firstName: string;
  middleInitial?: string;
  lastName: string;
  phone: string;
  cell?: string;
  gender: string;
  dob: string;
  ssn: string;
  disciplineId?: string;
  street: string;
  city: string;
  state: string;
  zip: string;
  role: string;
}

export interface EmployeeDTO extends CreateEmployeeDTO {
  id: string;
  fullName: string;
  active: boolean;
  companyId?: string;
  disciplineCode?: string;
  disciplineName?: string;
  addressId: string;
  verified: boolean;
  inviteDate: number;
  inviteAcceptedDate?: number;
}

export type EmployeesDTO = ListDTO<EmployeeDTO>;

export interface CreatePatientDTO {
  firstName: string;
  middleInitial?: string;
  lastName: string;
  dob: string;
  gender: string;
  ssn: string;
  insuranceId: string;
  phone1: string;
  phone2?: string;
  street: string;
  city: string;
  state: string;
  zip: string;
}

export interface PatientDTO extends CreatePatientDTO {
  id: string;
  fullName: string;
  addressId: string;
}

export type PatientsDTO = ListDTO<PatientDTO>;

export interface CreateVisitDTO {
  patientId: string;
  scheduledDate: number;
  employeeId: string;
  visitTypeId: string;
  notes?: string;
}

export interface FormSummaryDTO {
  id: string;
  status: RecordStatus;
  type: 'route-sheet' | 'patient-consent' | 'task-list';
}

export type VisitStatus = 'scheduled' | 'canceled' | 'incomplete' | 'completed';

export interface VisitDTO extends Omit<CreateVisitDTO, 'employeeId'> {
  id: string;
  status: VisitStatus;
  patientName: string;
  patient: PatientDTO;
  employee: EmployeeDTO;
  visitType: VisitTypeDTO;
  visitTypeName: string;
  formSummaries?: FormSummaryDTO[];
}

export type VisitsDTO = ListDTO<VisitDTO>;

export interface Discipline {
  id: string;
  code: string;
  name: string;
}

export enum PaySchedule {
  Visit = 'visit',
  Hour = 'hour'
}

export interface CreateVisitTypeDTO {
  name: string;
  defaultPay: number;
  defaultHours: number;
  paySchedule: PaySchedule;
  mileage: boolean;
  formIds: string[];
  disciplineId: string;
}

export interface VisitTypeDTO extends CreateVisitTypeDTO {
  id: string;
  active: boolean;
  disciplineCode: string;
  disciplineName: string;
}

export type VisitTypesDTO = ListDTO<VisitTypeDTO>;

export interface FormType {
  id: string;
  code: string;
  name: string;
}

export enum Forms {
  ROUTE_SHEET = 'RS',
  PATIENT_CONSENT = 'PC',
  TASK_LIST = 'TL'
}

export interface CreateCompanyDTO {
  name: string;
  doingBusinessAs?: string;
  street: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
  fax?: string;
  taxId: string;
  stateAgencyId?: string;
  medicareId: string;
  npiNumber: string;
}

export interface CompanyDTO extends CreateCompanyDTO {
  id: string;
  active: boolean;
  inviteDate: number;
  inviteAcceptedDate?: number;
  verified: boolean;
}

export interface CompanyWithUserDTO extends CompanyDTO {
  firstName: string;
  lastName: string;
  username: string;
  email: string;
}

export interface CompaniesDTO {
  data: CompanyDTO[];
  total: number;
}

export enum RecordStatus {
  pending = 'pending',
  saved = 'saved',
  submitted = 'submitted'
}

export interface RecordDTO {
  id: string;
  visitId: string;
  scheduledDate: number;
  type: Forms;
  assignedTo: string;
  discipline: string;
  status: RecordStatus;
}

export interface RecordsDTO {
  data: RecordDTO[];
  total: number;
}

export interface VisitsSummaryDTO {
  id: string;
  overdue: { data: VisitDTO[]; total: number };
  today: { data: VisitDTO[]; total: number };
  upcoming: { data: VisitDTO[]; total: number };
}

export interface UpdateRouteSheetDTO {
  status: RecordStatus;
  startTime?: string;
  endTime?: string;
  mileage?: number;
  patientSignature?: string;
  employeeSignature?: string;
}

export interface RouteSheetDTO {
  id: string;
  status: RecordStatus;
  patientSignature?: string;
  employeeSignature?: string;
  startTime?: string;
  endTime?: string;
  mileage?: number;
  requireMileage: boolean;
  employeeId: string;
}

export type PaymentStatus = 'not-paid' | 'paid';

export interface WageDTO {
  id: string;
  visitDate: number;
  visitType: string;
  employeeName: string;
  employeeFirstName: string;
  employeeLastName: string;
  patient: string;
  amount: number;
  extras?: number;
  notes?: string;
  status: PaymentStatus;
}

export interface WagesDTO {
  data: WageDTO[];
  total: number;
}

export interface UpdatePatientConsentDTO {
  status: RecordStatus;
  signature?: string;
  additionalSignature?: string;
  noInformationReleaseAbout?: string;
  noInformationReleaseTo?: string;
  providedNursing?: boolean;
  providedCHHA?: boolean;
  providedPT?: boolean;
  providedOT?: boolean;
  providedST?: boolean;
  providedDiet?: boolean;
  providedMSW?: boolean;
  advancedDirective?: boolean;
  powerOfAttorney?: boolean;
  livingWill?: boolean;
  doNotResuscitate?: boolean;
  doNotKnowAdvanceDirectives?: boolean;
  otherAdvanceDirectives?: string;
  photograph?: boolean;
  lang?: string;
}

export interface PatientConsentDTO {
  id: string;
  status: RecordStatus;
  signature?: string;
  additionalSignature?: string;
  noInformationReleaseAbout?: string;
  noInformationReleaseTo?: string;
  providedNursing?: boolean;
  providedCHHA?: boolean;
  providedPT?: boolean;
  providedOT?: boolean;
  providedST?: boolean;
  providedDiet?: boolean;
  providedMSW?: boolean;
  advancedDirective?: boolean;
  powerOfAttorney?: boolean;
  livingWill?: boolean;
  doNotResuscitate?: boolean;
  doNotKnowAdvanceDirectives?: boolean;
  otherAdvanceDirectives?: string;
  photograph?: boolean;
  lang?: string;
  employeeId: string;
  branchName: string;
  branchStreet: string;
  branchCity: string;
  branchState: string;
  branchZip: string;
  branchPhone: string;
  branchFax?: string;
  administrator?: string;
}

export enum Attentiveness {
  ALERT_AND_ORIENTED = 0,
  CONFUSED = 1,
  OTHER = 2
}

export enum UsesOxygenType {
  PRN = 0,
  CONTINUOUS = 1,
  OTHER = 2
}

export enum BladderBowelUsability {
  CONTINENT = 0,
  INCONTINENT = 1
}

export enum Frequency {
  NA = 0,
  ONCE_A_WEEK = 1,
  BI_WEEKLY = 2,
  EACH_VISIT = 3,
  OTHER = 4
}

export enum CareNeeds {
  ASSISTANCE = 0,
  SUPERVISION_OR_STANDBY_FOR_SAFETY = 1
}

export enum BathingType {
  SHOWER = 0,
  TUB = 1,
  CHAIR = 2,
  BED = 3,
  OTHER = 4
}

export enum BathingEntirety {
  COMPLETE = 0,
  PARTIAL = 1,
  PERINEAL_CARE = 2
}

export enum BathingLowerSafeLevelIf {
  NECESSARY_FOR_SAFETY = 0,
  PATIENT_REQUESTS = 1
}

export enum BathingDressing {
  REMOVED_AND_DRY_REAPPLIED = 0,
  KEPT_DRY = 1
}

export enum WeightBearing {
  FULL = 0,
  PARTIAL = 1,
  NONE = 2
}

export enum ROM {
  ACTIVE = 0,
  PASSIVE = 1
}

export enum BedLinensChanged {
  PRN = 0,
  ONCE_A_WEEK = 1
}

export enum TemperatureUnit {
  FAHRENHEIT = 0,
  CELSIUS = 1
}

export enum WeightUnit {
  POUND = 0,
  KILOGRAM = 1
}

export interface UpdateTaskListDTO {
  activityBedbound?: boolean;
  activityBedrestWithBRP?: boolean;
  activityUpAsTolerated?: boolean;
  activityUpInChair?: boolean;
  ambulationLocomotionAssistWithOther?: boolean;
  ambulationLocomotionAssistWithOtherText?: string;
  ambulationLocomotionAssistWithRemainedInBed?: boolean;
  ambulationLocomotionAssistWithTransfers?: boolean;
  ambulationLocomotionAssistWithTurnedAndRepositioned?: boolean;
  ambulationLocomotionDeviceCane?: boolean;
  ambulationLocomotionDeviceGaitBelt?: boolean;
  ambulationLocomotionDeviceMechanicalLift?: boolean;
  ambulationLocomotionDeviceNoAssistiveDevice?: boolean;
  ambulationLocomotionDeviceOther?: boolean;
  ambulationLocomotionDeviceOtherText?: string;
  ambulationLocomotionDeviceWalker?: boolean;
  ambulationLocomotionDeviceWheelchair?: boolean;
  ambulationLocomotionFrequency?: Frequency;
  ambulationLocomotionFrequencyOtherText?: string;
  ambulationLocomotionNeeds?: CareNeeds;
  ambulationLocomotionWeightBearing?: WeightBearing;
  amputationAKALeft?: boolean;
  amputationAKARight?: boolean;
  amputationBKALeft?: boolean;
  amputationBKARight?: boolean;
  attentiveness?: Attentiveness;
  attentivenessOtherText?: string;
  bathingAvailableEquipmentGrabBar?: boolean;
  bathingAvailableEquipmentHandheldShower?: boolean;
  bathingAvailableEquipmentOther?: boolean;
  bathingAvailableEquipmentOtherText?: string;
  bathingAvailableEquipmentShowerSeat?: boolean;
  bathingAvailableEquipmentTubBench?: boolean;
  bathingDressing?: BathingDressing;
  bathingEntirety?: BathingEntirety;
  bathingFrequency?: Frequency;
  bathingFrequencyOtherText?: string;
  bathingMayChooseLowerSafeLevelIf?: BathingLowerSafeLevelIf;
  bathingNeeds?: CareNeeds;
  bathingPrimaryBathType?: BathingType;
  bathingPrimaryBathTypeOtherText?: string;
  bloodPressure?: string;
  bloodPressureFrequency?: Frequency;
  bloodPressureFrequencyOtherText?: string;
  bowel?: BladderBowelUsability;
  denturesLower?: boolean;
  denturesUpper?: boolean;
  diet?: string;
  dressingBandage?: string;
  fluids?: string;
  hearingAidsLeft?: boolean;
  hearingAidsRight?: boolean;
  infoDiabetes?: boolean;
  infoDoNotResuscitate?: boolean;
  infoHeartCondition?: boolean;
  infoHip?: boolean;
  infoKnee?: boolean;
  infoOtherText?: string;
  infoParesis?: boolean;
  infoRespiratoryCondition?: boolean;
  infoShoulderSurgery?: boolean;
  infoShoulderSurgeryLeft?: boolean;
  infoShoulderSurgeryRight?: boolean;
  infoSpecialPrecautions?: boolean;
  infoSpecialPrecautionsGloves?: boolean;
  infoSpecialPrecautionsGown?: boolean;
  infoSpecialPrecautionsMask?: boolean;
  infoUniversalPrecautions?: boolean;
  infoWearsContacts?: boolean;
  infoWearsGlasses?: boolean;
  otherActivityAtTimeOfInquiry?: string;
  otherAssistedFeeding?: boolean;
  otherAssistedWithSelfMedications?: boolean;
  otherAssistedWithSelfMedicationsText?: string;
  otherChangedBedLinens?: BedLinensChanged;
  otherCleanedCareAreas?: boolean;
  otherCough?: boolean;
  otherDeepBreath?: boolean;
  otherEncouragedFluids?: boolean;
  otherHomeExercisePerTherapy?: boolean;
  otherLastBowelMovement?: string;
  otherOstomyCare?: boolean;
  otherOstomyCareText?: string;
  otherOther?: boolean;
  otherOtherText?: string;
  otherPainLevel?: number;
  otherPreparedBreakfast?: boolean;
  otherPreparedDinner?: boolean;
  otherPreparedLunch?: boolean;
  otherPreparedOther?: boolean;
  otherPreparedOtherText?: string;
  otherROM?: ROM;
  otherRecordedIandO?: boolean;
  paresisAKALeft?: boolean;
  paresisAKARight?: boolean;
  paresisBKALeft?: boolean;
  paresisBKARight?: boolean;
  personalCareFrequency?: Frequency;
  personalCareFrequencyOtherText?: string;
  personalCareHairBrush?: boolean;
  personalCareHairComb?: boolean;
  personalCareHairShampoo?: boolean;
  personalCareNeeds?: CareNeeds;
  personalCareOtherDressing?: boolean;
  personalCareOtherFindings?: string;
  personalCareOtherFootCare?: boolean;
  personalCareOtherNailCare?: boolean;
  personalCareOtherPressureAreasAndSkin?: boolean;
  personalCareOtherShaving?: boolean;
  personalCareOtherSkinCare?: boolean;
  personalCareTeethBrush?: boolean;
  personalCareTeethDentureCare?: boolean;
  personalCareTeethUseOralSwabs?: boolean;
  pulse?: number;
  pulseFrequency?: Frequency;
  pulseFrequencyOtherText?: string;
  report?: string;
  respiration?: number;
  respirationFrequency?: Frequency;
  respirationFrequencyOtherText?: string;
  status: RecordStatus;
  surgeryProcedure?: string;
  temperature?: number;
  temperatureFrequency?: Frequency;
  temperatureFrequencyOtherText?: string;
  temperatureUnit?: TemperatureUnit;
  urinaryBladder?: BladderBowelUsability;
  urineOstomy?: boolean;
  urineOther?: boolean;
  urineOtherText?: string;
  urineUrinaryCatheter?: boolean;
  usesOxygen?: boolean;
  usesOxygenType?: UsesOxygenType;
  usesOxygenTypeOtherText?: string;
  weight?: number;
  weightFrequency?: Frequency;
  weightFrequencyOtherText?: string;
  weightUnit?: WeightUnit;
}

export interface TaskListDTO extends UpdateTaskListDTO {
  id: string;
  employeeId: string;
}
