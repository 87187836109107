import { createSlice } from '@reduxjs/toolkit';

import Api from '@maya/api/api';
import fetch from '@maya/api/fetch';

import type { Discipline, FormType } from '@maya/interface';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { t } from 'react-polyglot';
import type { AppDispatch, RootState } from '../index';

// Define a type for the slice state
export interface EmployeeState {
  disciplines?: Discipline[];
  isDisciplinesLoading: boolean;
  formTypes?: FormType[];
  isFormTypesLoading: boolean;
}

// Define the initial state using that type
const initialState: EmployeeState = {
  isDisciplinesLoading: false,
  isFormTypesLoading: false
};

export const staticSlice = createSlice({
  name: 'static',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setDisciplinesLoading: (state, action: PayloadAction<boolean>) => {
      state.isDisciplinesLoading = action.payload;
    },
    updateDisciplines: (state, action: PayloadAction<Discipline[]>) => {
      state.disciplines = action.payload;
      state.isDisciplinesLoading = false;
    },
    setFormTypesLoading: (state, action: PayloadAction<boolean>) => {
      state.isFormTypesLoading = action.payload;
    },
    updateFormTypes: (state, action: PayloadAction<FormType[]>) => {
      state.formTypes = action.payload;
      state.isFormTypesLoading = false;
    }
  }
});

export const { updateDisciplines, setDisciplinesLoading, updateFormTypes, setFormTypesLoading } = staticSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectDisciplines = (state: RootState) => state.static.disciplines ?? [];
export const selectFormTypes = (state: RootState) => state.static.formTypes ?? [];

export interface EmployeeSearch {
  branchId: string;
  search?: string;
  visitTypeId?: string;
}

export const loadFormTypes = (t: t) => async (dispatch: AppDispatch, getState: () => RootState) => {
  const state = getState();
  if (state.static.isFormTypesLoading || state.static.formTypes !== undefined) {
    return;
  }

  dispatch(setFormTypesLoading(true));

  const response = await fetch(Api.static_FormTypesGet, t, {});

  if (response) {
    dispatch(updateFormTypes(response.data));
  } else {
    dispatch(setFormTypesLoading(false));
  }
};

export const loadDisciplines = (t: t) => async (dispatch: AppDispatch, getState: () => RootState) => {
  const state = getState();
  if (state.static.isDisciplinesLoading || state.static.disciplines !== undefined) {
    return;
  }

  dispatch(setDisciplinesLoading(true));

  const response = await fetch(Api.static_DisciplinesGet, t, {});

  if (response) {
    dispatch(updateDisciplines(response.data));
  } else {
    dispatch(setDisciplinesLoading(false));
  }
};

export default staticSlice.reducer;
