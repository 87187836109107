/* eslint-disable import/prefer-default-export */
export const SIDEBAR_WIDTH = 240;

export const HEADER_HEIGHT = 64;
export const HEADER_WITH_TABS_HEIGHT = 112;

export const MOBILE_HEADER_HEIGHT = 56;
export const MOBILE_HEADER_WITH_TABS_HEIGHT = 104;

export const PAGE_PADDING = 16;

export const INPUT_HEIGHT = 56;

export const PAGE_HEIGHT = HEADER_HEIGHT + PAGE_PADDING * 2;
export const PAGE_WITH_TABS_HEIGHT = HEADER_WITH_TABS_HEIGHT + PAGE_PADDING * 2;
