import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import Page from '@maya/common/Page';
import Loading from '@maya/common/loading/Loading';
import { useAppSelector } from '@maya/store/hooks';
import { selectVisitTypeLoading } from '@maya/store/slices/visit-type';
import VisitTypeForm from './VisitTypeForm';
import useVisitType from './hooks/useVisitType';

import type { FC } from 'react';

const VisitTypeView: FC = () => {
  const { id } = useParams();

  const visitType = useVisitType(id);

  const visitTypeLoading = useAppSelector(selectVisitTypeLoading);

  const title = useMemo(() => {
    if (visitType?.id !== id) {
      return '';
    }

    return visitType?.name ?? '';
  }, [visitType?.name, visitType?.id, id]);

  return (
    <Page title={title} backTo="/settings/visit-type" color="paper">
      {!visitType || visitTypeLoading ? (
        <Loading />
      ) : (
        <VisitTypeForm key="editing-form" visitType={visitType} editMode />
      )}
    </Page>
  );
};

export default VisitTypeView;
