/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { enqueueSnackbar } from 'notistack';

import { isListDTO } from '@maya/interface';
import { isNotNullish } from '@maya/util/null.util';

import type { t } from 'react-polyglot';
import type Rest from './index';

type GetMethods<T> = {
  [P in keyof T]: T[P] extends { method: 'GET'; response: Array<any> } | { method: 'GET'; response: {} } ? P : never;
}[keyof T];

export type RestCollectionKeys = GetMethods<Rest>;
export type RestCollectionEndpoints = Pick<Rest, RestCollectionKeys>;

type METHOD = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'get' | 'post' | 'put' | 'delete';

export interface FetchOptions {
  method?: METHOD;
  body?: BodyInit | null | undefined;
}

/**
 * @return if the fetchOptions have a query parameter
 */
export function hasQueryParams(fetchOptions: FetchOptions): fetchOptions is FetchOptions & { query: QueryMap } {
  return Boolean(fetchOptions && (fetchOptions as any).query);
}
/**
 * Converts an object to a query param string. Array properties are converted
 * into repeated params.
 */
export function getQueryString(query: QueryMap) {
  const keys = Object.keys(query);

  if (keys.length === 0) {
    return '';
  }

  const params: { key: string; value: QueryValues }[] = [];

  keys.forEach((key) => {
    const queryValue = query[key];
    if (Array.isArray(queryValue)) {
      queryValue.forEach((value) => params.push({ key, value }));
    } else {
      params.push({ key, value: queryValue });
    }
  });

  const queryString = params
    .filter(({ value }) => value != null)
    .map(({ key, value }) => `${encodeURIComponent(key)}=${encodeURIComponent(value as string)}`)
    .join('&');

  return queryString ? `?${queryString}` : '';
}

/**
 * Checks to see if all of the values in a query parameter object are empty
 */
export function areValuesEmpty(fields: Record<string, QueryValues>) {
  return !Object.keys(fields).some((name) => Boolean(fields[name]));
}

/**
 * Simple fetch wrapper to get JSON REST API
 */
async function handleFetchRequest<T>(
  url: string,
  t: t,
  options: FetchOptions & { defaultError?: string } = {}
): Promise<T> {
  const { method = 'GET', body = undefined } = options;

  const response = await fetch(url, {
    method,
    headers: new Headers({
      'Content-Type': 'application/json',
      Accept: 'application/json'
    }),
    body: isNotNullish(body) ? JSON.stringify(body) : body
  });

  if (response.status === 204) {
    /*
     * In the case of a NO CONTENT status, we can't call response.json() because it throws an error
     * if there is no response. We've got to cast this here because we don't know at compile time that
     * T is undefined. If we're calling this method and get a 204, we can assume this is intentional T is undefined.
     */
    return undefined as any;
  }

  if (!response.ok) {
    try {
      const text = await response.text();
      const body = JSON.parse(text);
      if ('messageKey' in body) {
        enqueueSnackbar(t(body.messageKey), { variant: 'error' });
        return undefined as any;
      }
      // eslint-disable-next-line no-empty
    } catch {}

    if (options.defaultError) {
      enqueueSnackbar(t(options.defaultError), { variant: 'error' });
    }

    return undefined as any;
  }

  try {
    const text = await response.text();
    return JSON.parse(text);
  } catch {
    return undefined as any;
  }
}

const getRequestsInProgress: Record<string, ((value: any | undefined) => void)[]> = {};
const getDataCache: Record<string, { date: Date; data: any }> = {};

export type QueryValues = string | boolean | number | undefined | null;
export type QueryMap = Record<string, QueryValues | QueryValues[]>;
export interface ExtraFetchOptions {
  force?: boolean;
}

/**
 * @param endpoint string combination of the http method (in caps) colon and the url with params
 * @param request object with maps of all params
 *
 * Fetch endpoint either takes options with no error handler and returns the response type,
 * or takes options with an error handler and returns a union of the response type and the
 * error handler return type
 */
async function fetchRequest<T extends keyof Rest>(
  endpoint: T,
  t: t,
  options: Rest[T]['request'] & { defaultError?: string }
): Promise<Rest[T]['response'] | undefined> {
  const { body = undefined, params = {}, query = undefined, defaultError } = options as any;
  // eslint-disable-next-line prefer-const
  let [method, url] = (endpoint as string).split(':') as [METHOD, string];
  url = `${process.env.REACT_APP_API_URL}${url}`;
  if (params) {
    Object.keys(params).forEach((param) => {
      url = url.replace(`{${param}}`, (params as any)[param]);
    });
  }
  if (query) {
    url += getQueryString(query);
  }

  if (method.toLowerCase() === 'get') {
    const p = new Promise<Rest[T]['response'] | undefined>((resolve) => {
      if (url in getRequestsInProgress) {
        getRequestsInProgress[url].push(resolve);
        return Promise.resolve(undefined);
      } else {
        getRequestsInProgress[url] = [resolve];
      }
    });

    handleFetchRequest<Rest[T]['response'] | undefined>(url, t, { body, method, defaultError }).then((response) => {
      getDataCache[url] = {
        date: new Date(),
        data: response
      };
      const resolveFunctions = getRequestsInProgress[url] ?? [];
      resolveFunctions.forEach((resolveFunction) => resolveFunction(response));
      delete getRequestsInProgress[url];
    });

    return p;
  }

  return handleFetchRequest(url, t, { body, method, defaultError });
}

export async function fetchAllList<T extends keyof Rest>(
  endpoint: T,
  t: t,
  options: Rest[T]['request'] & { defaultError?: string }
): Promise<Rest[T]['response'] | undefined> {
  let response = await fetchRequest(endpoint, t, options);
  if (isListDTO(response)) {
    const { total } = response;
    const returnCount = response.data.length;
    if (total > returnCount) {
      const response2 = await fetchRequest(endpoint, t, {
        ...options,
        query: { start: returnCount, count: response.total - returnCount }
      } as Rest[T]['request'] & { defaultError?: string });
      if (isListDTO(response2)) {
        response = { data: [...response.data, ...response2.data], total: response.total } as Rest[T]['response'];
      }
    }
  }
  return response;
}

export default fetchRequest;
