import type { Forms } from '@maya/interface';

export const formTypeMap: { [key in Forms]: string } = {
  ['PC']: 'patient-consent',
  ['TL']: 'task-list',
  ['RS']: 'route-sheet'
};

export function transformFormCode(formType: Forms): string {
  return formTypeMap[formType];
}
