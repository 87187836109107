import { useEffect, useMemo } from 'react';

import { Forms } from '@maya/interface';
import { useAppDispatch, useAppSelector } from '@maya/store/hooks';
import { loadFormTypes, selectFormTypes } from '@maya/store/slices/static';
import useTranslate from '../useTranslate';

export default function useFormTypes() {
  const dispatch = useAppDispatch();
  const t = useTranslate();

  useEffect(() => {
    dispatch(loadFormTypes(t));
  }, [dispatch, t]);

  const formTypes = useAppSelector(selectFormTypes);

  return useMemo(() => {
    const sortedFormTypes = [...formTypes];
    const index = sortedFormTypes.findIndex((ft) => ft.code === Forms.ROUTE_SHEET);
    if (index >= 0) {
      const routeSheet = sortedFormTypes.splice(index, 1)[0];
      return [routeSheet, ...sortedFormTypes];
    }

    return sortedFormTypes;
  }, [formTypes]);
}
