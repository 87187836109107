import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useCallback, useEffect, useMemo, useState } from 'react';

import DateField from '@maya/common/date/DateField';
import Select from '@maya/common/select/Select';
import PhoneNumberField from '@maya/common/textfield/PhoneNumberField';
import SSNField from '@maya/common/textfield/SSNField';
import TextField from '@maya/common/textfield/TextField';
import STATES from '@maya/constants/states';
import useFormValidationData from '@maya/hooks/useFormValidationData';
import { useIsSmallScreen } from '@maya/hooks/useMediaQuery';
import useTranslate from '@maya/hooks/useTranslate';
import { useAppDispatch, useAppSelector } from '@maya/store/hooks';
import { createUser, saveUser, selectUserSaveInProgress } from '@maya/store/slices/user';

import type { CreateEmployeeDTO, EmployeeDTO } from '@maya/interface';
import type { FC } from 'react';

export interface UserFormProps {
  user?: EmployeeDTO;
  editMode?: boolean;
  onClose?: () => void;
}

const UserForm: FC<UserFormProps> = ({ user, editMode = false, onClose }) => {
  const t = useTranslate();
  const isSmallScreen = useIsSmallScreen();

  const [version, setVersion] = useState(0);
  const [data, setData] = useState<Partial<CreateEmployeeDTO>>({ ...(user ?? {}) });
  const [formIsValid, setFormValidationData] = useFormValidationData(user != null);

  const saveInProgress = useAppSelector(selectUserSaveInProgress);

  const updateData = useCallback((newData: Partial<EmployeeDTO>) => {
    setData((oldData) => ({
      ...oldData,
      ...newData
    }));
  }, []);

  const dispatch = useAppDispatch();

  const handleValidate = useCallback(
    (key: string) => (valid: boolean) => {
      setFormValidationData(key, valid);
    },
    [setFormValidationData]
  );

  const [editing, setEditing] = useState(false);
  const startEditing = useCallback(() => {
    setEditing(true);
  }, []);
  const endEditing = useCallback(() => {
    setData(user ?? {});
    setVersion((oldVersion) => oldVersion + 1);
    setEditing(false);
  }, [user]);

  const handleSave = useCallback(async () => {
    if (!formIsValid) {
      return;
    }

    if (user) {
      if (!(await dispatch(saveUser({ ...user, ...data }, t)))) {
        return;
      }
    } else {
      if (!(await dispatch(createUser(data as CreateEmployeeDTO, t)))) {
        return;
      }
    }

    if (editMode) {
      setEditing(false);
    } else {
      onClose?.();
    }
  }, [formIsValid, user, editMode, dispatch, data, t, onClose]);

  const handleClose = useCallback(() => {
    onClose?.();
  }, [onClose]);

  useEffect(() => {
    setData(user ?? {});
    setVersion((oldVersion) => oldVersion + 1);
  }, [user]);

  const direction = useMemo(() => (isSmallScreen ? 'column' : 'row'), [isSmallScreen]);

  return (
    <Box key={version} component="form" autoComplete="off" sx={{ paddingBottom: '16px', maxWidth: '1032px' }}>
      <Stack spacing={2}>
        <Stack direction={direction} spacing={3} sx={{ width: '100%' }}>
          <TextField
            data-testid="firstName"
            required
            label={t('app.form.firstName')}
            defaultValue={user?.firstName}
            onChange={(newValue) => updateData({ firstName: newValue })}
            onValidate={handleValidate('firstName')}
            disabled={editMode && !editing}
          />
          <TextField
            data-testid="middleInitial"
            label={t('app.form.middleInitial')}
            defaultValue={user?.middleInitial}
            onChange={(newValue) => updateData({ middleInitial: newValue })}
            onValidate={handleValidate('middleInitial')}
            disabled={editMode && !editing}
          />
          <TextField
            data-testid="lastName"
            required
            label={t('app.form.lastName')}
            defaultValue={user?.lastName}
            onChange={(newValue) => updateData({ lastName: newValue })}
            onValidate={handleValidate('lastName')}
            disabled={editMode && !editing}
          />
        </Stack>
        <Stack direction={direction} spacing={3}>
          <DateField
            data-testid="dob"
            required
            label={t('app.form.dateOfBirth')}
            defaultValue={user?.dob}
            onChange={(newValue) => updateData({ dob: newValue })}
            onValidate={handleValidate('dob')}
            disabled={editMode && !editing}
          />
          <Select
            data-testid="gender"
            required
            label={t('app.form.gender')}
            defaultValue={user?.gender}
            options={[
              {
                value: 'm',
                label: t('app.gender.m')
              },
              {
                value: 'f',
                label: t('app.gender.f')
              }
            ]}
            onChange={(newValue) => updateData({ gender: newValue })}
            onValidate={handleValidate('gender')}
            disabled={editMode && !editing}
          />
          <SSNField
            data-testid="ssn"
            required
            label={t('app.form.socialSecurityNumber')}
            defaultValue={user?.ssn}
            onChange={(newValue) => updateData({ ssn: newValue })}
            onValidate={handleValidate('ssn')}
            disabled={editMode && !editing}
          />
        </Stack>
        <Stack direction={direction} spacing={3}>
          <TextField
            data-testid="street"
            required
            label={t('app.form.address')}
            defaultValue={user?.street}
            onChange={(newValue) => updateData({ street: newValue })}
            onValidate={handleValidate('street')}
            disabled={editMode && !editing}
          />
          <TextField
            data-testid="city"
            required
            label={t('app.form.city')}
            defaultValue={user?.city}
            onChange={(newValue) => updateData({ city: newValue })}
            onValidate={handleValidate('city')}
            disabled={editMode && !editing}
          />
          <Select
            data-testid="state"
            required
            label={t('app.form.state')}
            defaultValue={user?.state}
            options={STATES}
            onChange={(newValue) => updateData({ state: newValue })}
            onValidate={handleValidate('state')}
            disabled={editMode && !editing}
          />
          <TextField
            data-testid="zip"
            required
            label={t('app.form.zip')}
            defaultValue={user?.zip}
            placeholder="#####"
            pattern="^[0-9]{5}(-[0-9]{4})?$"
            onChange={(newValue) => updateData({ zip: newValue })}
            onValidate={handleValidate('zip')}
            disabled={editMode && !editing}
          />
        </Stack>
        <Stack direction={direction} spacing={3}>
          <PhoneNumberField
            data-testid="phone"
            required
            label={t('app.form.phone')}
            defaultValue={user?.phone}
            onChange={(newValue) => updateData({ phone: newValue })}
            onValidate={handleValidate('phone')}
            disabled={editMode && !editing}
          />
          <PhoneNumberField
            data-testid="cell"
            label={t('app.form.cell')}
            defaultValue={user?.cell}
            onChange={(newValue) => updateData({ cell: newValue })}
            onValidate={handleValidate('cell')}
            disabled={editMode && !editing}
          />
        </Stack>
        <Stack direction={direction} spacing={3}>
          <TextField
            data-testid="email"
            required
            label={t('app.form.email')}
            defaultValue={user?.email}
            pattern="^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$"
            onChange={(newValue) => updateData({ email: newValue })}
            onValidate={handleValidate('email')}
            disabled={editMode && !editing}
          />
          <TextField
            data-testid="username"
            required
            label={t('app.form.username')}
            defaultValue={user?.username}
            onChange={(newValue) => updateData({ username: newValue })}
            onValidate={handleValidate('username')}
            disabled={editMode && !editing}
          />
        </Stack>
        <Stack direction="row" spacing={3}>
          {!editMode || editing ? (
            <>
              <Button
                data-testid="save"
                variant="contained"
                onClick={handleSave}
                disabled={!formIsValid || saveInProgress}
              >
                {t('app.save')}
              </Button>
              <Button data-testid="cancel" onClick={editMode ? endEditing : handleClose}>
                {t('app.cancel')}
              </Button>
            </>
          ) : (
            <Button
              data-testid="edit"
              variant="contained"
              onClick={startEditing}
              disabled={!formIsValid || saveInProgress}
            >
              {t('app.edit')}
            </Button>
          )}
        </Stack>
      </Stack>
    </Box>
  );
};

export default UserForm;
