import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import MuiAutocomplete from '@mui/material/Autocomplete';
import * as React from 'react';
import { useCallback, useMemo } from 'react';
import TextField from '@mui/material/TextField';

import useValidate from '@maya/hooks/useValidate';

import type { ReactNode } from 'react';
import type { AutocompleteProps as MuiAutocompleteProps } from '@mui/material/Autocomplete';
import type { AutocompleteValue } from '@mui/base/useAutocomplete/useAutocomplete';

export interface AutocompleteProps<T extends string | number | readonly string[] | undefined>
  extends Omit<MuiAutocompleteProps<T, undefined, undefined, undefined>, 'id' | 'onChange' | 'renderInput'> {
  label: string;
  getOptionLabel: (value: T) => string;
  variant?: 'standard' | 'outlined' | 'filled';
  required?: boolean;
  error?: boolean;
  helperText?: ReactNode;
  onChange: (value: T | undefined) => void;
  onValidate?: (valid: boolean) => void;
}

const Autocomplete = function <T extends string | undefined>({
  label,
  options,
  defaultValue,
  variant = 'filled',
  sx,
  fullWidth = true,
  required = false,
  error,
  helperText,
  onChange,
  onValidate,
  getOptionLabel,
  ...props
}: AutocompleteProps<T>) {
  const id = useMemo(() => label.toLowerCase().replace(/ /g, '-'), [label]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const defaultValueMemoized = useMemo(
    () => (defaultValue ?? null) as AutocompleteValue<T, undefined, undefined, undefined>,
    [defaultValue]
  );

  const { validate, hint, hasError } = useValidate({
    defaultValue: defaultValueMemoized,
    required,
    error,
    helperText,
    onValidate
  });

  const handleChange = useCallback(
    (event: React.SyntheticEvent, newValue: T | null | undefined) => {
      onChange(newValue ?? undefined);
      validate(newValue);
    },
    [validate, onChange]
  );

  return (
    <FormControl variant={variant} sx={sx} required={required} fullWidth={fullWidth} error={hasError}>
      <MuiAutocomplete
        autoComplete
        id={id}
        defaultValue={defaultValueMemoized}
        options={options}
        getOptionLabel={getOptionLabel}
        onChange={handleChange}
        renderInput={(params) => <TextField label={label} required={required} variant={variant} {...params} />}
        {...props}
      />
      <FormHelperText>{hint ?? <>&nbsp;</>}</FormHelperText>
    </FormControl>
  );
};

export default Autocomplete;
