import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import FullCalendar from '@fullcalendar/react';
import dayjs from 'dayjs';
import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';

import '@maya/common/FullCalendar.css';
import Loading from '@maya/common/loading/Loading';
import useBranchId from '@maya/hooks/useBranchId';
import { useIsSmallScreen } from '@maya/hooks/useMediaQuery';
import useTranslate from '@maya/hooks/useTranslate';
import VisitEditModal from '@maya/patient/visits/VisitEditModal';
import VisitViewModal from '@maya/patient/visits/VisitViewModal';
import { useAppDispatch, useAppSelector } from '@maya/store/hooks';
import { deleteVisit, selectVisitIsLoading } from '@maya/store/slices/visit';

import type { DatesSetArg, EventClickArg } from '@fullcalendar/core';
import type { DateClickArg } from '@fullcalendar/interaction';
import type DateRangeISO from '@maya/common/date/DateRangeISO';
import type { PatientDTO, VisitDTO } from '@maya/interface';
import type { FC } from 'react';

export interface VisitsCalendarProps {
  patient?: PatientDTO;
  visits: VisitDTO[];
  onDateRangeChange: (dateRangeISO: DateRangeISO) => void;
  showPatientInSummary?: boolean;
}

enum VisitModalMode {
  closed,
  view,
  edit
}

const VisitsCalendar: FC<VisitsCalendarProps> = ({ patient, visits, showPatientInSummary, onDateRangeChange }) => {
  const branchId = useBranchId();
  const dispatch = useAppDispatch();
  const [selectedVisit, setSelectedVisit] = useState<Partial<VisitDTO>>();
  const [modalMode, setModalMode] = useState(VisitModalMode.closed);
  const calendarRef = useRef<FullCalendar | null>(null);
  const isSmallScreen = useIsSmallScreen();

  const isVisitsLoading = useAppSelector(selectVisitIsLoading);

  const selectedVisitId = selectedVisit?.id;
  const patientId = patient?.id;

  const t = useTranslate();

  useLayoutEffect(() => {
    if (isSmallScreen) {
      calendarRef.current?.getApi().changeView('listMonth', new Date());
    } else {
      calendarRef.current?.getApi().changeView('dayGridMonth', new Date());
    }
  }, [isSmallScreen]);

  const handleDatesSet = useCallback(
    (calendarDates: DatesSetArg) => {
      onDateRangeChange({ start: calendarDates.startStr, end: calendarDates.endStr });
    },
    [onDateRangeChange]
  );

  const handleEventClick = useCallback(
    (eventClick: EventClickArg) => {
      const foundVisit = visits.find((visit) => visit.id === eventClick.event.id);
      setSelectedVisit(foundVisit);
      setModalMode(VisitModalMode.view);
    },
    [visits, setSelectedVisit]
  );

  const handleDateClick = useCallback(
    (dateClick: DateClickArg) => {
      setSelectedVisit({ patient, scheduledDate: dateClick.date.getTime() });
      setModalMode(VisitModalMode.edit);
    },
    [patient, setSelectedVisit]
  );

  const handleVisitModalClose = useCallback(() => {
    setModalMode(VisitModalMode.closed);
  }, [setModalMode]);

  const handleEditModalClose = useCallback(() => {
    setModalMode(VisitModalMode.closed);
  }, [setModalMode]);

  const handleViewModalDelete = useCallback(() => {
    setModalMode(VisitModalMode.closed);
    if (branchId && selectedVisitId) {
      dispatch(deleteVisit(branchId, selectedVisitId, t));
    }
  }, [branchId, selectedVisitId, dispatch, t]);

  const handleViewModalEdit = useCallback(() => {
    setModalMode(VisitModalMode.edit);
  }, [setModalMode]);

  const events = visits.map((visit) => {
    return {
      id: visit.id,
      start: dayjs(visit.scheduledDate).format(),
      end: dayjs(visit.scheduledDate).format(),
      title: `${visit.visitTypeName}`,
      allDay: false,
      status: visit.status,
      className: `event-status-${visit.status}`
    };
  });

  // Default to no modal (closed).
  let modal: React.JSX.Element | null = null;
  if (selectedVisit) {
    if (modalMode === VisitModalMode.view) {
      modal = (
        <VisitViewModal
          visit={selectedVisit}
          onDelete={handleViewModalDelete}
          onEdit={handleViewModalEdit}
          onClose={handleVisitModalClose}
          showPatientInSummary={showPatientInSummary}
        />
      );
    } else if (modalMode === VisitModalMode.edit) {
      modal = patientId ? (
        <VisitEditModal patientId={patientId} visit={selectedVisit} onClose={handleEditModalClose} />
      ) : null;
    }
  }

  return (
    <>
      {isVisitsLoading ? <Loading /> : null}
      <FullCalendar
        ref={calendarRef}
        plugins={[dayGridPlugin, interactionPlugin, listPlugin]}
        initialView="dayGridMonth"
        displayEventTime={false}
        events={events}
        datesSet={handleDatesSet}
        eventClick={handleEventClick}
        dateClick={handleDateClick}
      />
      {modal}
    </>
  );
};

export default VisitsCalendar;
