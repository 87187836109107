import IconButton from '@mui/material/IconButton';
import { SnackbarProvider as NotistackProvider, useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';

import type { FC } from 'react';
import type { SnackbarKey, SnackbarProviderProps as NotistackProviderProps } from 'notistack';

interface SnackbarCloseButtonProps {
  snackbarKey: SnackbarKey;
}

const SnackbarCloseButton: FC<SnackbarCloseButtonProps> = ({ snackbarKey }) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton onClick={() => closeSnackbar(snackbarKey)} color="inherit">
      <CloseIcon />
    </IconButton>
  );
};

export interface SnackbarProviderProps {
  children: NotistackProviderProps['children'];
}

const SnackbarProvider: FC<SnackbarProviderProps> = ({ children }) => {
  return (
    <NotistackProvider
      maxSnack={1}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      action={(snackbarKey) => <SnackbarCloseButton snackbarKey={snackbarKey} />}
      autoHideDuration={5000}
    >
      {children}
    </NotistackProvider>
  );
};

export default SnackbarProvider;
