import Box from '@mui/material/Box';
import { useState } from 'react';

import '@maya/common/FullCalendar.css';
import { useIsSmallScreen } from '@maya/hooks/useMediaQuery';
import VisitsCalendar from '@maya/patient/visits/calendar/VisitsCalendar';
import useEmployeeVisits from './hooks/useEmployeeVisits';

import type DateRangeISO from '@maya/common/date/DateRangeISO';
import type { FC } from 'react';

const Schedule: FC = () => {
  const [dateRangeISO, setDateRangeISO] = useState<DateRangeISO>();

  const visits = useEmployeeVisits(dateRangeISO);

  const isSmallScreen = useIsSmallScreen();

  return (
    <Box sx={{ margin: isSmallScreen ? '-16px' : 0, height: '100%' }}>
      <VisitsCalendar visits={visits} onDateRangeChange={setDateRangeISO} showPatientInSummary />
    </Box>
  );
};

export default Schedule;
