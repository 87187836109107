import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useCallback, useMemo, useState } from 'react';

import DateField from '@maya/common/date/DateField';
import Select from '@maya/common/select/Select';
import PhoneNumberField from '@maya/common/textfield/PhoneNumberField';
import SSNField from '@maya/common/textfield/SSNField';
import TextField from '@maya/common/textfield/TextField';
import STATES from '@maya/constants/states';
import useDisciplines from '@maya/hooks/static/useDisciplines';
import useBranchId from '@maya/hooks/useBranchId';
import useFormValidationData from '@maya/hooks/useFormValidationData';
import { useIsSmallScreen } from '@maya/hooks/useMediaQuery';
import useTranslate from '@maya/hooks/useTranslate';
import { useAppDispatch, useAppSelector } from '@maya/store/hooks';
import { createEmployee, saveEmployee, selectEmployeeSaveInProgress } from '@maya/store/slices/employee';

import type { CreateEmployeeDTO, EmployeeDTO } from '@maya/interface';
import type { FC } from 'react';

export interface EmployeeFormProps {
  employee?: EmployeeDTO;
  onClose: () => void;
}

const EmployeeForm: FC<EmployeeFormProps> = ({ employee, onClose }) => {
  const t = useTranslate();
  const isSmallScreen = useIsSmallScreen();

  const [data, setData] = useState<Partial<CreateEmployeeDTO>>({ ...(employee ?? {}) });
  const [formIsValid, setFormValidationData] = useFormValidationData(employee != null);

  const saveInProgress = useAppSelector(selectEmployeeSaveInProgress);

  const updateData = useCallback((newData: Partial<EmployeeDTO>) => {
    setData((oldData) => ({
      ...oldData,
      ...newData
    }));
  }, []);

  const dispatch = useAppDispatch();
  const branchId = useBranchId();

  const disciplines = useDisciplines();

  const handleValidate = useCallback(
    (key: string) => (valid: boolean) => {
      setFormValidationData(key, valid);
    },
    [setFormValidationData]
  );

  const handleSave = useCallback(async () => {
    if (!formIsValid) {
      return;
    }

    if (branchId) {
      if (employee) {
        if (!(await dispatch(saveEmployee(branchId, { ...employee, ...data }, t)))) {
          return;
        }
      } else {
        if (!(await dispatch(createEmployee(branchId, data as CreateEmployeeDTO, t)))) {
          return;
        }
      }
    }
    onClose();
  }, [formIsValid, branchId, onClose, employee, dispatch, data, t]);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const direction = useMemo(() => (isSmallScreen ? 'column' : 'row'), [isSmallScreen]);

  return (
    <Box component="form" autoComplete="off" sx={{ paddingBottom: '16px', maxWidth: '1032px' }}>
      <Stack spacing={2}>
        <Stack direction={direction} spacing={3} sx={{ width: '100%' }}>
          <TextField
            data-testid="firstName"
            required
            label={t('app.form.firstName')}
            defaultValue={employee?.firstName}
            onChange={(newValue) => updateData({ firstName: newValue })}
            onValidate={handleValidate('firstName')}
          />
          <TextField
            data-testid="middleInitial"
            label={t('app.form.middleInitial')}
            defaultValue={employee?.middleInitial}
            onChange={(newValue) => updateData({ middleInitial: newValue })}
            onValidate={handleValidate('middleInitial')}
          />
          <TextField
            data-testid="lastName"
            required
            label={t('app.form.lastName')}
            defaultValue={employee?.lastName}
            onChange={(newValue) => updateData({ lastName: newValue })}
            onValidate={handleValidate('lastName')}
          />
        </Stack>
        <Stack direction={direction} spacing={3}>
          <DateField
            data-testid="dob"
            required
            label={t('app.form.dateOfBirth')}
            defaultValue={employee?.dob}
            onChange={(newValue) => updateData({ dob: newValue })}
            onValidate={handleValidate('dob')}
          />
          <Select
            data-testid="gender"
            required
            label={t('app.form.gender')}
            defaultValue={employee?.gender}
            options={[
              {
                value: 'm',
                label: t('app.gender.m')
              },
              {
                value: 'f',
                label: t('app.gender.f')
              }
            ]}
            onChange={(newValue) => updateData({ gender: newValue })}
            onValidate={handleValidate('gender')}
          />
          <SSNField
            data-testid="ssn"
            required
            label={t('app.form.socialSecurityNumber')}
            defaultValue={employee?.ssn}
            onChange={(newValue) => updateData({ ssn: newValue })}
            onValidate={handleValidate('ssn')}
          />
        </Stack>
        <Stack direction={direction} spacing={3}>
          <TextField
            data-testid="street"
            required
            label={t('app.form.address')}
            defaultValue={employee?.street}
            onChange={(newValue) => updateData({ street: newValue })}
            onValidate={handleValidate('street')}
          />
          <TextField
            data-testid="city"
            required
            label={t('app.form.city')}
            defaultValue={employee?.city}
            onChange={(newValue) => updateData({ city: newValue })}
            onValidate={handleValidate('city')}
          />
          <Select
            data-testid="state"
            required
            label={t('app.form.state')}
            defaultValue={employee?.state}
            options={STATES}
            onChange={(newValue) => updateData({ state: newValue })}
            onValidate={handleValidate('state')}
          />
          <TextField
            data-testid="zip"
            required
            label={t('app.form.zip')}
            defaultValue={employee?.zip}
            placeholder="#####"
            pattern="^[0-9]{5}(-[0-9]{4})?$"
            onChange={(newValue) => updateData({ zip: newValue })}
            onValidate={handleValidate('zip')}
          />
        </Stack>
        <Stack direction={direction} spacing={3}>
          <PhoneNumberField
            data-testid="phone"
            required
            label={t('app.form.phone')}
            defaultValue={employee?.phone}
            onChange={(newValue) => updateData({ phone: newValue })}
            onValidate={handleValidate('phone')}
          />
          <PhoneNumberField
            data-testid="cell"
            label={t('app.form.cell')}
            defaultValue={employee?.cell}
            onChange={(newValue) => updateData({ cell: newValue })}
            onValidate={handleValidate('cell')}
          />
        </Stack>
        <Stack direction={direction} spacing={3}>
          <TextField
            data-testid="email"
            required
            label={t('app.form.email')}
            defaultValue={employee?.email}
            pattern="^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$"
            onChange={(newValue) => updateData({ email: newValue })}
            onValidate={handleValidate('email')}
          />
          <TextField
            data-testid="username"
            required
            label={t('app.form.username')}
            defaultValue={employee?.username}
            onChange={(newValue) => updateData({ username: newValue })}
            onValidate={handleValidate('username')}
          />
        </Stack>
        <Stack direction={direction} spacing={3}>
          <Select
            data-testid="role"
            required
            label={t('app.form.role')}
            defaultValue={employee?.role}
            options={[
              {
                value: 'OFFICE',
                label: t('employees.roles.OFFICE')
              },
              {
                value: 'FIELD',
                label: t('employees.roles.FIELD')
              },
              {
                value: 'BRANCH_ADMIN',
                label: t('employees.roles.BRANCH_ADMIN')
              }
            ]}
            onChange={(newValue) => updateData({ role: newValue })}
            onValidate={handleValidate('role')}
          />
          <Select
            data-testid="discipline"
            key="discipline-select"
            required={data.role === 'FIELD'}
            label={t('app.form.discipline')}
            defaultValue={employee?.disciplineId}
            options={disciplines.map((discipline) => ({
              value: discipline.id,
              label: discipline.name
            }))}
            onChange={(newValue) => updateData({ disciplineId: newValue })}
            onValidate={handleValidate('discipline')}
            sx={{ visibility: data.role === 'FIELD' ? 'visible' : 'hidden' }}
          />
        </Stack>
        <Stack direction="row" spacing={3}>
          <Button data-testid="save" variant="contained" onClick={handleSave} disabled={!formIsValid || saveInProgress}>
            {t('app.save')}
          </Button>
          <Button data-testid="cancel" onClick={handleClose}>
            {t('app.cancel')}
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default EmployeeForm;
