import { combineReducers, configureStore } from '@reduxjs/toolkit';

import authReducer from './slices/auth';
import companyReducer from './slices/company';
import employeeReducer from './slices/employee';
import patientReducer from './slices/patient';
import patientConsentReducer from './slices/patient-consent';
import payrollReducer from './slices/payroll';
import recordReducer from './slices/record';
import routeSheetReducer from './slices/route-sheet';
import staticReducer from './slices/static';
import taskListReducer from './slices/task-list';
import userReducer from './slices/user';
import visitReducer from './slices/visit';
import visitTypeReducer from './slices/visit-type';

import type { AnyAction, PayloadAction } from '@reduxjs/toolkit';

const appReducer = combineReducers({
  auth: authReducer,
  company: companyReducer,
  employee: employeeReducer,
  patient: patientReducer,
  patientConsent: patientConsentReducer,
  payroll: payrollReducer,
  record: recordReducer,
  routeSheet: routeSheetReducer,
  static: staticReducer,
  taskList: taskListReducer,
  user: userReducer,
  visit: visitReducer,
  visitType: visitTypeReducer
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducerProxy = (state: any, action: AnyAction) => {
  if (action.type === 'auth/clearAuth') {
    const clearAuthAction = action as PayloadAction<{ failed?: boolean } | undefined>;
    if (!clearAuthAction.payload?.failed) {
      return appReducer(undefined, action);
    }
  }
  return appReducer(state, action);
};

export default function () {
  return configureStore({
    reducer: reducerProxy
  });
}
