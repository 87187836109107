import Box from '@mui/material/Box';
import dayjs from 'dayjs';
import { useCallback, useMemo } from 'react';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import useValidate from '@maya/hooks/useValidate';

import type { TextFieldVariants } from '@mui/material/TextField';
import type { TimePickerProps } from '@mui/x-date-pickers/TimePicker';
import type { Dayjs } from 'dayjs';
import type { FC, ReactNode } from 'react';

export interface TimeFieldProps extends Omit<TimePickerProps<Dayjs>, 'onChange' | 'defaultValue' | 'value'> {
  defaultValue: string | undefined;
  required?: boolean;
  helperText?: ReactNode;
  variant?: TextFieldVariants;
  fullWidth?: boolean;
  pattern?: string;
  error?: boolean;
  'data-testid'?: string;
  onChange: (value: string | undefined) => void;
  onValidate?: (valid: boolean) => void;
}

const TimeField: FC<TimeFieldProps> = ({
  required = false,
  defaultValue,
  helperText,
  variant = 'filled',
  fullWidth = true,
  pattern,
  error,
  onChange,
  onValidate,
  'data-testid': dataTestId,
  ...props
}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const defaultTimeValue = useMemo(() => (defaultValue ? dayjs(`2023-06-28 ${defaultValue}`) : undefined), []);

  const { validate, hint, hasError } = useValidate({
    defaultValue: defaultTimeValue,
    required,
    pattern,
    error,
    helperText,
    onValidate
  });

  const handleOnChange = useCallback(
    (newValue: Dayjs | null) => {
      onChange(newValue?.format('hh:mm A'));
      validate(newValue);
    },
    [onChange, validate]
  );

  return (
    <Box data-testid={dataTestId} sx={{ width: fullWidth ? '100%' : undefined }}>
      <TimePicker
        defaultValue={defaultTimeValue}
        onChange={handleOnChange}
        slotProps={{
          textField: {
            helperText: hint ?? <>&nbsp;</>,
            required,
            variant,
            fullWidth,
            error: hasError
          }
        }}
        {...props}
      />
    </Box>
  );
};

export default TimeField;
