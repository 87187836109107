import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useCallback, useMemo, useState } from 'react';

import DateField from '@maya/common/date/DateField';
import Select from '@maya/common/select/Select';
import PhoneNumberField from '@maya/common/textfield/PhoneNumberField';
import SSNField from '@maya/common/textfield/SSNField';
import TextField from '@maya/common/textfield/TextField';
import STATES from '@maya/constants/states';
import useBranchId from '@maya/hooks/useBranchId';
import useFormValidationData from '@maya/hooks/useFormValidationData';
import { useIsSmallScreen } from '@maya/hooks/useMediaQuery';
import useTranslate from '@maya/hooks/useTranslate';
import { useAppDispatch, useAppSelector } from '@maya/store/hooks';
import { createPatient, savePatient, selectPatientSaveInProgress } from '@maya/store/slices/patient';

import type { CreatePatientDTO, PatientDTO } from '@maya/interface';
import type { FC } from 'react';

export interface PatientFormProps {
  patient?: PatientDTO;
  onClose: () => void;
}

const PatientOverviewForm: FC<PatientFormProps> = ({ patient, onClose }) => {
  const t = useTranslate();
  const isSmallScreen = useIsSmallScreen();

  const [data, setData] = useState<Partial<PatientDTO>>({ ...(patient ?? {}) });
  const [formIsValid, setFormValidationData] = useFormValidationData(patient != null);

  const branchId = useBranchId();
  const dispatch = useAppDispatch();
  const saveInProgress = useAppSelector(selectPatientSaveInProgress);

  const updateData = useCallback((newData: Partial<CreatePatientDTO>) => {
    setData((oldData) => ({
      ...oldData,
      ...newData
    }));
  }, []);

  const handleValidate = useCallback(
    (key: string) => (valid: boolean) => {
      setFormValidationData(key, valid);
    },
    [setFormValidationData]
  );

  const handleSave = useCallback(async () => {
    if (!formIsValid) {
      return;
    }

    if (branchId) {
      if (patient) {
        if (!(await dispatch(savePatient(branchId, { ...patient, ...data }, t)))) {
          return;
        }
      } else {
        if (!(await dispatch(createPatient(branchId, data as CreatePatientDTO, t)))) {
          return;
        }
      }
    }
    onClose();
  }, [formIsValid, branchId, onClose, patient, dispatch, data, t]);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const direction = useMemo(() => (isSmallScreen ? 'column' : 'row'), [isSmallScreen]);

  return (
    <Box
      component="form"
      autoComplete="off"
      sx={{ paddingBottom: '16px', maxWidth: '978px' }}
      data-testid="patient-overview-form"
    >
      <Stack spacing={2}>
        <Stack direction={direction} spacing={3} sx={{ width: '100%' }}>
          <TextField
            data-testid="firstName"
            required
            label={t('app.form.firstName')}
            defaultValue={patient?.firstName}
            onChange={(newValue) => updateData({ firstName: newValue })}
            onValidate={handleValidate('firstName')}
          />
          <TextField
            data-testid="middleInitial"
            label={t('app.form.middleInitial')}
            defaultValue={patient?.middleInitial}
            onChange={(newValue) => updateData({ middleInitial: newValue })}
            onValidate={handleValidate('middleInitial')}
          />
          <TextField
            data-testid="lastName"
            required
            label={t('app.form.lastName')}
            defaultValue={patient?.lastName}
            onChange={(newValue) => updateData({ lastName: newValue })}
            onValidate={handleValidate('lastName')}
          />
        </Stack>
        <Stack direction={direction} spacing={3}>
          <DateField
            data-testid="dob"
            required
            label={t('app.form.dateOfBirth')}
            defaultValue={patient?.dob}
            onChange={(newValue) => updateData({ dob: newValue })}
            onValidate={handleValidate('dob')}
          />
          <Select
            data-testid="gender"
            required
            label={t('app.form.gender')}
            defaultValue={patient?.gender}
            options={[
              {
                value: 'm',
                label: t('app.gender.m')
              },
              {
                value: 'f',
                label: t('app.gender.f')
              }
            ]}
            onChange={(newValue) => updateData({ gender: newValue })}
            onValidate={handleValidate('gender')}
          />
          <SSNField
            data-testid="ssn"
            required
            label={t('app.form.socialSecurityNumber')}
            defaultValue={patient?.ssn}
            onChange={(newValue) => updateData({ ssn: newValue })}
            onValidate={handleValidate('ssn')}
          />
          <TextField
            data-testid="insurance-id"
            required
            label={t('app.form.insuranceId')}
            defaultValue={patient?.insuranceId}
            onChange={(newValue) => updateData({ insuranceId: newValue })}
            onValidate={handleValidate('insuranceId')}
          />
        </Stack>
        <Stack direction={direction} spacing={3}>
          <PhoneNumberField
            data-testid="phone-1"
            required
            label={t('app.form.phone')}
            defaultValue={patient?.phone1}
            onChange={(newValue) => updateData({ phone1: newValue })}
            onValidate={handleValidate('phone1')}
          />
          <PhoneNumberField
            data-testid="phone-2"
            label={t('app.form.secondPhone')}
            defaultValue={patient?.phone2}
            onChange={(newValue) => updateData({ phone2: newValue })}
            onValidate={handleValidate('phone2')}
          />
        </Stack>
        <Stack direction={direction} spacing={3}>
          <TextField
            data-testid="street"
            required
            label={t('app.form.address')}
            defaultValue={patient?.street}
            onChange={(newValue) => updateData({ street: newValue })}
            onValidate={handleValidate('street')}
          />
          <TextField
            data-testid="city"
            required
            label={t('app.form.city')}
            defaultValue={patient?.city}
            onChange={(newValue) => updateData({ city: newValue })}
            onValidate={handleValidate('city')}
          />
          <Select
            data-testid="state"
            required
            label={t('app.form.state')}
            defaultValue={patient?.state}
            options={STATES}
            onChange={(newValue) => updateData({ state: newValue })}
            onValidate={handleValidate('state')}
          />
          <TextField
            data-testid="zip"
            required
            label={t('app.form.zip')}
            defaultValue={patient?.zip}
            placeholder="#####"
            pattern="^[0-9]{5}(-[0-9]{4})?$"
            onChange={(newValue) => updateData({ zip: newValue })}
            onValidate={handleValidate('zip')}
          />
        </Stack>
        <Stack direction="row" spacing={3}>
          <Button data-testid="save" variant="contained" onClick={handleSave} disabled={!formIsValid || saveInProgress}>
            {t('app.save')}
          </Button>
          <Button data-testid="cancel" onClick={handleClose}>
            {t('app.cancel')}
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default PatientOverviewForm;
