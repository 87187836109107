import { createSlice } from '@reduxjs/toolkit';

import Api from '@maya/api/api';
import fetch, { fetchAllList } from '@maya/api/fetch';

import type { CompaniesDTO, CompanyDTO, CompanyWithUserDTO, CreateCompanyDTO, EmployeeDTO } from '@maya/interface';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { t } from 'react-polyglot';
import type { AppDispatch, RootState } from '../index';

// Define a type for the slice state
export interface Companiestate {
  company?: CompanyDTO | CompanyWithUserDTO;
  companies: CompanyDTO[];
  total: number;
  isLoading: boolean;
  saveInProgress: boolean;
  resendInProgress: boolean;
}

// Define the initial state using that type
const initialState: Companiestate = {
  companies: [],
  total: 0,
  isLoading: false,
  saveInProgress: false,
  resendInProgress: false
};

export const companieslice = createSlice({
  name: 'company',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setCompanyLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    updateCompanies: (state, action: PayloadAction<CompaniesDTO>) => {
      state.companies = action.payload.data;
      state.total = action.payload.data.length;
      state.isLoading = false;
    },
    updateCompany: (state, action: PayloadAction<CompanyDTO>) => {
      const company = action.payload;

      const index = state.companies.findIndex((e) => e.id === company.id);
      if (index >= 0) {
        state.companies[index] = company;
      }

      state.company = action.payload;
      state.isLoading = false;
      state.saveInProgress = false;
      state.resendInProgress = false;
    },
    updateCompanyInviteDate: (state, action: PayloadAction<EmployeeDTO>) => {
      const user = action.payload;

      if (state.company) {
        state.company = {
          ...state.company,
          inviteDate: user.inviteDate
        };
      }

      state.isLoading = false;
      state.saveInProgress = false;
      state.resendInProgress = false;
    },
    setSaveInProgress: (state, action: PayloadAction<boolean>) => {
      state.saveInProgress = action.payload;
    },
    setCompanyResendInProgress: (state, action: PayloadAction<boolean>) => {
      state.resendInProgress = action.payload;
    }
  }
});

export const {
  setCompanyLoading,
  updateCompanies,
  updateCompany,
  setSaveInProgress,
  updateCompanyInviteDate,
  setCompanyResendInProgress
} = companieslice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectCompanies = (state: RootState) => state.company.companies;
export const selectTotalCompanies = (state: RootState) => state.company.total;

export const selectCompany = (state: RootState) => state.company.company;
export const selectCompanyLoading = (state: RootState) => state.company.isLoading;
export const selectCompanieSaveInProgress = (state: RootState) => state.company.saveInProgress;
export const selectCompanieResendInProgress = (state: RootState) => state.company.resendInProgress;

export const loadAdminCompanies =
  (search: string, t: t) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    if (state.company.isLoading) {
      return;
    }

    dispatch(setCompanyLoading(true));

    const response = await fetchAllList(Api.admin_companies_Get, t, { query: { search } });

    if (response) {
      dispatch(updateCompanies(response));
    } else {
      dispatch(setCompanyLoading(false));
    }
  };

export const loadCompany = (companyId: string, t: t) => async (dispatch: AppDispatch, getState: () => RootState) => {
  const state = getState();
  if (state.company.isLoading) {
    return;
  }

  dispatch(setCompanyLoading(true));

  const response = await fetch(Api.company_Get, t, { params: { companyId } });

  if (response) {
    dispatch(updateCompany(response));
  } else {
    dispatch(setCompanyLoading(false));
  }
};

export const loadAdminCompany =
  (companyId: string, t: t) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    if (state.company.isLoading) {
      return;
    }

    dispatch(setCompanyLoading(true));

    const response = await fetch(Api.admin_company_Get, t, { params: { companyId } });

    if (response) {
      dispatch(updateCompany(response));
    } else {
      dispatch(setCompanyLoading(false));
    }
  };

export const createAdminCompany =
  (companyData: CreateCompanyDTO, t: t) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    if (state.company.isLoading) {
      return;
    }

    dispatch(setSaveInProgress(true));

    const company = await fetch(Api.admin_company_Post, t, {
      body: companyData,
      defaultError: 'company.errors.create'
    });

    if (company) {
      dispatch(updateCompany(company));
      return true;
    } else {
      dispatch(setSaveInProgress(false));
      return false;
    }
  };

export const saveCompany = (company: CompanyDTO, t: t) => async (dispatch: AppDispatch, getState: () => RootState) => {
  const state = getState();
  if (state.company.isLoading) {
    return;
  }

  dispatch(setSaveInProgress(true));

  const updatedCompany = await fetch(Api.company_Put, t, {
    params: { companyId: company.id },
    body: company,
    defaultError: 'company.errors.update'
  });

  if (updatedCompany) {
    dispatch(updateCompany(updatedCompany));
    return true;
  } else {
    dispatch(setSaveInProgress(false));
    return false;
  }
};

export const saveAdminCompany =
  (company: CompanyDTO, t: t) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    if (state.company.isLoading) {
      return;
    }

    dispatch(setSaveInProgress(true));

    const updatedCompany = await fetch(Api.admin_company_Put, t, {
      params: { companyId: company.id },
      body: company,
      defaultError: 'company.errors.update'
    });

    if (updatedCompany) {
      dispatch(updateCompany(updatedCompany));
      return true;
    } else {
      dispatch(setSaveInProgress(false));
      return false;
    }
  };

export const saveAdminCompanyStatus =
  (company: CompanyDTO, t: t) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    if (state.company.isLoading) {
      return;
    }

    dispatch(setSaveInProgress(true));

    const updatedCompany = await fetch(Api.admin_company_StatusPut, t, {
      params: { companyId: company.id },
      body: company
    });

    if (updatedCompany) {
      dispatch(updateCompany(updatedCompany));
    } else {
      dispatch(setSaveInProgress(false));
    }
  };

export const resendCompanyAdminInvite =
  (companyId: string, t: t) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    if (state.company.isLoading) {
      return;
    }

    dispatch(setCompanyResendInProgress(true));

    const updatedCompany = await fetch(Api.admin_company_ResendInvitePut, t, {
      params: { companyId }
    });

    if (updatedCompany) {
      dispatch(updateCompanyInviteDate(updatedCompany));
    } else {
      dispatch(setCompanyResendInProgress(false));
    }
  };

export default companieslice.reducer;
