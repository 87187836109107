import { useEffect } from 'react';

import useTranslate from '@maya/hooks/useTranslate';
import { useAppDispatch, useAppSelector } from '@maya/store/hooks';
import { loadUsers, selectUsers } from '@maya/store/slices/user';

export default function useUsers(search = '') {
  const dispatch = useAppDispatch();
  const t = useTranslate();

  useEffect(() => {
    dispatch(loadUsers(search, t));
  }, [dispatch, search, t]);

  return useAppSelector(selectUsers);
}
