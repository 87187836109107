import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import Page from '@maya/common/Page';
import Loading from '@maya/common/loading/Loading';
import useQuery from '@maya/hooks/useQuery';
import useTranslate from '@maya/hooks/useTranslate';
import { RecordStatus } from '@maya/interface';
import useVisit from '@maya/patient/visits/hooks/useVisit';
import { useAppSelector } from '@maya/store/hooks';
import { selectIsOffice } from '@maya/store/slices/auth';
import { selectTaskListIsLoading } from '@maya/store/slices/task-list';
import TaskListForm, { headerTabValues, makeTabAnchorId } from './TaskListForm';
import useTaskList from './hooks/useTaskList';

import type { HeaderTabsConfig } from '@maya/common/Header';
import type { FC, SyntheticEvent } from 'react';
import type { HeaderTabValue } from './TaskListForm';

export interface Heading {
  id: string;
  title: string;
}

export interface NestedHeading extends Heading {
  items: Heading[];
}

const TaskList: FC = () => {
  const t = useTranslate();
  const { id, visitId } = useParams();
  const query = useQuery();

  const taskListLoading = useAppSelector(selectTaskListIsLoading);
  const isOffice = useAppSelector(selectIsOffice);

  const taskList = useTaskList(visitId, id);
  const visit = useVisit(visitId);

  const backTo = useMemo(() => query.get('backTo') ?? `/visit/${visitId}`, [query, visitId]);

  const [tab, setTab] = useState<HeaderTabValue>('information');

  const handleTabClick = useCallback((_event: SyntheticEvent, newValue: HeaderTabValue) => {
    const yOffset = -124;
    const element = document.getElementById(makeTabAnchorId(newValue));
    if (!element) {
      return;
    }

    const top = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top, behavior: 'smooth' });
    setTab(newValue);
  }, []);

  const handleTabChange = useCallback((newValue: HeaderTabValue) => {
    setTab(newValue);
  }, []);

  const headerTabsConfig: HeaderTabsConfig<HeaderTabValue> = useMemo(
    () => ({
      value: tab,
      onChange: handleTabClick,
      tabs: headerTabValues.map((value) => ({
        label: t(`taskList.tabs.${value}`),
        value
      }))
    }),
    [tab, handleTabClick, t]
  );

  return (
    <Page
      key="task-list-page"
      title={t('taskList.header')}
      color="paper"
      headerTabsConfig={headerTabsConfig}
      backTo={backTo}
    >
      {!taskList || taskListLoading || !id || !visit ? (
        <Loading />
      ) : (
        <TaskListForm
          key="task-list-form"
          id={id}
          visit={visit}
          taskList={taskList}
          readOnly={isOffice || taskList.status === RecordStatus.submitted}
          onTabChange={(newTab) => handleTabChange(newTab)}
        />
      )}
    </Page>
  );
};

export default TaskList;
