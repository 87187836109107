/**
 * Api operation to path mappings
 */
const enum Api {
  auth_Get = 'GET:/auth/authenticated',
  auth_LoginPost = 'POST:/auth/login',
  auth_LogoutDelete = 'DELETE:/auth/logout',
  auth_VerifyPost = 'POST:/auth/verify',
  auth_ResendPost = 'POST:/auth/resend',
  auth_ForgotPasswordPost = 'POST:/auth/forgot-password',
  auth_ResetPasswordPost = 'POST:/auth/reset-password',

  employees_Get = 'GET:/branch/{branchId}/employee',

  employee_Get = 'GET:/branch/{branchId}/employee/{employeeId}',
  employee_Post = 'POST:/branch/{branchId}/employee',
  employee_Put = 'PUT:/branch/{branchId}/employee/{employeeId}',
  employee_InviteResendPost = 'POST:/branch/{branchId}/employee/{employeeId}/invite/resend',

  patients_Get = 'GET:/branch/{branchId}/patient',

  patient_Get = 'GET:/branch/{branchId}/patient/{patientId}',
  patient_Post = 'POST:/branch/{branchId}/patient',
  patient_Put = 'PUT:/branch/{branchId}/patient/{patientId}',
  visits_Get = 'GET:/branch/{branchId}/patient/{patientId}/visit',
  visit_Get = 'GET:/branch/{branchId}/visit/{visitId}',
  visit_Delete = 'DELETE:/branch/{branchId}/visit/{visitId}',
  visit_Post = 'POST:/branch/{branchId}/visit',
  visit_Put = 'PUT:/branch/{branchId}/visit/{visitId}',
  visits_EmployeeSummaryGet = 'GET:/branch/{branchId}/visit/details/summary',
  visits_EmployeeGet = 'GET:/branch/{branchId}/employee/{employeeId}/visit',

  visitTypes_Get = 'GET:/branch/{branchId}/visit-type',

  visitType_Get = 'GET:/branch/{branchId}/visit-type/{visitTypeId}',
  visitType_Post = 'POST:/branch/{branchId}/visit-type',
  visitType_Put = 'PUT:/branch/{branchId}/visit-type/{visitTypeId}',

  admin_companies_Get = 'GET:/admin/company',

  admin_company_Get = 'GET:/admin/company/{companyId}',
  admin_company_Post = 'POST:/admin/company',
  admin_company_Put = 'PUT:/admin/company/{companyId}',
  admin_company_StatusPut = 'PUT:/admin/company/{companyId}/status',
  admin_company_ResendInvitePut = 'POST:/admin/company/{companyId}/invite/resend',

  admin_users_Get = 'GET:/admin/user',

  admin_user_Get = 'GET:/admin/user/{userId}',
  admin_user_Post = 'POST:/admin/user',
  admin_user_Put = 'PUT:/admin/user/{userId}',

  company_Get = 'GET:/company/{companyId}',
  company_Put = 'PUT:/company/{companyId}',

  static_FormTypesGet = 'GET:/static/form-type',
  static_DisciplinesGet = 'GET:/static/discipline',

  records_Get = 'GET:/branch/{branchId}/patient/{patientId}/records',

  routeSheet_Get = 'GET:/branch/{branchId}/visit/{visitId}/route-sheet/{routeSheetId}',
  routeSheet_Put = 'PUT:/branch/{branchId}/visit/{visitId}/route-sheet/{routeSheetId}',

  patientConsent_Get = 'GET:/branch/{branchId}/visit/{visitId}/patient-consent/{patientConsentId}',
  patientConsent_Put = 'PUT:/branch/{branchId}/visit/{visitId}/patient-consent/{patientConsentId}',

  payroll_Get = 'GET:/branch/{branchId}/payroll',
  payroll_EmployeeGet = 'GET:/branch/{branchId}/payroll/current',
  payroll_Put = 'PUT:/branch/{branchId}/payroll/visit/{visitId}',

  taskList_Get = 'GET:/branch/{branchId}/visit/{visitId}/task-list/{taskListId}',
  taskList_Put = 'PUT:/branch/{branchId}/visit/{visitId}/task-list/{taskListId}'
}

export default Api;
