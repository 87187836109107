import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import Page from '@maya/common/Page';
import Loading from '@maya/common/loading/Loading';
import { useAppSelector } from '@maya/store/hooks';
import { selectUserLoading } from '@maya/store/slices/user';
import UserForm from './UserForm';
import useUser from './hooks/useUser';

import type { FC } from 'react';

const UserView: FC = () => {
  const { id } = useParams();

  const user = useUser(id);

  const userLoading = useAppSelector(selectUserLoading);

  const title = useMemo(() => {
    if (!user || user?.id !== id) {
      return '';
    }

    return `${user.firstName} ${user.lastName}`;
  }, [user, id]);

  return (
    <Page title={title} backTo="/user/list" color="paper">
      {!user || userLoading ? <Loading /> : <UserForm key="editing-form" user={user} editMode />}
    </Page>
  );
};

export default UserView;
