import Box from '@mui/material/Box';
import { useState } from 'react';

import '@maya/common/FullCalendar.css';
import { useIsSmallScreen } from '@maya/hooks/useMediaQuery';
import useVisits from '@maya/patient/visits/hooks/useVisits';
import VisitsCalendar from './calendar/VisitsCalendar';

import type DateRangeISO from '@maya/common/date/DateRangeISO';
import type { PatientDTO } from '@maya/interface';
import type { FC } from 'react';

export interface VisitsProps {
  patient: PatientDTO;
}

const Visits: FC<VisitsProps> = ({ patient }) => {
  const [dateRangeISO, setDateRangeISO] = useState<DateRangeISO>();

  const patientId = patient.id;

  const visits = useVisits(patientId, dateRangeISO);

  const isSmallScreen = useIsSmallScreen();

  return (
    <Box sx={{ margin: isSmallScreen ? '-16px' : 0, height: '100%' }}>
      <VisitsCalendar patient={patient} visits={visits} onDateRangeChange={setDateRangeISO} />
    </Box>
  );
};

export default Visits;
