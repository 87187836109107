import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

import useTranslate from '@maya/hooks/useTranslate';
import { RecordStatus } from '@maya/interface';

import type { FormSummaryDTO } from '@maya/interface';
import type { FC } from 'react';

export interface RecordListItemProps {
  form: FormSummaryDTO;
}

const RecordListItem: FC<RecordListItemProps> = ({ form }) => {
  const t = useTranslate();
  const theme = useTheme();

  const statusColor = useMemo(() => {
    if (form.status === RecordStatus.saved) {
      return theme.palette.warning.main;
    }

    if (form.status === RecordStatus.submitted) {
      return theme.palette.success.main;
    }

    return theme.palette.text.secondary;
  }, [form.status, theme.palette.success.main, theme.palette.text.secondary, theme.palette.warning.main]);

  return (
    <ListItem key={form.id} disablePadding>
      <ListItemButton data-testid={`record-${form.id}`} component={Link} to={`${form.type}/${form.id}`}>
        <ListItemIcon>
          <ListAltIcon />
        </ListItemIcon>
        <ListItemText
          primary={t(`forms.${form.type}`)}
          secondary={t(`patients.records.status.${form.status}`)}
          secondaryTypographyProps={{ color: statusColor }}
        />
        <ListItemIcon sx={{ minWidth: 'unset' }}>
          <ChevronRightIcon />
        </ListItemIcon>
      </ListItemButton>
    </ListItem>
  );
};

export default RecordListItem;
