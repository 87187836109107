import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import dayjs from 'dayjs';
import { useCallback, useMemo, useState } from 'react';

import DateField from '@maya/common/date/DateField';
import Loading from '@maya/common/loading/Loading';
import Select from '@maya/common/select/Select';
import TextField from '@maya/common/textfield/TextField';
import EmployeeAutocomplete from '@maya/employee/EmployeeAutocomplete';
import useBranchId from '@maya/hooks/useBranchId';
import useFormValidationData from '@maya/hooks/useFormValidationData';
import useTranslate from '@maya/hooks/useTranslate';
import { useAppDispatch, useAppSelector } from '@maya/store/hooks';
import { createVisit, saveVisit, selectVisitSaveInProgress } from '@maya/store/slices/visit';
import useVisitTypes from '@maya/visit-type/hooks/useVisitTypes';

import type { Option } from '@maya/common/select/Select';
import type { CreateVisitDTO, VisitDTO } from '@maya/interface';
import type { FC } from 'react';

export interface VisitEditModalProps {
  patientId: string;
  visit: Partial<VisitDTO>;
  onClose: () => void;
}

// Splits scheduleDate into date and time.
interface EditVisitData extends Omit<Partial<CreateVisitDTO>, 'scheduledDate'> {
  date?: string;
  time?: number;
}

const fixupScheduleDate = (data: EditVisitData): CreateVisitDTO => {
  // Combine the date and the time of day values into scheduledDate.
  const { date, time, ...rest } = data;

  return {
    ...(rest as CreateVisitDTO),
    scheduledDate: dayjs(date)
      .startOf('day')
      .add(time ?? 0, 'minutes')
      .valueOf()
  };
};

const minutesOfDay = (date?: number) => (date ? (date - dayjs(date).startOf('day').valueOf()) / 60000 : undefined);

/**
 * Build time options.
 * Creates options for the hour and half hour.
 * The values are the number of minutes in the day.
 */
const timeOptions = [
  // offset is in minutes
  { label: 'AM', offset: 0 },
  { label: 'PM', offset: 720 }
].reduce((options, period) => {
  const periodOptions: Option<number>[] = [];
  for (let hour = 1; hour <= 12; hour += 1) {
    const hourStr = String(hour).padStart(2, '0');
    periodOptions.push({
      label: `${hourStr}:00 ${period.label}`,
      value: hour * 60 + period.offset
    });
    periodOptions.push({
      label: `${hourStr}:30 ${period.label}`,
      value: hour * 60 + period.offset + 30
    });
  }
  // shift the 12 o'clock times to the front.
  let option = periodOptions.pop() as Option<number>;
  option.value -= 720;
  periodOptions.unshift(option);
  option = periodOptions.pop() as Option<number>;
  option.value -= 720;
  periodOptions.unshift(option);
  return [...options, ...periodOptions];
}, [] as Option<number>[]);

const doNothing = () => undefined;

function transformDtoToFormData(visit: Partial<VisitDTO>): Partial<EditVisitData> {
  const { scheduledDate } = visit;

  return {
    visitTypeId: visit?.visitTypeId,
    employeeId: visit?.employee?.id,
    date: scheduledDate ? dayjs(scheduledDate).format('YYYY-MM-DD') : undefined,
    time: minutesOfDay(scheduledDate)
  };
}

const VisitEditModal: FC<VisitEditModalProps> = ({ patientId, visit, onClose }) => {
  const t = useTranslate();
  const [data, setData] = useState<EditVisitData>(transformDtoToFormData(visit));
  const [formIsValid, setFormValidationData] = useFormValidationData(visit?.id != null);
  const visitTypes = useVisitTypes({ currentVisitTypeId: visit.visitTypeId });

  const branchId = useBranchId();
  const dispatch = useAppDispatch();
  const saveInProgress = useAppSelector(selectVisitSaveInProgress);

  const updateData = useCallback(
    (newData: EditVisitData) => {
      setData((oldData) => ({
        ...oldData,
        ...newData
      }));
    },
    [setData]
  );

  const handleValidate = useCallback(
    (key: string) => (valid: boolean) => {
      setFormValidationData(key, valid);
    },
    [setFormValidationData]
  );

  const visitTypeOptions = useMemo(
    () =>
      visitTypes?.map((visitType) => ({
        label: visitType.name,
        value: visitType.id
      })),
    [visitTypes]
  );

  const handleSave = useCallback(() => {
    if (!formIsValid) {
      return;
    }

    const formData = fixupScheduleDate(data);

    formData.patientId = patientId;

    if (branchId) {
      if (visit.id) {
        dispatch(saveVisit(branchId, visit.id, formData, t));
      } else {
        dispatch(createVisit(branchId, formData, t));
      }
    }
    onClose();
  }, [formIsValid, data, patientId, branchId, onClose, visit.id, dispatch, t]);

  const currentVisitTypeId = data.visitTypeId ?? visit?.visitTypeId;
  const employeeSelectDisabled = currentVisitTypeId == null;

  return (
    <Dialog disableEscapeKeyDown={true} open={true} role="dialog" data-testid="patient-visit-edit-dialog">
      {!visitTypeOptions.length ? (
        <Loading />
      ) : (
        <>
          <Toolbar sx={{ justifyContent: 'space-between' }}>
            <DialogTitle role="heading" sx={{ paddingLeft: 0 }}>
              {visit.id ? t('patients.visit.modify') : t('patients.visit.new')}
            </DialogTitle>
            <IconButton onClick={onClose} data-testid="close-button">
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <Box
            component="form"
            autoComplete="off"
            sx={{ padding: '0 24px 24px 24px' }}
            data-testid="patient-overview-form"
          >
            <Stack spacing={2}>
              <Stack direction="row" spacing={3}>
                <DateField
                  data-testid="date"
                  required
                  label={t('app.form.date')}
                  defaultValue={data.date}
                  onChange={(newValue) => updateData({ date: newValue })}
                  onValidate={handleValidate('date')}
                />
                <Select
                  data-testid="time"
                  required
                  label={t('app.form.time')}
                  defaultValue={data.time}
                  options={timeOptions}
                  onChange={(newValue) => updateData({ time: newValue })}
                  onValidate={handleValidate('time')}
                />
              </Stack>
              <Stack direction="row" spacing={3}>
                <TextField
                  data-testid="patientName"
                  required
                  disabled={true}
                  label={t('app.form.patient')}
                  defaultValue={visit?.patient?.fullName}
                  onChange={doNothing}
                />
              </Stack>
              <Stack direction="row" spacing={3}>
                <Select
                  data-testid="visit-type-id"
                  required
                  label={t('app.form.visitType')}
                  defaultValue={visit?.visitTypeId}
                  options={visitTypeOptions}
                  onChange={(newValue) => updateData({ visitTypeId: newValue })}
                  onValidate={handleValidate('visitTypeId')}
                />
              </Stack>
              <Stack direction="row" spacing={3}>
                <EmployeeAutocomplete
                  required
                  label={t('app.form.employee')}
                  defaultValue={visit?.employee?.id}
                  onChange={(newValue) => updateData({ employeeId: newValue ?? undefined })}
                  onValidate={handleValidate('employeeId')}
                  visitTypeId={currentVisitTypeId}
                  disabled={employeeSelectDisabled}
                />
              </Stack>
              <Stack direction="row" spacing={3}>
                <TextField
                  data-testid="notes"
                  label={t('app.form.notes')}
                  defaultValue={visit?.notes}
                  onChange={(newValue) => updateData({ notes: newValue })}
                  onValidate={handleValidate('notes')}
                  multiline={true}
                  minRows={3}
                />
              </Stack>
              <Stack direction="row" spacing={3}>
                <Button
                  data-testid="save"
                  variant="contained"
                  onClick={handleSave}
                  disabled={!formIsValid || saveInProgress}
                >
                  {t('app.save')}
                </Button>
                <Button data-testid="cancel" onClick={onClose}>
                  {t('app.cancel')}
                </Button>
              </Stack>
            </Stack>
          </Box>
        </>
      )}
    </Dialog>
  );
};

export default VisitEditModal;
