import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import Page from '@maya/common/Page';
import Loading from '@maya/common/loading/Loading';
import useQuery from '@maya/hooks/useQuery';
import useTranslate from '@maya/hooks/useTranslate';
import { RecordStatus } from '@maya/interface';
import { useAppSelector } from '@maya/store/hooks';
import { selectIsOffice } from '@maya/store/slices/auth';
import { selectRouteSheetLoading } from '@maya/store/slices/route-sheet';
import RouteSheetForm from './RouteSheetForm';
import useRouteSheet from './hooks/useRouteSheet';

import type { FC } from 'react';

const RouteSheet: FC = () => {
  const t = useTranslate();
  const { id, visitId } = useParams();
  const query = useQuery();

  const routeSheetLoading = useAppSelector(selectRouteSheetLoading);
  const isOffice = useAppSelector(selectIsOffice);

  const routeSheet = useRouteSheet(visitId, id);

  const backTo = useMemo(() => query.get('backTo') ?? `/visit/${visitId}`, [query, visitId]);

  return (
    <Page title={t('routeSheet.header')} color="paper" backTo={backTo}>
      {!routeSheet || routeSheetLoading || !id || !visitId ? (
        <Loading />
      ) : (
        <RouteSheetForm
          id={id}
          visitId={visitId}
          routeSheet={routeSheet}
          readOnly={isOffice || routeSheet.status === RecordStatus.submitted}
        />
      )}
    </Page>
  );
};

export default RouteSheet;
