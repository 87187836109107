import { isDayjs } from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { isNotNullish, isNullish } from '@maya/util/null.util';
import { isEmpty } from '@maya/util/string.util';
import useTranslate from './useTranslate';

import type { Dayjs } from 'dayjs';
import type { ReactNode } from 'react';

export interface BaseUseValidateOptions {
  error?: boolean;
  pattern?: string;
  required?: boolean;
  helperText?: ReactNode;
  onValidate?: (valid: boolean) => void;
}

export interface UseValidateOptionsDate extends BaseUseValidateOptions {
  defaultValue: Dayjs | undefined | null;
}

export interface UseValidateOptionsNumber extends BaseUseValidateOptions {
  defaultValue: number | undefined | null;
}

export interface UseValidateOptionsBoolean extends BaseUseValidateOptions {
  defaultValue: boolean | undefined | null;
}

export interface UseValidateOptionsString extends BaseUseValidateOptions {
  defaultValue: string | undefined | null;
}

export interface UseValidateOptionsArray extends BaseUseValidateOptions {
  defaultValue: string[] | undefined | null;
  min?: number;
  max?: number;
}

export interface UseValidateOptionsSelect extends BaseUseValidateOptions {
  defaultValue: string | number | readonly string[] | undefined | null;
}

export type UseValidateOptions =
  | UseValidateOptionsDate
  | UseValidateOptionsNumber
  | UseValidateOptionsBoolean
  | UseValidateOptionsString
  | UseValidateOptionsArray
  | UseValidateOptionsSelect;

export interface UseValidateState {
  invalid: boolean;
  hint?: string;
  empty: boolean;
  dirty: boolean;
}

export interface BaseUseValidateResponse {
  hasError: boolean;
  hint: ReactNode;
}

export interface UseValidateResponseDate extends BaseUseValidateResponse {
  validate: (value: Dayjs | undefined | null) => void;
}

export interface UseValidateResponseNumber extends BaseUseValidateResponse {
  validate: (value: number | undefined | null) => void;
}

export interface UseValidateResponseBoolean extends BaseUseValidateResponse {
  validate: (value: boolean | undefined | null) => void;
}

export interface UseValidateResponseString extends BaseUseValidateResponse {
  validate: (value: string | undefined | null) => void;
}

export interface UseValidateResponseArray extends BaseUseValidateResponse {
  validate: (value: string[] | undefined | null) => void;
}

export interface UseValidateResponseSelect extends BaseUseValidateResponse {
  validate: (value: string | number | readonly string[] | undefined | null) => void;
}

export type UseValidateResponse =
  | UseValidateResponseDate
  | UseValidateResponseNumber
  | UseValidateResponseBoolean
  | UseValidateResponseString
  | UseValidateResponseArray
  | UseValidateResponseSelect;

export default function useValidate(options?: UseValidateOptionsDate): UseValidateResponseDate;
export default function useValidate(options?: UseValidateOptionsNumber): UseValidateResponseNumber;
export default function useValidate(options?: UseValidateOptionsBoolean): UseValidateResponseBoolean;
export default function useValidate(options?: UseValidateOptionsString): UseValidateResponseString;
export default function useValidate(options?: UseValidateOptionsArray): UseValidateResponseArray;
export default function useValidate(options?: UseValidateOptionsSelect): UseValidateResponseSelect;
export default function useValidate(options?: UseValidateOptions): UseValidateResponse {
  const { defaultValue, error, pattern, required, helperText, onValidate } = options ?? {};
  const t = useTranslate();

  const [{ invalid, hint: errorHint, empty, dirty }, setInvalid] = useState<UseValidateState>({
    invalid: false,
    empty: typeof defaultValue === 'string' ? isEmpty(defaultValue) : isNullish(defaultValue),
    dirty: false
  });

  const validate = useCallback(
    (value: Dayjs | string | readonly string[] | number | boolean | undefined | null, updateDirty = true) => {
      let valueEmpty = isNullish(value);
      if (typeof value === 'string' && isEmpty(value)) {
        valueEmpty = true;
      }

      if (Array.isArray(value) && value.length === 0) {
        valueEmpty = true;
      }

      const newDirty = updateDirty ? true : dirty;

      if (valueEmpty) {
        if (required) {
          setInvalid({ invalid: true, hint: t('app.errors.required'), empty: valueEmpty, dirty: newDirty });
          onValidate?.(false);
          return;
        }
      } else {
        if (isDayjs(value) && !value.isValid()) {
          setInvalid({ invalid: true, hint: t('app.errors.invalidFormat'), empty: valueEmpty, dirty: newDirty });
          onValidate?.(false);
          return;
        }

        if (Array.isArray(value)) {
          if (options) {
            if ('min' in options) {
              if (isNotNullish(options.min) && value.length < options.min) {
                setInvalid({
                  invalid: true,
                  hint: t('app.errors.min', { min: options.min }),
                  empty: valueEmpty,
                  dirty: newDirty
                });
                onValidate?.(false);
                return;
              }
            }

            if ('max' in options) {
              if (isNotNullish(options.max) && value.length > options.max) {
                setInvalid({
                  invalid: true,
                  hint: t('app.errors.max', { min: options.max }),
                  empty: valueEmpty,
                  dirty: newDirty
                });
                onValidate?.(false);
                return;
              }
            }
          }
          valueEmpty = true;
        }

        if (pattern) {
          if (!new RegExp(pattern).test(String(value ?? ''))) {
            setInvalid({
              invalid: true,
              hint: t('app.errors.invalidFormat'),
              empty: valueEmpty,
              dirty: newDirty
            });
            onValidate?.(false);
            return;
          }
        }
      }

      setInvalid({ invalid: false, empty: valueEmpty, dirty: newDirty });
      onValidate?.(true);
    },
    [dirty, onValidate, options, pattern, required, t]
  );

  useEffect(() => {
    validate(defaultValue, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [required]);

  const hint = useMemo(() => {
    if (invalid && (!empty || dirty)) {
      return errorHint ?? helperText;
    }

    return helperText;
  }, [dirty, empty, errorHint, helperText, invalid]);

  const hasError = useMemo(() => Boolean((invalid && (!empty || dirty)) || error), [dirty, empty, error, invalid]);

  return { validate, hasError, hint };
}
