import { useEffect } from 'react';

import useBranchId from '@maya/hooks/useBranchId';
import useTranslate from '@maya/hooks/useTranslate';
import { useAppDispatch, useAppSelector } from '@maya/store/hooks';
import { loadRouteSheet, selectRouteSheet } from '@maya/store/slices/route-sheet';

export default function useRouteSheet(visitId?: string, routeSheetId?: string) {
  const branchId = useBranchId();
  const t = useTranslate();

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!routeSheetId || !visitId || !branchId) {
      return;
    }

    dispatch(loadRouteSheet(branchId, visitId, routeSheetId, t));
  }, [branchId, dispatch, routeSheetId, visitId, t]);

  return useAppSelector(selectRouteSheet);
}
