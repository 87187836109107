import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { useCallback, useMemo, useState } from 'react';

import Empty from '@maya/common/Empty';
import FormattedDate from '@maya/common/FormattedDate';
import Page from '@maya/common/Page';
import Autocomplete from '@maya/common/auto-complete/Autocomplete';
import DateField from '@maya/common/date/DateField';
import Loading from '@maya/common/loading/Loading';
import TableCell from '@maya/common/table/TableCell';
import { PAGE_HEIGHT } from '@maya/constants';
import EmployeeAutocomplete from '@maya/employee/EmployeeAutocomplete';
import { useIsSmallScreen } from '@maya/hooks/useMediaQuery';
import useTranslate from '@maya/hooks/useTranslate';
import WageEditModal from '@maya/payroll/WageEditModal';
import useWages from '@maya/payroll/hooks/useWages';
import { useAppSelector } from '@maya/store/hooks';
import { selectPayrollLoading, selectTotalWages } from '@maya/store/slices/payroll';

import type { PaymentStatus, WageDTO } from '@maya/interface';
import type { FC } from 'react';

interface PayrollFilterProps {
  employeeId: string | null;
  dateBeforeISO?: string;
  status: PaymentStatus | null;
}

const paymentStatusOptions: PaymentStatus[] = ['not-paid', 'paid'];

const Payroll: FC = () => {
  const t = useTranslate();
  const isSmallScreen = useIsSmallScreen();

  const totalWages = useAppSelector(selectTotalWages);
  const payrollLoading = useAppSelector(selectPayrollLoading);

  const [data, setData] = useState<PayrollFilterProps>({
    employeeId: null,
    status: null
  });
  const wages = useWages(data.employeeId ?? undefined, data.dateBeforeISO, data.status ?? undefined);
  const [editWage, setEditWage] = useState<WageDTO | null>(null);

  const updateData = useCallback(
    (newData: Partial<PayrollFilterProps>) => {
      setData((oldData) => ({
        ...oldData,
        ...newData
      }));
    },
    [setData]
  );

  const getOptionLabel = useCallback(
    (status: PaymentStatus) => (status?.length ? t(`payroll.status.${status}`) : ''),
    [t]
  );

  const handleModalClose = useCallback(() => setEditWage(null), [setEditWage]);

  const direction = useMemo(() => (isSmallScreen ? 'column' : 'row'), [isSmallScreen]);

  return (
    <Page title={t('payroll.header')}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          height: `calc(100vh - ${PAGE_HEIGHT}px)`
        }}
      >
        <Card sx={{ height: '100%' }}>
          <CardContent sx={{ height: '100%', display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <Stack direction={direction} spacing={2}>
              <EmployeeAutocomplete
                label={t('app.form.employee')}
                value={data.employeeId}
                onChange={(newValue) => updateData({ employeeId: newValue ?? null })}
                helperText=""
              />
              <DateField
                data-testid="date"
                label={t('app.form.date')}
                defaultValue={data.dateBeforeISO}
                onChange={(newValue) => updateData({ dateBeforeISO: newValue })}
                helperText=""
              />
              <Autocomplete
                label={t('app.form.status')}
                options={paymentStatusOptions}
                getOptionLabel={getOptionLabel}
                value={data.status}
                onChange={(newValue) => updateData({ status: newValue ?? undefined })}
                data-testid="status"
                helperText=""
              />
            </Stack>
            {payrollLoading ? (
              <Loading />
            ) : wages.length ? (
              <>
                <TableContainer>
                  <Table aria-label="wages list" stickyHeader component="div">
                    <TableHead component="div">
                      <TableRow component="div">
                        <TableCell>{t('payroll.list.column.visitDate')}</TableCell>
                        <TableCell>{t('payroll.list.column.visitType')}</TableCell>
                        <TableCell>{t('payroll.list.column.employee')}</TableCell>
                        <TableCell>{t('payroll.list.column.patient')}</TableCell>
                        <TableCell>{t('payroll.list.column.amount')}</TableCell>
                        <TableCell>{t('payroll.list.column.status')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody component="div">
                      {wages.map((wage) => (
                        <TableRow
                          key={wage.id}
                          data-testid={wage.id}
                          sx={{ textDecoration: 'none', '&:last-child td, &:last-child th': { border: 0 } }}
                          hover
                          component="div"
                          onClick={() => setEditWage(wage)}
                        >
                          <TableCell>
                            <FormattedDate timestamp={wage.visitDate} />
                          </TableCell>
                          <TableCell>{wage.visitType}</TableCell>
                          <TableCell>{wage.employeeName}</TableCell>
                          <TableCell>{wage.patient}</TableCell>
                          <TableCell>
                            <Box sx={{ width: '70px', textAlign: 'right' }}>
                              {wage.amount.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD'
                              })}
                            </Box>
                          </TableCell>
                          <TableCell>{t(`payroll.status.${wage.status}`)}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Typography variant="body2" sx={{ textAlign: 'right' }} data-testid="total-rows">
                  {t('app.totalRows')} {totalWages}
                </Typography>
              </>
            ) : (
              <Empty key="empty" image="list">
                {t('payroll.list.empty')}
              </Empty>
            )}
          </CardContent>
        </Card>
        {editWage ? <WageEditModal wage={editWage} onClose={handleModalClose} /> : null}
      </Box>
    </Page>
  );
};

export default Payroll;
