import Box from '@mui/material/Box';
import { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Page from '@maya/common/Page';
import CompanyView from '@maya/company/CompanyView';
import { PAGE_WITH_TABS_HEIGHT } from '@maya/constants';
import useTranslate from '@maya/hooks/useTranslate';
import { useAppSelector } from '@maya/store/hooks';
import { selectIsCompanyAdmin } from '@maya/store/slices/auth';
import VisitTypeList from '@maya/visit-type/VisitTypeList';

import type { HeaderTabsConfig } from '@maya/common/Header';
import type { FC, ReactNode, SyntheticEvent } from 'react';

enum HeaderTabValue {
  VISIT_TYPE = 'visit-types',
  COMPANY = 'company'
}

const createActiveTabContent = (tabValue: HeaderTabValue, isCompanyAdmin: boolean): ReactNode => {
  switch (tabValue) {
    case HeaderTabValue.VISIT_TYPE:
      return <VisitTypeList />;
    case HeaderTabValue.COMPANY:
      return isCompanyAdmin ? <CompanyView /> : null;
  }
};

const toTabType = (tab: string | undefined, isCompanyAdmin: boolean): HeaderTabValue => {
  switch (tab) {
    case HeaderTabValue.VISIT_TYPE:
      return tab;
    case HeaderTabValue.COMPANY:
      if (isCompanyAdmin) {
        return tab;
      }
      break;
  }

  return HeaderTabValue.VISIT_TYPE;
};

const Settings: FC = () => {
  const t = useTranslate();
  const { tab } = useParams();
  const navigate = useNavigate();

  const isCompanyAdmin = useAppSelector(selectIsCompanyAdmin);

  const tabValue = useMemo(() => toTabType(tab, isCompanyAdmin), [isCompanyAdmin, tab]);

  const handleTabChange = useCallback(
    (_event: SyntheticEvent, newValue: HeaderTabValue) => {
      navigate(`/settings/${newValue}`);
    },
    [navigate]
  );

  const headerTabsConfig: HeaderTabsConfig<HeaderTabValue> = useMemo(
    () => ({
      value: tabValue,
      onChange: handleTabChange,
      tabs: [
        { label: t('settings.tabs.visitTypes'), value: HeaderTabValue.VISIT_TYPE },
        isCompanyAdmin && { label: t('settings.tabs.company'), value: HeaderTabValue.COMPANY }
      ]
    }),
    [tabValue, handleTabChange, t, isCompanyAdmin]
  );

  return (
    <Page title={t('settings.header')} headerTabsConfig={headerTabsConfig}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          height: `calc(100vh - ${PAGE_WITH_TABS_HEIGHT}px)`
        }}
      >
        {createActiveTabContent(tabValue, isCompanyAdmin)}
      </Box>
    </Page>
  );
};

export default Settings;
