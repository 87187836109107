import Box from '@mui/material/Box';
import { useMemo } from 'react';

import listImage from '@maya/assets/images/list.svg';
import tasksImage from '@maya/assets/images/tasks.svg';

import type { FC } from 'react';

export interface EmptyProps {
  image: 'tasks' | 'list';
  'data-testid'?: string;
  children: string;
}

const Empty: FC<EmptyProps> = ({ children, image, ...props }) => {
  const imageSrc = useMemo(() => {
    if (image === 'tasks') {
      return tasksImage;
    }

    if (image === 'list') {
      return listImage;
    }

    return null;
  }, [image]);

  return (
    <Box
      data-testid="empty"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '4px',
        height: '100%',
        flexGrow: 1
      }}
      {...props}
    >
      {imageSrc ? <Box component="img" src={imageSrc} /> : null}
      {children}
    </Box>
  );
};

export default Empty;
