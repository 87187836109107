import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import dayjs from 'dayjs';
import { useMemo } from 'react';

import useTranslate from '@maya/hooks/useTranslate';

import type { WageDTO } from '@maya/interface';
import type { FC } from 'react';

export interface VisitSummaryProps {
  wage: WageDTO;
}

const VisitPayrollSummary: FC<VisitSummaryProps> = ({ wage }) => {
  const t = useTranslate();
  const theme = useTheme();

  const [borderColor, backgroundColor, visitPatientTypeTextColor, statusTextColor] = useMemo(() => {
    if (wage.status === 'paid') {
      return [theme.palette.success.main, theme.palette.success.light];
    }

    return [theme.palette.divider, 'transparent', theme.palette.text.secondary, theme.palette.warning.main];
  }, [
    theme.palette.divider,
    theme.palette.success.light,
    theme.palette.success.main,
    theme.palette.text.secondary,
    theme.palette.warning.main,
    wage.status
  ]);

  return (
    <Box
      data-testid={`visit-payroll-summary-${wage.id}`}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: '12px',
        borderRadius: '4px',
        border: `1px solid ${borderColor}`,
        background: backgroundColor,
        textDecoration: 'none',
        textTransform: 'none',
        textAlign: 'start',
        color: 'unset'
      }}
    >
      <Typography
        variant="body2"
        component="div"
        sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
      >
        <Box>{dayjs(wage.visitDate).format('MM/DD/YYYY')}</Box>
        <Box sx={{ color: statusTextColor ?? borderColor }} data-testid="status">
          {t(`payroll.status.${wage.status}`)}
        </Box>
      </Typography>
      <Typography variant="h6" component="div" sx={{ width: '100%' }}>
        {wage.amount.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD'
        })}
      </Typography>
      <Typography
        variant="body2"
        component="div"
        color="text.secondary"
        sx={{ width: '100%', color: visitPatientTypeTextColor ?? borderColor }}
      >
        {wage.visitType} - {wage.patient}
      </Typography>
      {wage.notes ? (
        <Typography variant="body2" component="div" sx={{ width: '100%', marginTop: '8px' }}>
          {t('app.form.notes')}: {wage.notes}
        </Typography>
      ) : null}
    </Box>
  );
};

export default VisitPayrollSummary;
