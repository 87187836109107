import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

import { SIDEBAR_WIDTH } from '@maya/constants';

import type { ReactNode, SyntheticEvent } from 'react';

export interface HeaderTabConfig<T> {
  label: string;
  value: T;
}
export interface HeaderTabsConfig<T> {
  tabs: (HeaderTabConfig<T> | false | null)[];
  value: T;
  onChange: (event: SyntheticEvent, value: T) => void;
}

export interface HeaderProps<T> {
  title: ReactNode;
  tabsConfig?: HeaderTabsConfig<T>;
  onSidebarToggle: () => void;
}

const Header = <T extends string>({ title, tabsConfig, onSidebarToggle }: HeaderProps<T>) => {
  const theme = useTheme();

  return (
    <AppBar
      position="fixed"
      sx={{
        width: '100%',
        [theme.breakpoints.up('md')]: { width: `calc(100% - ${SIDEBAR_WIDTH}px)`, ml: `${SIDEBAR_WIDTH}px` }
      }}
    >
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={onSidebarToggle}
          sx={{
            mr: 2,
            [theme.breakpoints.up('md')]: {
              display: 'none'
            }
          }}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" noWrap component="div">
          {title}
        </Typography>
      </Toolbar>
      {tabsConfig ? (
        <Tabs
          value={tabsConfig.value}
          onChange={tabsConfig.onChange}
          textColor="inherit"
          data-testid="header-tabs"
          classes={{ scroller: 'maya-tabs' }}
        >
          {tabsConfig.tabs.map((tab) => {
            if (!tab) {
              return null;
            }

            const { label, value } = tab;

            return (
              <Tab
                key={value}
                label={label}
                value={value}
                data-testid={`header-tab-${value}`}
                sx={{
                  paddingLeft: '24px',
                  paddingRight: '24px'
                }}
              />
            );
          })}
        </Tabs>
      ) : null}
    </AppBar>
  );
};

export default Header;
