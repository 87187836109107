import { createSlice } from '@reduxjs/toolkit';

import Api from '@maya/api/api';
import fetch, { fetchAllList } from '@maya/api/fetch';

import type { CreateVisitTypeDTO, VisitTypeDTO, VisitTypesDTO } from '@maya/interface';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { t } from 'react-polyglot';
import type { AppDispatch, RootState } from '../index';

// Define a type for the slice state
export interface VisitTypeState {
  visitType?: VisitTypeDTO;
  visitTypes: VisitTypeDTO[];
  total: number;
  isLoading: boolean;
  saveInProgress: boolean;
}

// Define the initial state using that type
const initialState: VisitTypeState = {
  visitTypes: [],
  total: 0,
  isLoading: false,
  saveInProgress: false
};

export const visitTypeSlice = createSlice({
  name: 'visitType',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setVisitTypeLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    updateVisitTypes: (state, action: PayloadAction<VisitTypesDTO>) => {
      state.visitTypes = action.payload.data;
      state.total = action.payload.total;
      state.isLoading = false;
    },
    updateVisitType: (state, action: PayloadAction<VisitTypeDTO>) => {
      const visitType = action.payload;

      const index = state.visitTypes.findIndex((e) => e.id === visitType.id);
      if (index >= 0) {
        state.visitTypes[index] = visitType;
      }

      state.visitType = action.payload;
      state.isLoading = false;
      state.saveInProgress = false;
    },
    setSaveInProgress: (state, action: PayloadAction<boolean>) => {
      state.saveInProgress = action.payload;
    }
  }
});

export const { setVisitTypeLoading, updateVisitTypes, updateVisitType, setSaveInProgress } = visitTypeSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectVisitTypes = (state: RootState) => state.visitType.visitTypes;
export const selectTotalVisitTypes = (state: RootState) => state.visitType.total;

export const selectVisitType = (state: RootState) => state.visitType.visitType;
export const selectVisitTypeLoading = (state: RootState) => state.visitType.isLoading;
export const selectVisitTypeSaveInProgress = (state: RootState) => state.visitType.saveInProgress;

export interface VisitTypeSearch {
  branchId: string;
  search?: string;
  visitTypeId?: string;
}

export const loadVisitTypes = (branchId: string, t: t) => async (dispatch: AppDispatch, getState: () => RootState) => {
  const state = getState();
  if (state.visitType.isLoading) {
    return;
  }

  dispatch(setVisitTypeLoading(true));

  const response = await fetchAllList(Api.visitTypes_Get, t, { params: { branchId } });

  if (response) {
    dispatch(updateVisitTypes(response));
  } else {
    dispatch(setVisitTypeLoading(false));
  }
};

export const loadVisitType =
  (branchId: string, visitTypeId: string, t: t) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    if (state.visitType.isLoading) {
      return;
    }

    dispatch(setVisitTypeLoading(true));

    const response = await fetch(Api.visitType_Get, t, { params: { branchId, visitTypeId } });

    if (response) {
      dispatch(updateVisitType(response));
    } else {
      dispatch(setVisitTypeLoading(false));
    }
  };

export const createVisitType =
  (branchId: string, data: CreateVisitTypeDTO, t: t) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    if (state.visitType.isLoading) {
      return;
    }

    dispatch(setSaveInProgress(true));

    const visitType = await fetch(Api.visitType_Post, t, {
      params: { branchId },
      body: data,
      defaultError: 'visitTypes.errors.create'
    });

    if (visitType) {
      dispatch(updateVisitType(visitType));
      return true;
    } else {
      dispatch(setSaveInProgress(false));
      return false;
    }
  };

export const saveVisitType =
  (branchId: string, visitType: VisitTypeDTO, t: t) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    if (state.visitType.isLoading) {
      return;
    }

    dispatch(setSaveInProgress(true));

    const updatedVisitType = await fetch(Api.visitType_Put, t, {
      params: { branchId, visitTypeId: visitType.id },
      body: visitType,
      defaultError: 'visitTypes.errors.update'
    });

    if (updatedVisitType) {
      dispatch(updateVisitType(updatedVisitType));
      return true;
    } else {
      dispatch(setSaveInProgress(false));
      return false;
    }
  };

export default visitTypeSlice.reducer;
