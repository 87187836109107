// Define a type for the slice state
import { createSlice } from '@reduxjs/toolkit';

import Api from '@maya/api/api';
import { fetchAllList } from '@maya/api/fetch';

import type { RecordDTO, RecordsDTO } from '@maya/interface';
import type { AppDispatch, RootState } from '@maya/store';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { t } from 'react-polyglot';

export interface RecordState {
  records: RecordDTO[];
  record?: RecordDTO;
  total: number;
  isLoading: boolean;
}

// Define the initial state using that type
const initialState: RecordState = {
  records: [],
  total: 0,
  isLoading: false
};

export const recordSlice = createSlice({
  name: 'record',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setRecordsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    updateRecords: (state, action: PayloadAction<RecordsDTO>) => {
      state.records = action.payload.data;
      state.total = action.payload.total;
      state.isLoading = false;
    },
    updateRecord: (state, action: PayloadAction<RecordDTO>) => {
      const record = action.payload;

      const index = state.records.findIndex((e) => e.id === record.id);
      if (index >= 0) {
        state.records[index] = record;
      } else {
        state.records.push(record);
      }

      state.isLoading = false;
    }
  }
});

export const { updateRecords, updateRecord, setRecordsLoading } = recordSlice.actions;

export const selectRecords = (state: RootState) => state.record.records;
export const selectRecordsTotal = (state: RootState) => state.record.total;
export const selectRecord = (state: RootState) => state.record.record;

export const loadRecords =
  (branchId: string, patientId: string, t: t) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    if (state.record.isLoading || !branchId || !patientId) {
      return;
    }

    dispatch(setRecordsLoading(true));

    const response = await fetchAllList(Api.records_Get, t, {
      params: { branchId, patientId }
    });

    if (response) {
      dispatch(updateRecords(response));
    } else {
      dispatch(setRecordsLoading(false));
    }
  };

export default recordSlice.reducer;
