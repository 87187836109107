import type { ThemeOptions } from '@mui/material/styles';

const theme: ThemeOptions = {
  palette: {
    background: {
      default: '#fafafa'
    },
    primary: {
      main: '#673ab7'
    },
    warning: {
      main: '#c77700',
      light: '#fff5e5'
    },
    error: {
      main: '#e31b0c',
      light: '#feeceb'
    },
    success: {
      main: '#3b873e',
      light: '#edf7ed'
    }
  },
  typography: {
    subtitle1: {
      fontSize: '1.17rem',
      fontWeight: 'bold'
    }
  },
  components: {
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: '#ffffff'
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none'
        }
      }
    }
  }
};

export default theme;
