import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useCallback, useMemo, useState } from 'react';

import Select from '@maya/common/select/Select';
import PhoneNumberField from '@maya/common/textfield/PhoneNumberField';
import TextField from '@maya/common/textfield/TextField';
import STATES from '@maya/constants/states';
import useFormValidationData from '@maya/hooks/useFormValidationData';
import { useIsSmallScreen } from '@maya/hooks/useMediaQuery';
import useTranslate from '@maya/hooks/useTranslate';
import { useAppDispatch, useAppSelector } from '@maya/store/hooks';
import {
  createAdminCompany,
  saveAdminCompany,
  saveCompany,
  selectCompanieSaveInProgress
} from '@maya/store/slices/company';

import type { CompanyDTO, CompanyWithUserDTO, CreateCompanyDTO } from '@maya/interface';
import type { FC } from 'react';

export interface CompanyFormProps {
  company?: CompanyDTO | CompanyWithUserDTO;
  onClose: () => void;
}

const CompanyForm: FC<CompanyFormProps> = ({ company, onClose }) => {
  const t = useTranslate();
  const isSmallScreen = useIsSmallScreen();

  const [data, setData] = useState<Partial<CreateCompanyDTO>>({ ...(company ?? {}) });
  const [formIsValid, setFormValidationData] = useFormValidationData(company != null);

  const saveInProgress = useAppSelector(selectCompanieSaveInProgress);
  const disabled = saveInProgress;

  const updateData = useCallback((newData: Partial<CompanyDTO | CompanyWithUserDTO>) => {
    setData((oldData) => ({
      ...oldData,
      ...newData
    }));
  }, []);

  const dispatch = useAppDispatch();

  const handleValidate = useCallback(
    (key: string) => (valid: boolean) => {
      setFormValidationData(key, valid);
    },
    [setFormValidationData]
  );

  const handleSave = useCallback(async () => {
    if (!formIsValid) {
      return;
    }

    if (company) {
      if ('username' in company) {
        if (!(await dispatch(saveAdminCompany({ ...company, ...data }, t)))) {
          return;
        }
      } else {
        if (!(await dispatch(saveCompany({ ...company, ...data }, t)))) {
          return;
        }
      }
    } else {
      if (!(await dispatch(createAdminCompany(data as CreateCompanyDTO, t)))) {
        return;
      }
    }
    onClose();
  }, [formIsValid, company, onClose, dispatch, data, t]);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const direction = useMemo(() => (isSmallScreen ? 'column' : 'row'), [isSmallScreen]);

  return (
    <Box component="form" autoComplete="off" sx={{ paddingBottom: '16px', maxWidth: '750px' }}>
      <Stack spacing={2}>
        <Typography variant="h6">{t('company.view.header')}</Typography>
        <Stack direction={direction} spacing={3} sx={{ width: '100%' }}>
          <TextField
            data-testid="name"
            required
            label={t('company.form.name')}
            defaultValue={company?.name}
            onChange={(newValue) => updateData({ name: newValue })}
            onValidate={handleValidate('name')}
            disabled={disabled}
          />
        </Stack>
        <Stack direction={direction} spacing={3} sx={{ width: '100%' }}>
          <TextField
            data-testid="doingBusinessAs"
            label={t('company.form.doingBusinessAs')}
            defaultValue={company?.doingBusinessAs}
            onChange={(newValue) => updateData({ doingBusinessAs: newValue })}
            onValidate={handleValidate('doingBusinessAs')}
            disabled={disabled}
          />
        </Stack>
        <Stack direction={direction} spacing={3} sx={{ width: '100%' }}>
          <TextField
            data-testid="street"
            required
            label={t('app.form.address')}
            defaultValue={company?.street}
            onChange={(newValue) => updateData({ street: newValue })}
            onValidate={handleValidate('street')}
            disabled={disabled}
          />
        </Stack>
        <Stack direction={direction} spacing={3}>
          <TextField
            data-testid="city"
            required
            label={t('app.form.city')}
            defaultValue={company?.city}
            onChange={(newValue) => updateData({ city: newValue })}
            onValidate={handleValidate('city')}
            disabled={disabled}
          />
          <Select
            data-testid="state"
            required
            label={t('app.form.state')}
            defaultValue={company?.state}
            options={STATES}
            onChange={(newValue) => updateData({ state: newValue })}
            onValidate={handleValidate('state')}
            disabled={disabled}
          />
          <TextField
            data-testid="zip"
            required
            label={t('app.form.zip')}
            defaultValue={company?.zip}
            placeholder="#####"
            pattern="^[0-9]{5}(-[0-9]{4})?$"
            onChange={(newValue) => updateData({ zip: newValue })}
            onValidate={handleValidate('zip')}
            disabled={disabled}
          />
        </Stack>
        <Stack direction={direction} spacing={3}>
          <PhoneNumberField
            data-testid="phone"
            required
            label={t('app.form.phone')}
            defaultValue={company?.phone}
            onChange={(newValue) => updateData({ phone: newValue })}
            onValidate={handleValidate('phone')}
            disabled={disabled}
          />
          <PhoneNumberField
            data-testid="fax"
            label={t('company.form.fax')}
            defaultValue={company?.fax}
            onChange={(newValue) => updateData({ fax: newValue })}
            onValidate={handleValidate('fax')}
            disabled={disabled}
          />
        </Stack>
        <Typography variant="h6">{t('company.view.idNumbers')}</Typography>
        <Stack direction={direction} spacing={3}>
          <TextField
            data-testid="federalTaxId"
            required
            label={t('company.form.federalTaxId')}
            defaultValue={company?.taxId}
            onChange={(newValue) => updateData({ taxId: newValue })}
            onValidate={handleValidate('federalTaxId')}
            disabled={disabled}
          />
          <TextField
            data-testid="medicareId"
            required
            label={t('company.form.medicareId')}
            defaultValue={company?.medicareId}
            onChange={(newValue) => updateData({ medicareId: newValue })}
            onValidate={handleValidate('medicareId')}
            disabled={disabled}
          />
        </Stack>
        <Stack direction={direction} spacing={3}>
          <TextField
            data-testid="stateAgencyId"
            label={t('company.form.stateAgencyId')}
            defaultValue={company?.stateAgencyId}
            onChange={(newValue) => updateData({ stateAgencyId: newValue })}
            onValidate={handleValidate('stateAgencyId')}
            disabled={disabled}
          />
          <TextField
            data-testid="npiNumber"
            required
            label={t('company.form.npiNumber')}
            defaultValue={company?.npiNumber}
            onChange={(newValue) => updateData({ npiNumber: newValue })}
            onValidate={handleValidate('npiNumber')}
            disabled={disabled}
          />
        </Stack>
        {!company || 'username' in company ? (
          <>
            <Typography variant="h6">{t('company.view.administrator')}</Typography>
            <Stack direction={direction} spacing={3}>
              <TextField
                data-testid="firstName"
                required
                label={t('app.form.firstName')}
                defaultValue={company?.firstName}
                onChange={(newValue) => updateData({ firstName: newValue })}
                onValidate={handleValidate('firstName')}
                disabled={disabled}
              />
              <TextField
                data-testid="lastName"
                required
                label={t('app.form.lastName')}
                defaultValue={company?.lastName}
                onChange={(newValue) => updateData({ lastName: newValue })}
                onValidate={handleValidate('lastName')}
                disabled={disabled}
              />
            </Stack>
            <Stack direction={direction} spacing={3}>
              <TextField
                data-testid="username"
                required
                label={t('app.form.username')}
                defaultValue={company?.username}
                onChange={(newValue) => updateData({ username: newValue })}
                onValidate={handleValidate('username')}
                disabled={disabled}
              />
              <TextField
                data-testid="email"
                required
                label={t('app.form.email')}
                defaultValue={company?.email}
                pattern="^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$"
                onChange={(newValue) => updateData({ email: newValue })}
                onValidate={handleValidate('email')}
                disabled={disabled}
              />
            </Stack>
          </>
        ) : null}
        <Stack direction="row" spacing={3}>
          <Button data-testid="save" variant="contained" onClick={handleSave} disabled={!formIsValid || saveInProgress}>
            {t('app.save')}
          </Button>
          <Button data-testid="cancel" onClick={handleClose}>
            {t('app.cancel')}
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default CompanyForm;
