import { useEffect } from 'react';

import useTranslate from '@maya/hooks/useTranslate';
import { useAppDispatch, useAppSelector } from '@maya/store/hooks';
import { loadUser, selectUser } from '@maya/store/slices/user';

export default function useUser(userId?: string) {
  const dispatch = useAppDispatch();
  const t = useTranslate();

  useEffect(() => {
    if (!userId) {
      return;
    }

    dispatch(loadUser(userId, t));
  }, [dispatch, t, userId]);

  return useAppSelector(selectUser);
}
