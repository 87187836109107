import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Signature from '@maya/common/signature/Signature';
import NumberField from '@maya/common/textfield/NumberField';
import TimeField from '@maya/common/time/TimeField';
import useBranchId from '@maya/hooks/useBranchId';
import useTranslate from '@maya/hooks/useTranslate';
import { RecordStatus } from '@maya/interface';
import { useAppDispatch, useAppSelector } from '@maya/store/hooks';
import { saveRouteSheet, selectRouteSheetSaveInProgress } from '@maya/store/slices/route-sheet';
import { isNotEmpty } from '@maya/util/string.util';

import type { RouteSheetDTO, UpdateRouteSheetDTO } from '@maya/interface';
import type { FC } from 'react';

export interface RouteSheetFormProps {
  visitId: string;
  id: string;
  routeSheet: RouteSheetDTO;
  readOnly?: boolean;
}

type Form = Omit<UpdateRouteSheetDTO, 'status'>;

const RouteSheetForm: FC<RouteSheetFormProps> = ({ visitId, id, routeSheet, readOnly = false }) => {
  const t = useTranslate();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const branchId = useBranchId();
  const saving = useAppSelector(selectRouteSheetSaveInProgress);

  const disabled = saving;

  const [form, setForm] = useState<Form>({
    mileage: routeSheet.mileage ?? 0,
    patientSignature: routeSheet.patientSignature ?? '',
    employeeSignature: routeSheet.employeeSignature ?? '',
    startTime: routeSheet.startTime ?? '',
    endTime: routeSheet.endTime ?? ''
  });

  const { mileage, patientSignature, employeeSignature, startTime, endTime } = form;

  const updateForm = useCallback((data: Partial<Form>) => {
    setForm((old) => ({ ...old, ...data }));
  }, []);

  const valid = useMemo(
    () =>
      isNotEmpty(patientSignature) &&
      isNotEmpty(employeeSignature) &&
      isNotEmpty(startTime) &&
      isNotEmpty(endTime) &&
      (!routeSheet.requireMileage || (mileage && mileage > 0)),
    [employeeSignature, endTime, mileage, patientSignature, routeSheet.requireMileage, startTime]
  );

  const saveForm = useCallback(
    async (status: RecordStatus) => {
      if (!branchId || (!valid && status === 'submitted')) {
        return;
      }

      await dispatch(saveRouteSheet(branchId, visitId, id, { ...form, status }, t));
      navigate(`/visit/${visitId}`);
    },
    [branchId, dispatch, form, id, navigate, t, valid, visitId]
  );

  const handleSubmit = useCallback(() => {
    saveForm(RecordStatus.submitted);
  }, [saveForm]);

  const handleSave = useCallback(() => {
    saveForm(RecordStatus.saved);
  }, [saveForm]);

  return (
    <>
      <Typography component="h3" variant="subtitle1" sx={{ marginBottom: '16px' }}>
        {t('routeSheet.visit')}
      </Typography>
      <Box sx={{ display: 'flex', gap: '16px' }}>
        <TimeField
          label={t('routeSheet.timeIn')}
          defaultValue={startTime}
          onChange={(startTime) => updateForm({ startTime: startTime ?? '' })}
          disabled={disabled}
          readOnly={readOnly}
          fullWidth={false}
          data-testid="timeIn"
          required
        />
        <TimeField
          label={t('routeSheet.timeOut')}
          defaultValue={endTime}
          onChange={(endTime) => updateForm({ endTime: endTime ?? '' })}
          disabled={disabled}
          readOnly={readOnly}
          fullWidth={false}
          data-testid="timeOut"
          required
        />
        {routeSheet.requireMileage ? (
          <NumberField
            key="mileage"
            label={t('routeSheet.mileage')}
            defaultValue={mileage}
            onChange={(mileage) => updateForm({ mileage: mileage ?? 0 })}
            disabled={disabled}
            readOnly={readOnly}
            fullWidth={false}
            min={0}
            data-testid="mileage"
            required
          />
        ) : null}
      </Box>
      <Divider sx={{ marginTop: '16px' }} />
      <Typography component="h3" variant="subtitle1" sx={{ margin: '16px 0' }}>
        {t('routeSheet.acknowledgementOfVisit.header')}
      </Typography>
      <div>{t('routeSheet.acknowledgementOfVisit.statement')}</div>
      <ol type="A">
        <li>{t('routeSheet.acknowledgementOfVisit.point1')}</li>
        <li>{t('routeSheet.acknowledgementOfVisit.point2')}</li>
        <li>{t('routeSheet.acknowledgementOfVisit.point3')}</li>
        <li>{t('routeSheet.acknowledgementOfVisit.point4')}</li>
        <li>{t('routeSheet.acknowledgementOfVisit.point5')}</li>
      </ol>
      <Signature
        data-testid="patientSignature"
        initialUrl={patientSignature}
        onValue={(patientSignature) => updateForm({ patientSignature })}
        required
        readOnly={readOnly || disabled}
      />
      <Divider sx={{ marginTop: '16px' }} />
      <Typography component="h3" variant="subtitle1" sx={{ margin: '16px 0' }}>
        {t('routeSheet.certificationOfVisit.header')}
      </Typography>
      <div>{t('routeSheet.certificationOfVisit.statement')}</div>
      <ol type="A">
        <li>{t('routeSheet.certificationOfVisit.point1')}</li>
        <li>{t('routeSheet.certificationOfVisit.point2')}</li>
        <li>{t('routeSheet.certificationOfVisit.point3')}</li>
        <li>{t('routeSheet.certificationOfVisit.point4')}</li>
        <li>{t('routeSheet.certificationOfVisit.point5')}</li>
        <li>{t('routeSheet.certificationOfVisit.point6')}</li>
        <li>{t('routeSheet.certificationOfVisit.point7')}</li>
        <li>{t('routeSheet.certificationOfVisit.point8')}</li>
        <li>{t('routeSheet.certificationOfVisit.point9')}</li>
        <li>{t('routeSheet.certificationOfVisit.point10')}</li>
      </ol>
      <Box sx={{ margin: '16px 0' }}>{t('routeSheet.certificationOfVisit.statement2')}</Box>
      <Box sx={{ margin: '16px 0' }}>{t('routeSheet.certificationOfVisit.statement3')}</Box>
      <Signature
        data-testid="employeeSignature"
        initialUrl={employeeSignature}
        onValue={(employeeSignature) => updateForm({ employeeSignature })}
        required
        readOnly={readOnly || disabled}
      />
      {!readOnly ? (
        <>
          <Divider sx={{ marginTop: '16px' }} />
          <Box sx={{ display: 'flex', gap: '8px', marginTop: '16px' }}>
            <Button data-testid="submit" variant="contained" disabled={!valid || saving} onClick={handleSubmit}>
              {t('app.submit')}
            </Button>
            <Button data-testid="save" variant="text" disabled={saving} onClick={handleSave}>
              {t('app.save')}
            </Button>
          </Box>
        </>
      ) : null}
    </>
  );
};

export default RouteSheetForm;
