import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EditIcon from '@mui/icons-material/Edit';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import PersonIcon from '@mui/icons-material/Person';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import Page from '@maya/common/Page';
import Loading from '@maya/common/loading/Loading';
import useBranchId from '@maya/hooks/useBranchId';
import useTranslate from '@maya/hooks/useTranslate';
import { useAppDispatch, useAppSelector } from '@maya/store/hooks';
import {
  resendEmployeeInvite,
  selectEmployeeLoading,
  selectEmployeeResendInviteInProgress
} from '@maya/store/slices/employee';
import EmployeeForm from './EmployeeForm';
import useEmployee from './hooks/useEmployee';

import type { FC } from 'react';

const EmployeeView: FC = () => {
  const t = useTranslate();
  const { id } = useParams();

  const employee = useEmployee(id);

  const employeeLoading = useAppSelector(selectEmployeeLoading);

  const title = useMemo(() => {
    if (employee?.id !== id) {
      return '';
    }

    return employee?.fullName ?? '';
  }, [employee?.fullName, employee?.id, id]);

  const [editing, setEditing] = useState(false);
  const startEditing = useCallback(() => {
    setEditing(true);
  }, []);
  const endEditing = useCallback(() => {
    setEditing(false);
  }, []);

  const dispatch = useAppDispatch();
  const resendInProgress = useAppSelector(selectEmployeeResendInviteInProgress);

  const branchId = useBranchId();
  const resendInvite = useCallback(() => {
    if (!branchId || !employee) {
      return;
    }

    dispatch(resendEmployeeInvite(branchId, employee, t));
  }, [branchId, dispatch, employee, t]);

  return (
    <Page title={title} backTo="/employee/list" color={editing ? 'paper' : 'default'}>
      {!employee || employeeLoading ? (
        <Loading />
      ) : editing ? (
        <EmployeeForm key="editing-form" employee={employee} onClose={endEditing} />
      ) : (
        <Card key="details-card">
          <CardContent sx={{ position: 'relative', paddingTop: 0 }}>
            <IconButton
              data-testid="edit"
              sx={{ position: 'absolute', top: '16px', right: '16px', zIndex: 1 }}
              onClick={startEditing}
            >
              <EditIcon />
            </IconButton>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
              <ListItem>
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText
                  data-testid="name-dob-and-gender"
                  primary={employee.fullName}
                  secondary={`${dayjs(employee.dob).format('MM/DD/YYYY')} - ${t(`app.gender.${employee.gender}`)}`}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <LocalPhoneIcon />
                </ListItemIcon>
                <ListItemText data-testid="phone" primary={employee.phone} />
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton component={Link} href={`mailto:${employee.email}`}>
                  <ListItemIcon>
                    <EmailIcon />
                  </ListItemIcon>
                  <ListItemText
                    data-testid="email"
                    primaryTypographyProps={{ color: 'primary' }}
                    primary={employee.email}
                  />
                </ListItemButton>
              </ListItem>
            </List>
            <List
              sx={{ width: '100%', bgcolor: 'background.paper' }}
              subheader={
                <Typography variant="subtitle1" component="div" sx={{ marginLeft: '16px', fontWeight: 'bold' }}>
                  {t('employees.view.account')}
                </Typography>
              }
            >
              <ListItem>
                <ListItemIcon>
                  <AccountCircleIcon />
                </ListItemIcon>
                <ListItemText
                  data-testid="username-and-invite"
                  primary={employee.username}
                  secondaryTypographyProps={{ component: 'div' }}
                  secondary={
                    employee.verified ? (
                      `${t('app.inviteAcceptedOn')} ${dayjs(employee.inviteAcceptedDate).format(
                        'MMMM DD, YYYY hh:mm A'
                      )}`
                    ) : (
                      <Box sx={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
                        {`${t('app.invited')} ${dayjs(employee.inviteDate).format('MMMM DD, YYYY hh:mm A')}`}
                        <Button
                          data-testid="resend-invite"
                          size="small"
                          disabled={resendInProgress}
                          onClick={resendInvite}
                        >
                          {t('app.resend')}
                        </Button>
                      </Box>
                    )
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <LockOpenIcon />
                </ListItemIcon>
                <ListItemText data-testid="role" primary={t(`employees.roles.${employee.role}`)} />
              </ListItem>
            </List>
          </CardContent>
        </Card>
      )}
    </Page>
  );
};

export default EmployeeView;
