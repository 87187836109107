import { useEffect } from 'react';

import useBranchId from '@maya/hooks/useBranchId';
import useTranslate from '@maya/hooks/useTranslate';
import { useAppDispatch, useAppSelector } from '@maya/store/hooks';
import { loadPatientConsent, selectPatientConsent } from '@maya/store/slices/patient-consent';

export default function usePatientConsent(visitId?: string, patientConsentId?: string) {
  const branchId = useBranchId();
  const t = useTranslate();

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!patientConsentId || !visitId || !branchId) {
      return;
    }

    dispatch(loadPatientConsent(branchId, visitId, patientConsentId, t));
  }, [branchId, dispatch, patientConsentId, t, visitId]);

  return useAppSelector(selectPatientConsent);
}
