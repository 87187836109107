import MuiCheckbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useCallback, useEffect, useState } from 'react';

import type { CheckboxProps as MuiCheckboxProps } from '@mui/material/Checkbox';
import type { ChangeEvent, ChangeEventHandler, FC, ReactNode } from 'react';

export interface CheckboxProps extends Omit<MuiCheckboxProps, 'onChange' | 'required' | 'value' | 'defaultValue'> {
  label: ReactNode;
  onChange: (newValue: boolean, event: ChangeEvent<HTMLInputElement>) => void;
}

const Checkbox: FC<CheckboxProps> = ({ label, defaultChecked, checked, onChange, disabled, readOnly, ...props }) => {
  const [internalChecked, setInternalChecked] = useState(defaultChecked ?? checked ?? false);

  useEffect(() => {
    if (typeof checked === 'boolean') {
      setInternalChecked(checked);
    }
  }, [checked]);

  const handleOnChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      if (readOnly) {
        return;
      }
      onChange(event.target.checked, event);
      setInternalChecked(event.target.checked);
    },
    [onChange, readOnly]
  );

  return (
    <FormControlLabel
      control={
        <MuiCheckbox
          data-testid={`checkbox-${label}`}
          checked={internalChecked}
          onChange={handleOnChange}
          disabled={disabled}
          {...props}
        />
      }
      label={label}
    />
  );
};

export default Checkbox;
