import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  HEADER_HEIGHT,
  HEADER_WITH_TABS_HEIGHT,
  MOBILE_HEADER_HEIGHT,
  MOBILE_HEADER_WITH_TABS_HEIGHT
} from '@maya/constants';
import { useIsSmallScreen } from '@maya/hooks/useMediaQuery';
import Header from './Header';
import Sidebar from './Sidebar';

import type { PropsWithChildren, ReactNode } from 'react';
import type { HeaderTabsConfig } from './Header';

export interface PageProps<T> extends PropsWithChildren {
  color?: 'default' | 'paper';
  title: ReactNode;
  backTo?: string;
  headerTabsConfig?: HeaderTabsConfig<T>;
  noPadding?: boolean;
}

const Page = <T extends string>({
  color = 'default',
  title,
  backTo,
  headerTabsConfig,
  noPadding = false,
  children
}: PageProps<T>) => {
  const theme = useTheme();

  const isSmallScreen = useIsSmallScreen();

  const header = useMemo(
    () =>
      backTo ? (
        <Box
          key="title-with-backTo"
          sx={{ display: 'flex', gap: '16px', alignItems: 'center', justifyContent: 'center' }}
        >
          <IconButton component={Link} to={backTo} color="inherit">
            <ArrowBackIcon />
          </IconButton>
          <Box>{title}</Box>
        </Box>
      ) : (
        title
      ),
    [backTo, title]
  );

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const handleSidebarToggle = useCallback(() => {
    setSidebarOpen((old) => !old);
  }, []);
  const handleSidebarOpen = useCallback(() => {
    setSidebarOpen(true);
  }, []);
  const handleSidebarClose = useCallback(() => {
    setSidebarOpen(false);
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        background: color === 'paper' ? theme.palette.background.paper : theme.palette.background.default
      }}
    >
      <Sidebar open={sidebarOpen} onOpen={handleSidebarOpen} onClose={handleSidebarClose} />
      <Header title={header} tabsConfig={headerTabsConfig} onSidebarToggle={handleSidebarToggle} />
      <Box
        sx={{
          position: 'relative',
          marginTop: headerTabsConfig
            ? `${isSmallScreen ? MOBILE_HEADER_WITH_TABS_HEIGHT : HEADER_WITH_TABS_HEIGHT}px`
            : `${isSmallScreen ? MOBILE_HEADER_HEIGHT : HEADER_HEIGHT}px`,
          width: '100%',
          minHeight: `calc(100vh - ${
            headerTabsConfig
              ? `${isSmallScreen ? MOBILE_HEADER_WITH_TABS_HEIGHT : HEADER_WITH_TABS_HEIGHT}px`
              : `${isSmallScreen ? MOBILE_HEADER_HEIGHT : HEADER_HEIGHT}px`
          })`,
          padding: noPadding ? undefined : '16px'
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default Page;
