import { useEffect } from 'react';

import useBranchId from '@maya/hooks/useBranchId';
import useTranslate from '@maya/hooks/useTranslate';
import { useAppDispatch, useAppSelector } from '@maya/store/hooks';
import { loadVisit, selectVisit } from '@maya/store/slices/visit';

export default function useVisit(visitId?: string) {
  const branchId = useBranchId();
  const dispatch = useAppDispatch();
  const t = useTranslate();

  useEffect(() => {
    if (!branchId || !visitId) {
      return;
    }

    dispatch(loadVisit(branchId, visitId, t));
  }, [branchId, dispatch, t, visitId]);

  return useAppSelector(selectVisit);
}
