import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import Page from '@maya/common/Page';
import Loading from '@maya/common/loading/Loading';
import useQuery from '@maya/hooks/useQuery';
import useTranslate from '@maya/hooks/useTranslate';
import { RecordStatus } from '@maya/interface';
import { useAppSelector } from '@maya/store/hooks';
import { selectIsOffice } from '@maya/store/slices/auth';
import { selectPatientConsentLoading } from '@maya/store/slices/patient-consent';
import PatientConsentForm from './PatientConsentForm';
import usePatientConsent from './hooks/usePatientConsent';

import type { FC } from 'react';

const PatientConsent: FC = () => {
  const t = useTranslate();
  const { id, visitId } = useParams();
  const query = useQuery();

  const patientConsentLoading = useAppSelector(selectPatientConsentLoading);
  const isOffice = useAppSelector(selectIsOffice);

  const patientConsent = usePatientConsent(visitId, id);

  const backTo = useMemo(() => query.get('backTo') ?? `/visit/${visitId}`, [query, visitId]);

  return (
    <Page title={t('patientConsent.header')} color="paper" backTo={backTo}>
      {!patientConsent || patientConsentLoading || !id || !visitId ? (
        <Loading />
      ) : (
        <PatientConsentForm
          id={id}
          visitId={visitId}
          patientConsent={patientConsent}
          readOnly={isOffice || patientConsent.status === RecordStatus.submitted}
        />
      )}
    </Page>
  );
};

export default PatientConsent;
