import { useEffect } from 'react';

import useBranchId from '@maya/hooks/useBranchId';
import { useAppDispatch, useAppSelector } from '@maya/store/hooks';
import { loadVisits, selectVisits } from '@maya/store/slices/visit';
import useTranslate from '@maya/hooks/useTranslate';

import type DateRangeISO from '@maya/common/date/DateRangeISO';

export default function useVisits(patientId?: string, dateRangeISO?: DateRangeISO) {
  const branchId = useBranchId();
  const dispatch = useAppDispatch();
  const t = useTranslate();

  useEffect(() => {
    if (!branchId || !patientId || !dateRangeISO) {
      return;
    }

    dispatch(loadVisits(branchId, patientId, dateRangeISO, t));
  }, [branchId, dateRangeISO, dispatch, patientId, t]);

  return useAppSelector(selectVisits);
}
