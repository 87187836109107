import { useEffect } from 'react';

import useBranchId from '@maya/hooks/useBranchId';
import useTranslate from '@maya/hooks/useTranslate';
import { useAppDispatch, useAppSelector } from '@maya/store/hooks';
import { loadEmployeeWages, selectWages } from '@maya/store/slices/payroll';

export default function useEmployeeWages() {
  const branchId = useBranchId();
  const t = useTranslate();

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!branchId) {
      return;
    }

    dispatch(loadEmployeeWages(branchId, t));
  }, [branchId, dispatch, t]);

  return useAppSelector(selectWages);
}
