import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import Page from '@maya/common/Page';
import useTranslate from '@maya/hooks/useTranslate';
import UserForm from './UserForm';

import type { FC } from 'react';

const NewUserView: FC = () => {
  const t = useTranslate();
  const navigate = useNavigate();

  const endEditing = useCallback(() => {
    navigate('/user/list');
  }, [navigate]);

  return (
    <Page title={t('user.view.newHeader')} backTo="/user/list" color="paper">
      <UserForm key="editing-form" onClose={endEditing} />
    </Page>
  );
};

export default NewUserView;
