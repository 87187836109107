import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Empty from '@maya/common/Empty';
import Loading from '@maya/common/loading/Loading';
import useTranslate from '@maya/hooks/useTranslate';
import { useAppSelector } from '@maya/store/hooks';
import { selectVisitIsLoading } from '@maya/store/slices/visit';
import VisitSummary from './VisitSummary';
import useSummary from './hooks/useSummary';

import type { FC } from 'react';

const Summary: FC = () => {
  const t = useTranslate();
  const summary = useSummary();
  const summaryLoading = useAppSelector(selectVisitIsLoading);

  if (!summary || summaryLoading) {
    return <Loading />;
  }

  if (summary.overdue.data.length === 0 && summary.today.data.length === 0 && summary.upcoming.data.length === 0) {
    return (
      <Empty key="allCaughtUp" image="tasks" data-testid="allCaughtUp">
        {t('dashboard.allCaughtUp')}
      </Empty>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '16px'
      }}
    >
      {summary.overdue.data.length > 0 ? (
        <Box key="overdue" data-testid="overdue" sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            {t('dashboard.types.overdue')}
          </Typography>
          {summary.overdue.data.map((visit) => (
            <VisitSummary key={visit.id} visit={visit} type="overdue" />
          ))}
        </Box>
      ) : null}
      {summary.today.data.length > 0 ? (
        <Box key="today" data-testid="today" sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            {t('dashboard.types.today')}
          </Typography>
          {summary.today.data.map((visit) => (
            <VisitSummary key={visit.id} visit={visit} type="today" />
          ))}
        </Box>
      ) : null}
      {summary.upcoming.data.length > 0 ? (
        <Box key="upcoming" data-testid="upcoming" sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            {t('dashboard.types.upcoming')}
          </Typography>
          {summary.upcoming.data.map((visit) => (
            <VisitSummary key={visit.id} visit={visit} type="upcoming" />
          ))}
        </Box>
      ) : null}
    </Box>
  );
};

export default Summary;
