import { useEffect } from 'react';

import useBranchId from '@maya/hooks/useBranchId';
import useTranslate from '@maya/hooks/useTranslate';
import { useAppDispatch, useAppSelector } from '@maya/store/hooks';
import { selectProfile } from '@maya/store/slices/auth';
import { loadEmployeeVisits, selectVisits } from '@maya/store/slices/visit';

import type DateRangeISO from '@maya/common/date/DateRangeISO';

export default function useEmployeeVisits(dateRangeISO?: DateRangeISO) {
  const branchId = useBranchId();
  const profile = useAppSelector(selectProfile);
  const t = useTranslate();

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!branchId || !profile?.id || !dateRangeISO) {
      return;
    }

    dispatch(loadEmployeeVisits(branchId, profile.id, dateRangeISO, t));
  }, [branchId, dateRangeISO, dispatch, profile?.id, t]);

  return useAppSelector(selectVisits);
}
