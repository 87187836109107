// Define a type for the slice state
import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import Api from '@maya/api/api';
import fetch, { fetchAllList } from '@maya/api/fetch';

import type DateRangeISO from '@maya/common/date/DateRangeISO';
import type { CreateVisitDTO, VisitDTO, VisitsDTO, VisitsSummaryDTO } from '@maya/interface';
import type { AppDispatch, RootState } from '@maya/store';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { t } from 'react-polyglot';

export interface VisitState {
  visits: VisitDTO[];
  visit?: VisitDTO;
  total: number;
  isLoading: boolean;
  saveInProgress: boolean;
  summary?: VisitsSummaryDTO;
}

// Define the initial state using that type
const initialState: VisitState = {
  visits: [],
  total: 0,
  isLoading: false,
  saveInProgress: false
};

const adjustTime = (date: number) => {
  // The time of day must be on the hour or half hour.  Adjust the time of day back to the closest 30 minute
  // mark (better to be early than late).
  const dateObj = dayjs(date);
  const adjustment = dateObj.get('minutes') % 30;
  return dateObj.subtract(adjustment, 'minutes').startOf('minutes').valueOf();
};

const fixVisitDate = (visit: VisitDTO) => ({ ...visit, scheduledDate: adjustTime(visit.scheduledDate) });

const fixVisitsDate = (visits: VisitDTO[]) => visits.map(fixVisitDate);

export const visitSlice = createSlice({
  name: 'visit',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setVisitsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
      if (action.payload) {
        state.visits = [];
      }
    },
    setSaveInProgress: (state, action: PayloadAction<boolean>) => {
      state.saveInProgress = action.payload;
    },
    updateVisits: (state, action: PayloadAction<VisitsDTO>) => {
      state.visits = fixVisitsDate(action.payload.data);
      state.total = action.payload.total;
      state.isLoading = false;
    },
    updateVisit: (state, action: PayloadAction<VisitDTO>) => {
      const visit = fixVisitDate(action.payload);

      const index = state.visits.findIndex((e) => e.id === visit.id);
      if (index >= 0) {
        state.visits[index] = visit;
      } else {
        state.visits.push(visit);
      }

      state.isLoading = false;
      state.saveInProgress = false;
      state.visit = visit;
    },
    updateSummary: (state, action: PayloadAction<VisitsSummaryDTO>) => {
      state.summary = action.payload;
      state.isLoading = false;
    }
  }
});

export const { setSaveInProgress, updateVisits, setVisitsLoading, updateVisit, updateSummary } = visitSlice.actions;

export const selectVisits = (state: RootState) => state.visit.visits;
export const selectVisitsSummary = (state: RootState) => state.visit.summary;
export const selectVisit = (state: RootState) => state.visit.visit;

export const selectVisitSaveInProgress = (state: RootState) => state.visit.saveInProgress;
export const selectVisitIsLoading = (state: RootState) => state.visit.isLoading;

export const loadVisits =
  (branchId: string, patientId: string, dateRangeISO: DateRangeISO, t: t) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    if (state.visit.isLoading || !branchId) {
      return;
    }

    dispatch(setVisitsLoading(true));

    const response = await fetchAllList(Api.visits_Get, t, {
      params: { branchId, patientId },
      query: {
        startDate: dateRangeISO.start,
        endDate: dateRangeISO.end
      }
    });

    if (response) {
      dispatch(updateVisits(response));
    } else {
      dispatch(setVisitsLoading(false));
    }
  };

export const loadVisit =
  (branchId: string, visitId: string, t: t) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    if (state.visit.isLoading || !branchId || !visitId) {
      return;
    }

    dispatch(setVisitsLoading(true));

    const response = await fetch(Api.visit_Get, t, {
      params: { branchId, visitId }
    });

    if (response) {
      dispatch(updateVisit(response));
    } else {
      dispatch(setVisitsLoading(false));
    }
  };

export const deleteVisit =
  (branchId: string, visitId: string, t: t) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    if (state.visit.isLoading || !branchId || !visitId) {
      return;
    }

    dispatch(setSaveInProgress(true));

    const response = await fetch(Api.visit_Delete, t, {
      params: { branchId, visitId }
    });

    if (response) {
      dispatch(updateVisit(response));
    } else {
      dispatch(setSaveInProgress(false));
    }
  };

export const createVisit =
  (branchId: string, visitData: CreateVisitDTO, t: t) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    if (state.visit.isLoading) {
      return;
    }

    dispatch(setSaveInProgress(true));

    const visit = await fetch(Api.visit_Post, t, { params: { branchId }, body: visitData });

    if (visit) {
      dispatch(updateVisit(visit));
    } else {
      dispatch(setSaveInProgress(false));
    }
  };

export const saveVisit =
  (branchId: string, visitId: string, visitData: CreateVisitDTO, t: t) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    if (state.visit.isLoading) {
      return;
    }

    dispatch(setSaveInProgress(true));

    const visit = await fetch(Api.visit_Put, t, { params: { branchId, visitId }, body: visitData });

    if (visit) {
      dispatch(updateVisit(visit));
    } else {
      dispatch(setSaveInProgress(false));
    }
  };

export const loadSummary = (branchId: string, t: t) => async (dispatch: AppDispatch, getState: () => RootState) => {
  const state = getState();
  if (state.visit.isLoading) {
    return;
  }

  dispatch(setSaveInProgress(true));

  const summary = await fetch(Api.visits_EmployeeSummaryGet, t, { params: { branchId } });

  if (summary) {
    dispatch(updateSummary(summary));
  } else {
    dispatch(setSaveInProgress(false));
  }
};

export const loadEmployeeVisits =
  (branchId: string, employeeId: string, dateRangeISO: DateRangeISO, t: t) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    if (state.visit.isLoading || !branchId) {
      return;
    }

    dispatch(setVisitsLoading(true));

    const response = await fetchAllList(Api.visits_EmployeeGet, t, {
      params: { branchId, employeeId },
      query: {
        startDate: dateRangeISO.start,
        endDate: dateRangeISO.end
      }
    });

    if (response) {
      dispatch(updateVisits(response));
    } else {
      dispatch(setVisitsLoading(false));
    }
  };

export default visitSlice.reducer;
