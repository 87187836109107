import { useEffect } from 'react';

import useBranchId from '@maya/hooks/useBranchId';
import useTranslate from '@maya/hooks/useTranslate';
import { useAppDispatch, useAppSelector } from '@maya/store/hooks';
import { loadRecords, selectRecords } from '@maya/store/slices/record';

export default function useRecords(patientId?: string) {
  const branchId = useBranchId();
  const dispatch = useAppDispatch();
  const t = useTranslate();

  useEffect(() => {
    if (!branchId || !patientId) {
      return;
    }

    dispatch(loadRecords(branchId, patientId, t));
  }, [branchId, dispatch, patientId, t]);

  return useAppSelector(selectRecords);
}
