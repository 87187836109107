import { useCallback, useState } from 'react';

import PatientOverviewForm from '@maya/patient/overview/PatientOverviewForm';
import PatientOverviewView from '@maya/patient/overview/PatientOverviewView';
import { useAppSelector } from '@maya/store/hooks';
import { selectIsOffice } from '@maya/store/slices/auth';

import type { PatientDTO } from '@maya/interface';
import type { FC } from 'react';

export interface PatientOverviewProps {
  patient: PatientDTO;
}

const PatientOverview: FC<PatientOverviewProps> = ({ patient }) => {
  const isOffice = useAppSelector(selectIsOffice);

  const [editing, setEditing] = useState(false);
  const startEditing = useCallback(() => {
    if (!isOffice) {
      return;
    }
    setEditing(true);
  }, [isOffice]);
  const endEditing = useCallback(() => {
    setEditing(false);
  }, []);

  return editing ? (
    <PatientOverviewForm patient={patient} onClose={endEditing} />
  ) : (
    <PatientOverviewView patient={patient} onStartEditing={startEditing} />
  );
};

export default PatientOverview;
