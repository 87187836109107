import { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Page from '@maya/common/Page';
import Loading from '@maya/common/loading/Loading';
import useQuery from '@maya/hooks/useQuery';
import useTranslate from '@maya/hooks/useTranslate';
import usePatient from '@maya/patient/hooks/usePatient';
import PatientOverview from '@maya/patient/overview/PatientOverview';
import Records from '@maya/patient/records/Records';
import Visits from '@maya/patient/visits/Visits';
import { useAppSelector } from '@maya/store/hooks';
import { selectIsOffice } from '@maya/store/slices/auth';
import { selectPatientsLoading } from '@maya/store/slices/patient';

import type { HeaderTabsConfig } from '@maya/common/Header';
import type { PatientDTO } from '@maya/interface';
import type { FC, ReactNode, SyntheticEvent } from 'react';

const createActiveTabContent = (tabValue: HeaderTabValue, patient: PatientDTO, isOffice: boolean): ReactNode | null => {
  switch (tabValue) {
    case 'overview': {
      return <PatientOverview patient={patient} />;
    }
    case 'schedule': {
      return <Visits patient={patient} />;
    }
    case 'records': {
      return isOffice ? <Records patient={patient} /> : null;
    }
  }
  return null;
};

type HeaderTabValue = 'overview' | 'schedule' | 'records';

const PatientView: FC = () => {
  const t = useTranslate();
  const { id, tab } = useParams();
  const tabValue = useMemo(() => (tab ?? 'overview') as HeaderTabValue, [tab]);

  const query = useQuery();
  const backTo = useMemo(() => query.get('backTo') ?? '/patient/list', [query]);

  const patient = usePatient(id);
  const patientsLoading = useAppSelector(selectPatientsLoading);
  const navigate = useNavigate();

  const isOffice = useAppSelector(selectIsOffice);

  const title = useMemo(() => {
    if (patient?.id !== id) {
      return '';
    }

    return patient?.fullName ?? '';
  }, [patient?.fullName, patient?.id, id]);

  const handleTabChange = useCallback(
    (_event: SyntheticEvent, newValue: HeaderTabValue) => {
      const backToQuery = query.get('backTo');
      navigate(`/patient/details/${id}/${newValue}${backToQuery ? `?backTo=${backToQuery}` : ''}`);
    },
    [query, navigate, id]
  );

  const headerTabsConfig: HeaderTabsConfig<HeaderTabValue> = useMemo(
    () => ({
      value: tabValue,
      onChange: handleTabChange,
      tabs: [
        { label: t('patients.tabs.overview'), value: 'overview' },
        { label: t('patients.tabs.schedule'), value: 'schedule' },
        isOffice && { label: t('patients.tabs.records'), value: 'records' }
      ]
    }),
    [tabValue, handleTabChange, t, isOffice]
  );

  return (
    <Page title={title} backTo={backTo} headerTabsConfig={headerTabsConfig}>
      {!patient || patientsLoading ? <Loading /> : createActiveTabContent(tabValue, patient, isOffice)}
    </Page>
  );
};
export default PatientView;
