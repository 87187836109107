import { forwardRef } from 'react';
import { IMaskInput } from 'react-imask';

import TextField from './TextField';

import type { FC } from 'react';
import type { TextFieldProps } from './TextField';

interface PhoneNumberMaskProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const PhoneNumberMask = forwardRef<HTMLInputElement, PhoneNumberMaskProps>(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="#00-000-0000"
      definitions={{
        '#': /[1-9]/
      }}
      inputRef={ref}
      onAccept={(value: string) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

const PhoneNumberField: FC<Omit<TextFieldProps, 'placeholder' | 'pattern'>> = ({ InputProps, ...props }) => {
  return (
    <TextField
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      InputProps={{ inputComponent: PhoneNumberMask as any, ...InputProps }}
      placeholder="###-###-####"
      pattern="^[0-9]{3}-[0-9]{3}-[0-9]{4}$"
      {...props}
    />
  );
};

export default PhoneNumberField;
