import { useEffect } from 'react';

import useTranslate from '@maya/hooks/useTranslate';
import { useAppDispatch, useAppSelector } from '@maya/store/hooks';
import { loadAdminCompanies, selectCompanies } from '@maya/store/slices/company';

export default function useAdminCompanies(search: string) {
  const dispatch = useAppDispatch();
  const t = useTranslate();

  useEffect(() => {
    dispatch(loadAdminCompanies(search, t));
  }, [dispatch, search, t]);

  return useAppSelector(selectCompanies);
}
