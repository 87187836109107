import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import { useCallback, useState } from 'react';

import useValidate from '@maya/hooks/useValidate';
import Checkbox from './Checkbox';

import type { FC } from 'react';

export interface CheckboxOption {
  name: string;
  label: string;
  disabled?: boolean;
}

export interface CheckboxGroupProps {
  label: string;
  checkboxes: CheckboxOption[];
  error?: boolean;
  required?: boolean;
  helperText?: string;
  fullWidth?: boolean;
  min?: number;
  max?: number;
  defaultValue: string[] | undefined | null;
  boldLabel?: boolean;
  disabled?: boolean;
  onValidate: (valid: boolean) => void;
  onChange: (newValue: string[]) => void;
}

const CheckboxGroup: FC<CheckboxGroupProps> = ({
  label,
  checkboxes,
  error,
  required = false,
  helperText,
  fullWidth = true,
  min,
  max,
  defaultValue,
  boldLabel = false,
  disabled,
  onValidate,
  onChange
}) => {
  const [data, setData] = useState(defaultValue ?? []);

  const { validate, hint, hasError } = useValidate({
    defaultValue: data,
    required,
    min,
    max,
    error,
    helperText,
    onValidate
  });

  const handleOnChange = useCallback(
    (name: string) => (newValue: boolean) => {
      const newData = [...data];
      const index = newData.indexOf(name);

      if (!newValue) {
        if (index >= 0) {
          newData.splice(index, 1);
        }
      } else {
        if (index < 0) {
          newData.push(name);
        }
      }

      onChange(newData);
      setData(newData);
      validate(newData);
    },
    [data, onChange, validate]
  );

  return (
    <FormControl error={hasError} required={required} fullWidth={fullWidth}>
      <FormLabel component="legend" sx={{ fontWeight: boldLabel ? 'bold' : undefined }}>
        {label}
      </FormLabel>
      <FormGroup>
        {checkboxes.map((checkbox) => (
          <Checkbox
            key={checkbox.name as string}
            label={checkbox.label}
            defaultChecked={data.includes(checkbox.name)}
            onChange={handleOnChange(checkbox.name as string)}
            disabled={checkbox.disabled || disabled}
          />
        ))}
      </FormGroup>
      <FormHelperText>{hint ?? <>&nbsp;</>}</FormHelperText>
    </FormControl>
  );
};

export default CheckboxGroup;
