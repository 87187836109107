// Define a type for the slice state
import { createSlice } from '@reduxjs/toolkit';

import Api from '@maya/api/api';
import fetch from '@maya/api/fetch';

import type { TaskListDTO, UpdateTaskListDTO } from '@maya/interface';
import type { AppDispatch, RootState } from '@maya/store';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { t } from 'react-polyglot';

export interface TaskListState {
  taskList?: TaskListDTO;
  isLoading: boolean;
  saveInProgress: boolean;
}

// Define the initial state using that type
const initialState: TaskListState = {
  isLoading: false,
  saveInProgress: false
};

export const taskListSlice = createSlice({
  name: 'taskList',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setTaskListLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setSaveInProgress: (state, action: PayloadAction<boolean>) => {
      state.saveInProgress = action.payload;
    },
    updateTaskList: (state, action: PayloadAction<TaskListDTO>) => {
      state.taskList = action.payload;
      state.isLoading = false;
      state.saveInProgress = false;
    }
  }
});

export const { setSaveInProgress, updateTaskList, setTaskListLoading } = taskListSlice.actions;

export const selectTaskList = (state: RootState) => state.taskList.taskList;

export const selectTaskListSaveInProgress = (state: RootState) => state.taskList.saveInProgress;
export const selectTaskListIsLoading = (state: RootState) => state.taskList.isLoading;

export const loadTaskList =
  (branchId: string, visitId: string, taskListId: string, t: t) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    if (state.taskList.isLoading || !branchId || !visitId || !taskListId) {
      return;
    }

    dispatch(setTaskListLoading(true));

    const response = await fetch(Api.taskList_Get, t, {
      params: { branchId, visitId, taskListId }
    });

    if (response) {
      dispatch(updateTaskList(response));
    } else {
      dispatch(setTaskListLoading(false));
    }
  };

export const saveTaskList =
  (branchId: string, visitId: string, taskListId: string, taskListData: UpdateTaskListDTO, t: t) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    if (state.taskList.isLoading) {
      return;
    }

    dispatch(setSaveInProgress(true));

    const taskList = await fetch(Api.taskList_Put, t, {
      params: { branchId, visitId, taskListId },
      body: taskListData
    });

    if (taskList) {
      dispatch(updateTaskList(taskList));
    } else {
      dispatch(setSaveInProgress(false));
    }
  };

export default taskListSlice.reducer;
