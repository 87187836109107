import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import { useCallback, useState } from 'react';

import RadioGroup from '@maya/common/radio-group/RadioGroup';
import NumberField from '@maya/common/textfield/NumberField';
import TextField from '@maya/common/textfield/TextField';
import useBranchId from '@maya/hooks/useBranchId';
import useFormValidationData from '@maya/hooks/useFormValidationData';
import useTranslate from '@maya/hooks/useTranslate';
import { useAppDispatch, useAppSelector } from '@maya/store/hooks';
import { saveWage, selectWageSaveInProgress } from '@maya/store/slices/payroll';

import type { PaymentStatus, WageDTO } from '@maya/interface';
import type { FC } from 'react';

export interface WageEditModalProps {
  wage: WageDTO;
  onClose: () => void;
}

const WageEditModal: FC<WageEditModalProps> = ({ wage, onClose }) => {
  const t = useTranslate();
  const [data, setData] = useState<WageDTO>({ ...wage });
  const [formIsValid, setFormValidationData] = useFormValidationData(wage != null);

  const branchId = useBranchId();
  const dispatch = useAppDispatch();
  const saveInProgress = useAppSelector(selectWageSaveInProgress);

  const updateData = useCallback(
    (newData: Partial<WageDTO>) => {
      setData((oldData) => ({
        ...oldData,
        ...newData
      }));
    },
    [setData]
  );

  const handleValidate = useCallback(
    (key: string) => (valid: boolean) => {
      setFormValidationData(key, valid);
    },
    [setFormValidationData]
  );

  const handleSave = useCallback(() => {
    if (!formIsValid || !data) {
      return;
    }

    if (branchId) {
      dispatch(saveWage(branchId, data, t));
    }
    onClose();
  }, [formIsValid, data, branchId, onClose, dispatch, t]);

  return (
    <Dialog disableEscapeKeyDown={true} open={true} role="dialog" data-testid="wage-edit-dialog">
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <DialogTitle role="heading" sx={{ padding: 0 }}>
          {t('payroll.editModal.header')}
        </DialogTitle>
        <IconButton onClick={onClose} data-testid="close-button">
          <CloseIcon />
        </IconButton>
      </Toolbar>
      <Box component="form" autoComplete="off" sx={{ padding: '0 24px 24px 24px' }} data-testid="wage-edit-form">
        <Stack spacing={2}>
          <RadioGroup
            data-testid="status"
            options={[
              {
                label: t('payroll.status.paid'),
                option: 'paid'
              },
              {
                label: t('payroll.status.not-paid'),
                option: 'not-paid'
              }
            ]}
            defaultValue={wage?.status}
            onChange={(newValue) => updateData({ status: newValue as PaymentStatus })}
            onValidate={handleValidate('status')}
          />
        </Stack>
        <Stack direction="row" spacing={3}>
          <NumberField
            data-testid="amount"
            required
            label={t('app.form.amount')}
            defaultValue={data.amount}
            onChange={(newValue) => updateData({ amount: newValue })}
            onValidate={handleValidate('amount')}
          />
          <NumberField
            data-testid="extras"
            label={t('app.form.extras')}
            defaultValue={data.extras}
            onChange={(newValue) => updateData({ extras: newValue })}
            onValidate={handleValidate('extras')}
          />
        </Stack>
        <Stack direction="row" spacing={3}>
          <TextField
            data-testid="notes"
            label={t('app.form.notes')}
            defaultValue={data.notes}
            onChange={(newValue) => updateData({ notes: newValue })}
            onValidate={handleValidate('notes')}
            multiline={true}
            minRows={3}
          />
        </Stack>
        <Stack direction="row" spacing={3}>
          <Button data-testid="save" variant="contained" onClick={handleSave} disabled={!formIsValid || saveInProgress}>
            {t('app.save')}
          </Button>
          <Button data-testid="cancel" onClick={onClose}>
            {t('app.cancel')}
          </Button>
        </Stack>
      </Box>
    </Dialog>
  );
};

export default WageEditModal;
