import { createSlice } from '@reduxjs/toolkit';

import Api from '@maya/api/api';
import fetch, { fetchAllList } from '@maya/api/fetch';

import type { CreatePatientDTO, PatientDTO, PatientsDTO } from '@maya/interface';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { t } from 'react-polyglot';
import type { AppDispatch, RootState } from '../index';

// Define a type for the slice state
export interface PatientState {
  patient?: PatientDTO;
  patients: PatientDTO[];
  total: number;
  isLoading: boolean;
  saveInProgress: boolean;
}

// Define the initial state using that type
const initialState: PatientState = {
  patients: [],
  total: 0,
  isLoading: false,
  saveInProgress: false
};

export const patientSlice = createSlice({
  name: 'patient',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setPatientLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setSaveInProgress: (state, action: PayloadAction<boolean>) => {
      state.saveInProgress = action.payload;
    },
    updatePatients: (state, action: PayloadAction<PatientsDTO>) => {
      state.patients = action.payload.data;
      state.total = action.payload.total;
      state.isLoading = false;
    },
    updatePatient: (state, action: PayloadAction<PatientDTO>) => {
      const patient = action.payload;

      const index = state.patients.findIndex((e) => e.id === patient.id);
      if (index >= 0) {
        state.patients[index] = patient;
      }

      state.patient = action.payload;
      state.isLoading = false;
      state.saveInProgress = false;
    }
  }
});

export const { setPatientLoading, setSaveInProgress, updatePatient, updatePatients } = patientSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectPatients = (state: RootState) => state.patient.patients;
export const selectTotalPatients = (state: RootState) => state.patient.total;

export const selectPatient = (state: RootState) => state.patient.patient;

export const selectPatientsLoading = (state: RootState) => state.patient.isLoading;

export const selectPatientSaveInProgress = (state: RootState) => state.patient.saveInProgress;

export interface PatientSearch {
  branchId?: string;
  search?: string;
}

export const loadPatients =
  ({ branchId, search }: PatientSearch, t: t) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    if (state.patient.isLoading || !branchId) {
      return;
    }

    dispatch(setPatientLoading(true));

    const response = await fetchAllList(Api.patients_Get, t, { params: { branchId }, query: { search } });

    if (response) {
      dispatch(updatePatients(response));
    } else {
      dispatch(setPatientLoading(false));
    }
  };

export const loadPatient =
  (branchId: string, patientId: string, t: t) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    if (state.patient.isLoading || !branchId) {
      return;
    }

    dispatch(setPatientLoading(true));

    const response = await fetch(Api.patient_Get, t, { params: { branchId, patientId } });

    if (response) {
      dispatch(updatePatient(response));
    } else {
      dispatch(setPatientLoading(false));
    }
  };

export const createPatient =
  (branchId: string, patientData: CreatePatientDTO, t: t) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    if (state.patient.isLoading) {
      return;
    }

    dispatch(setSaveInProgress(true));

    const patient = await fetch(Api.patient_Post, t, {
      params: { branchId },
      body: patientData,
      defaultError: 'patients.errors.create'
    });

    if (patient) {
      dispatch(updatePatient(patient));
      return true;
    } else {
      dispatch(setSaveInProgress(false));
      return false;
    }
  };

export const savePatient =
  (branchId: string, patient: PatientDTO, t: t) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    if (state.patient.isLoading) {
      return;
    }

    const updatedPatient = await fetch(Api.patient_Put, t, {
      params: { branchId, patientId: patient.id },
      body: patient,
      defaultError: 'patients.errors.update'
    });

    if (updatedPatient) {
      dispatch(updatePatient(updatedPatient));
      return true;
    } else {
      dispatch(setSaveInProgress(false));
      return false;
    }
  };

export default patientSlice.reducer;
