import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import type { FC } from 'react';

const Loading: FC = () => (
  <Box sx={{ display: 'flex', position: 'absolute', inset: 0, alignItems: 'center', justifyContent: 'center' }}>
    <CircularProgress data-testid="spinner" />
  </Box>
);

export default Loading;
