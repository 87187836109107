import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import logo from '@maya/assets/images/maya-logo.png';
import Loading from '@maya/common/loading/Loading';
import { useIsValidPassword } from '@maya/hooks/useIsValidPassword';
import useTranslate from '@maya/hooks/useTranslate';
import { useAppDispatch, useAppSelector } from '@maya/store/hooks';
import {
  login,
  selectAuthCheckComplete,
  selectAuthCheckInProgress,
  selectAuthInProgress
} from '@maya/store/slices/auth';
import { isEmpty } from '@maya/util/string.util';

import type { FC, FormEvent, KeyboardEventHandler, MouseEvent } from 'react';

const Login: FC = () => {
  const t = useTranslate();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const isValidPassword = useIsValidPassword(password);

  const isInvalid = useMemo(() => {
    return isEmpty(username) || isEmpty(password) || !isValidPassword;
  }, [isValidPassword, password, username]);

  const inProgress = useAppSelector(selectAuthInProgress);
  const authCheckInProgress = useAppSelector(selectAuthCheckInProgress);
  const authCheckComplete = useAppSelector(selectAuthCheckComplete);

  const dispatch = useAppDispatch();
  const handleLogin = useCallback(
    (event?: FormEvent) => {
      event?.preventDefault();

      if (isInvalid) {
        return;
      }

      dispatch(login(username, password, t));
    },
    [dispatch, isInvalid, password, t, username]
  );

  const handleKeydown: KeyboardEventHandler = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        handleLogin();
      }
    },
    [handleLogin]
  );

  return (
    <Box
      sx={{
        inset: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh'
      }}
    >
      {authCheckInProgress || !authCheckComplete ? (
        <Loading key="auth-loading" />
      ) : (
        <Card sx={{ maxWidth: 500, width: '100%', margin: '0 16px' }}>
          <CardContent>
            <Box component="form" sx={{ display: 'flex', flexDirection: 'column' }} onSubmit={handleLogin}>
              <Box
                component="img"
                src={logo}
                alt="Maya Logo"
                sx={{
                  maxWidth: 350,
                  height: 150,
                  alignSelf: 'center',
                  marginBottom: '40px'
                }}
              />
              <TextField
                data-testid="username"
                label={t('auth.username')}
                variant="filled"
                sx={{ marginBottom: '24px' }}
                onChange={(event) => {
                  setUsername(event.target.value);
                }}
                onKeyDown={handleKeydown}
                disabled={inProgress}
                required
              />
              <TextField
                data-testid="password"
                label={t('auth.password')}
                variant="filled"
                type={showPassword ? 'text' : 'password'}
                sx={{ marginBottom: '24px' }}
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
                onKeyDown={handleKeydown}
                disabled={inProgress}
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <Box sx={{ display: 'flex', gap: '16px', marginBottom: '20px' }}>
                <Button
                  data-testid="login"
                  variant="contained"
                  onClick={handleLogin}
                  disabled={isInvalid || inProgress}
                >
                  {t('auth.login')}
                </Button>
                <Button component={Link} data-testid="forgot-password" variant="text" to="/forgot-password">
                  {t('auth.forgotPassword')}
                </Button>
              </Box>
              <Typography variant="body2" sx={{ textAlign: 'center' }}>
                {t('copyright.copyright')}
              </Typography>
              <Typography variant="body2" sx={{ textAlign: 'center' }}>
                {t('copyright.allRightsReserved')}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      )}
    </Box>
  );
};

export default Login;
