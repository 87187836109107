import { useCallback, useState } from 'react';

import Page from '@maya/common/Page';
import useTranslate from '@maya/hooks/useTranslate';
import CompanyView from './CompanyView';

import type { FC } from 'react';

const CompanyViewPage: FC = () => {
  const t = useTranslate();
  const [editing, setEditing] = useState(false);
  const startEditing = useCallback(() => {
    setEditing(true);
  }, []);
  const endEditing = useCallback(() => {
    setEditing(false);
  }, []);

  return (
    <Page
      title={t(editing ? 'company.view.editHeader' : 'company.view.header')}
      backTo="/company/list"
      color={editing ? 'paper' : 'default'}
    >
      <CompanyView startEditing={startEditing} endEditing={endEditing} />
    </Page>
  );
};

export default CompanyViewPage;
