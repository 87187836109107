import AddIcon from '@mui/icons-material/Add';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Fab from '@mui/material/Fab';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';

import Empty from '@maya/common/Empty';
import TableCell from '@maya/common/table/TableCell';
import useBranchId from '@maya/hooks/useBranchId';
import useTranslate from '@maya/hooks/useTranslate';
import { useAppDispatch, useAppSelector } from '@maya/store/hooks';
import { saveVisitType, selectTotalVisitTypes } from '@maya/store/slices/visit-type';
import useVisitTypes from './hooks/useVisitTypes';

import type { VisitTypeDTO } from '@maya/interface';
import type { FC, MouseEvent } from 'react';

const VisitTypeList: FC = () => {
  const t = useTranslate();
  const branchId = useBranchId();

  const visitTypes = useVisitTypes({ includeInactive: true });
  const totalVisitTypes = useAppSelector(selectTotalVisitTypes);

  const dispatch = useAppDispatch();
  const toggleVisitTypeActive = useCallback(
    (visitType: VisitTypeDTO) => (event: MouseEvent) => {
      event.stopPropagation();
      if (!branchId) {
        return;
      }

      dispatch(
        saveVisitType(
          branchId,
          {
            ...visitType,
            active: !visitType.active
          },
          t
        )
      );
    },
    [branchId, dispatch, t]
  );
  return (
    <>
      <Card data-testid="visit-type-list" sx={{ flexGrow: 1 }}>
        <CardContent
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            paddingBottom: '12px!important'
          }}
        >
          {visitTypes.length === 0 ? (
            <Empty key="empty" image="list">
              {t('visitTypes.list.empty')}
            </Empty>
          ) : (
            <>
              <TableContainer sx={{ flexGrow: 1 }}>
                <Table aria-label="visitType list" stickyHeader component="div">
                  <TableHead component="div">
                    <TableRow component="div">
                      <TableCell>{t('visitTypes.list.column.visitTypeName')}</TableCell>
                      <TableCell>{t('visitTypes.list.column.discipline')}</TableCell>
                      <TableCell>{t('visitTypes.list.column.active')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody component="div">
                    {visitTypes.map((visitType) => (
                      <TableRow
                        component={Link}
                        key={visitType.id}
                        data-testid={`visitType-${visitType.name}`}
                        sx={{ textDecoration: 'none', '&:last-child td, &:last-child th': { border: 0 } }}
                        hover
                        to={`/visit-type/details/${visitType.id}`}
                      >
                        <TableCell>{visitType.name}</TableCell>
                        <TableCell>{visitType.disciplineName}</TableCell>
                        <TableCell data-testid="checkbox" sx={{ padding: 0 }}>
                          <Switch
                            checked={visitType.active}
                            color="secondary"
                            onClick={toggleVisitTypeActive(visitType)}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Typography variant="body2" sx={{ textAlign: 'right' }}>
                {t('app.totalRows')} {totalVisitTypes}
              </Typography>
            </>
          )}
        </CardContent>
      </Card>
      <Fab
        variant="extended"
        component={Link}
        to="/visit-type/new"
        color="secondary"
        sx={{ alignSelf: 'flex-end', gap: '4px', minHeight: '48px' }}
      >
        <AddIcon />
        {t('visitTypes.view.header')}
      </Fab>
    </>
  );
};

export default VisitTypeList;
