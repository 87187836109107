import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Checkbox from '@maya/common/checkbox/Checkbox';
import RadioGroup from '@maya/common/radio-group/RadioGroup';
import Signature from '@maya/common/signature/Signature';
import TextField from '@maya/common/textfield/TextField';
import useBranchId from '@maya/hooks/useBranchId';
import useTranslate from '@maya/hooks/useTranslate';
import { RecordStatus } from '@maya/interface';
import { useAppDispatch, useAppSelector } from '@maya/store/hooks';
import { savePatientConsent, selectPatientConsentSaveInProgress } from '@maya/store/slices/patient-consent';
import { isNotEmpty } from '@maya/util/string.util';

import type { PatientConsentDTO, UpdatePatientConsentDTO } from '@maya/interface';
import type { TypographyProps } from '@mui/material/Typography';
import type { FC } from 'react';

const SectionHeading: FC<Pick<TypographyProps, 'children' | 'sx'>> = ({ children, sx }) => (
  <Typography component="h3" variant="subtitle1" sx={{ margin: '16px 0', ...sx }}>
    {children}
  </Typography>
);

export interface PatientConsentFormProps {
  visitId: string;
  id: string;
  patientConsent: PatientConsentDTO;
  readOnly?: boolean;
}

type Form = Omit<UpdatePatientConsentDTO, 'status'>;

const PatientConsentForm: FC<PatientConsentFormProps> = ({ visitId, id, patientConsent, readOnly = false }) => {
  const t = useTranslate();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const branchId = useBranchId();
  const saving = useAppSelector(selectPatientConsentSaveInProgress);

  const [form, setForm] = useState<Form>({
    noInformationReleaseAbout: patientConsent.noInformationReleaseAbout ?? '',
    noInformationReleaseTo: patientConsent.noInformationReleaseTo ?? '',
    providedNursing: patientConsent.providedNursing ?? false,
    providedPT: patientConsent.providedPT ?? false,
    providedOT: patientConsent.providedOT ?? false,
    providedST: patientConsent.providedST ?? false,
    providedDiet: patientConsent.providedDiet ?? false,
    providedMSW: patientConsent.providedMSW ?? false,
    providedCHHA: patientConsent.providedCHHA ?? false,
    advancedDirective: patientConsent.advancedDirective ?? false,
    powerOfAttorney: patientConsent.powerOfAttorney ?? false,
    livingWill: patientConsent.livingWill ?? false,
    doNotResuscitate: patientConsent.doNotResuscitate ?? false,
    doNotKnowAdvanceDirectives: patientConsent.doNotKnowAdvanceDirectives ?? false,
    otherAdvanceDirectives: patientConsent.otherAdvanceDirectives ?? '',
    photograph: patientConsent.photograph ?? false,
    signature: patientConsent.signature ?? '',
    additionalSignature: patientConsent.additionalSignature ?? ''
  });

  const {
    noInformationReleaseAbout,
    noInformationReleaseTo,
    providedNursing,
    providedPT,
    providedOT,
    providedST,
    providedDiet,
    providedMSW,
    providedCHHA,
    advancedDirective,
    powerOfAttorney,
    livingWill,
    doNotResuscitate,
    doNotKnowAdvanceDirectives,
    otherAdvanceDirectives,
    photograph,
    signature,
    additionalSignature
  } = form;

  const updateForm = useCallback((data: Partial<Form>) => {
    setForm((old) => ({ ...old, ...data }));
  }, []);

  const valid = useMemo(
    () => isNotEmpty(signature) && isNotEmpty(additionalSignature),
    [signature, additionalSignature]
  );

  const disabled = saving;

  const saveForm = useCallback(
    async (status: RecordStatus) => {
      if (!branchId || (!valid && status === 'submitted')) {
        return;
      }

      await dispatch(savePatientConsent(branchId, visitId, id, { ...form, status }, t));
      navigate(`/visit/${visitId}`);
    },
    [branchId, dispatch, form, id, navigate, t, valid, visitId]
  );

  const handleSubmit = useCallback(() => {
    saveForm(RecordStatus.submitted);
  }, [saveForm]);

  const handleSave = useCallback(() => {
    saveForm(RecordStatus.saved);
  }, [saveForm]);

  const replacements = {
    branchName: patientConsent.branchName,
    branchPhone: patientConsent.branchPhone
  };

  return (
    <>
      <SectionHeading sx={{ marginTop: 0 }}>{t('patientConsent.certification_header1')}</SectionHeading>
      <p>{t('patientConsent.certification_statement1', replacements)}</p>
      <SectionHeading>{t('patientConsent.certification_header2')}</SectionHeading>
      <p>{t('patientConsent.certification_statement2', replacements)}</p>
      <SectionHeading>{t('patientConsent.certification_header3')}</SectionHeading>
      <p>{t('patientConsent.certification_statement3', replacements)}</p>
      <Divider sx={{ margin: '16px 0' }} />
      <SectionHeading>{t('patientConsent.release_title')}</SectionHeading>
      <p>{t('patientConsent.release_statement', replacements)}</p>
      <TextField
        data-testid="noInformationReleaseAbout"
        label={t('patientConsent.release_notReleasedAbout')}
        multiline
        rows={4}
        defaultValue={noInformationReleaseAbout}
        onChange={(noInformationReleaseAbout) => updateForm({ noInformationReleaseAbout })}
        disabled={disabled}
        readOnly={readOnly}
      />
      <TextField
        data-testid="noInformationReleaseTo"
        label={t('patientConsent.release_notReleasedTo')}
        multiline
        rows={4}
        defaultValue={noInformationReleaseTo}
        onChange={(noInformationReleaseTo) => updateForm({ noInformationReleaseTo })}
        disabled={disabled}
        readOnly={readOnly}
      />
      <Divider sx={{ marginTop: '16px' }} />
      <SectionHeading>{t('patientConsent.services_title')}</SectionHeading>
      <p>{t('patientConsent.services_statement', replacements)}</p>
      <Stack sx={{ marginLeft: '8px', marginBottom: '16px' }}>
        <Checkbox
          data-testid="providedNursing"
          label={t('patientConsent.services_nursing')}
          defaultChecked={providedNursing ?? false}
          onChange={(providedNursing) => updateForm({ providedNursing })}
          disabled={disabled}
          readOnly={readOnly}
        />
        <Checkbox
          data-testid="providedPT"
          label={t('patientConsent.services_pt')}
          defaultChecked={providedPT ?? false}
          onChange={(providedPT) => updateForm({ providedPT })}
          disabled={disabled}
          readOnly={readOnly}
        />
        <Checkbox
          data-testid="providedOT"
          label={t('patientConsent.services_ot')}
          defaultChecked={providedOT ?? false}
          onChange={(providedOT) => updateForm({ providedOT })}
          disabled={disabled}
          readOnly={readOnly}
        />
        <Checkbox
          data-testid="providedST"
          label={t('patientConsent.services_st')}
          defaultChecked={providedST ?? false}
          onChange={(providedST) => updateForm({ providedST })}
          disabled={disabled}
          readOnly={readOnly}
        />
        <Checkbox
          data-testid="providedDiet"
          label={t('patientConsent.services_dietitian')}
          defaultChecked={providedDiet ?? false}
          onChange={(providedDiet) => updateForm({ providedDiet })}
          disabled={disabled}
          readOnly={readOnly}
        />
        <Checkbox
          data-testid="providedMSW"
          label={t('patientConsent.services_msw')}
          defaultChecked={providedMSW ?? false}
          onChange={(providedMSW) => updateForm({ providedMSW })}
          disabled={disabled}
          readOnly={readOnly}
        />
        <Checkbox
          data-testid="providedCHHA"
          label={t('patientConsent.services_chha')}
          defaultChecked={providedCHHA ?? false}
          onChange={(providedCHHA) => updateForm({ providedCHHA })}
          disabled={disabled}
          readOnly={readOnly}
        />
      </Stack>
      <Divider sx={{ marginTop: '16px' }} />
      <SectionHeading>{t('patientConsent.directives_title')}</SectionHeading>
      <p>{t('patientConsent.directives_statement', replacements)}</p>
      <Stack sx={{ marginLeft: '8px', marginBottom: '16px' }}>
        <Checkbox
          data-testid="advancedDirective"
          label={t('patientConsent.directives_directive')}
          defaultChecked={advancedDirective ?? false}
          onChange={(advancedDirective) => updateForm({ advancedDirective })}
          disabled={disabled}
          readOnly={readOnly}
        />
        <Checkbox
          data-testid="powerOfAttorney"
          label={t('patientConsent.directives_durable')}
          defaultChecked={powerOfAttorney ?? false}
          onChange={(powerOfAttorney) => updateForm({ powerOfAttorney })}
          disabled={disabled}
          readOnly={readOnly}
        />
        <Checkbox
          data-testid="livingWill"
          label={t('patientConsent.directives_will')}
          defaultChecked={livingWill ?? false}
          onChange={(livingWill) => updateForm({ livingWill })}
          disabled={disabled}
          readOnly={readOnly}
        />
        <Checkbox
          data-testid="doNotResuscitate"
          label={t('patientConsent.directives_dnr')}
          defaultChecked={doNotResuscitate ?? false}
          onChange={(doNotResuscitate) => updateForm({ doNotResuscitate })}
          disabled={disabled}
          readOnly={readOnly}
        />
        <Checkbox
          data-testid="doNotKnowAdvanceDirectives"
          label={t('patientConsent.directives_none')}
          defaultChecked={doNotKnowAdvanceDirectives ?? false}
          onChange={(doNotKnowAdvanceDirectives) => updateForm({ doNotKnowAdvanceDirectives })}
          disabled={disabled}
          readOnly={readOnly}
        />
      </Stack>
      <TextField
        data-testid="otherAdvanceDirectives"
        label={t('patientConsent.directives_other')}
        multiline
        rows={4}
        defaultValue={otherAdvanceDirectives}
        onChange={(otherAdvanceDirectives) => updateForm({ otherAdvanceDirectives })}
        disabled={disabled}
        readOnly={readOnly}
      />
      <Divider sx={{ marginTop: '16px' }} />
      <SectionHeading>{t('patientConsent.consent_title')}</SectionHeading>
      <RadioGroup
        data-testid="photograph"
        name="usesOxygenType"
        options={[
          {
            label: t('patientConsent.consent_consent'),
            option: 'true'
          },
          {
            label: t('patientConsent.consent_notConsent'),
            option: 'false'
          }
        ]}
        defaultValue={String(photograph)}
        onChange={(photograph) => updateForm({ photograph: photograph === 'true' })}
        disabled={disabled}
        readOnly={readOnly}
        helperText=""
        sx={{ marginLeft: '8px' }}
      />
      <p>{t('patientConsent.consent_statement', replacements)}</p>
      <Divider sx={{ marginTop: '16px' }} />
      <SectionHeading>{t('patientConsent.payment_title')}</SectionHeading>
      <p>{t('patientConsent.payment_statement', replacements)}</p>
      <Divider />
      <SectionHeading>{t('patientConsent.medicare_title')}</SectionHeading>
      <p>{t('patientConsent.medicare_statement', replacements)}</p>
      <Divider />
      <SectionHeading>{t('patientConsent.privacy_title')}</SectionHeading>
      <p>{t('patientConsent.privacy_statement1', replacements)}</p>
      <p>{t('patientConsent.privacy_statement2', replacements)}</p>
      <Divider />
      <SectionHeading>{t('patientConsent.portability_title')}</SectionHeading>
      <p>{t('patientConsent.portability_statement', replacements)}</p>
      <Divider />
      <SectionHeading>{t('patientConsent.patientRights_title')}</SectionHeading>
      <p>{t('patientConsent.patientRights_statement', replacements)}</p>
      <ol>
        <li>{t('patientConsent.patientRights_point1', replacements)}</li>
        <li>{t('patientConsent.patientRights_point2', replacements)}</li>
        <li>{t('patientConsent.patientRights_point3', replacements)}</li>
        <li>{t('patientConsent.patientRights_point4', replacements)}</li>
        <li>{t('patientConsent.patientRights_point5', replacements)}</li>
        <li>{t('patientConsent.patientRights_point6', replacements)}</li>
        <li>{t('patientConsent.patientRights_point7', replacements)}</li>
        <li>{t('patientConsent.patientRights_point8', replacements)}</li>
        <li>{t('patientConsent.patientRights_point9', replacements)}</li>
        <li>{t('patientConsent.patientRights_point10', replacements)}</li>
        <li>{t('patientConsent.patientRights_point11', replacements)}</li>
        <li>{t('patientConsent.patientRights_point12', replacements)}</li>
        <li>{t('patientConsent.patientRights_point13', replacements)}</li>
        <li>{t('patientConsent.patientRights_point14', replacements)}</li>
        <li>{t('patientConsent.patientRights_point15', replacements)}</li>
        <li>{t('patientConsent.patientRights_point16', replacements)}</li>
        <li>
          {t('patientConsent.patientRights_point17', replacements)}
          <br />
          {patientConsent.administrator && (
            <div>{`${t('patientConsent.administrator')}: ${patientConsent.administrator}`}</div>
          )}
          <div>
            {`${patientConsent.branchStreet}, ${patientConsent.branchCity} ${patientConsent.branchState}. ${patientConsent.branchZip}`}
          </div>
          <div>{`Phone: ${patientConsent.branchPhone}`}</div>
          {patientConsent.branchFax && <div>{`Fax: ${patientConsent.branchFax}`}</div>}
        </li>
        <li>{t('patientConsent.patientRights_point18', replacements)}</li>
      </ol>
      <Divider sx={{ marginTop: '16px' }} />
      <SectionHeading>{t('patientConsent.patientResponsibilities_title')}</SectionHeading>

      <p>{t('patientConsent.patientResponsibilities_statement', replacements)}</p>
      <ol>
        <li>{t('patientConsent.patientResponsibilities_point1', replacements)}</li>
        <li>{t('patientConsent.patientResponsibilities_point2', replacements)}</li>
        <li>{t('patientConsent.patientResponsibilities_point3', replacements)}</li>
        <li>{t('patientConsent.patientResponsibilities_point4', replacements)}</li>
        <li>{t('patientConsent.patientResponsibilities_point5', replacements)}</li>
        <li>{t('patientConsent.patientResponsibilities_point6', replacements)}</li>
        <li>{t('patientConsent.patientResponsibilities_point7', replacements)}</li>
        <li>{t('patientConsent.patientResponsibilities_point8', replacements)}</li>
        <li>{t('patientConsent.patientResponsibilities_point9', replacements)}</li>
        <li>{t('patientConsent.patientResponsibilities_point10', replacements)}</li>
      </ol>
      <Divider sx={{ marginTop: '16px' }} />
      <SectionHeading>{t('patientConsent.patientAcknowledgement_title')}</SectionHeading>
      <Signature
        data-testid="signature"
        initialUrl={signature}
        onValue={(signature) => updateForm({ signature })}
        required
        readOnly={disabled || readOnly}
      />
      <Divider sx={{ marginTop: '16px' }} />
      <SectionHeading>{t('patientConsent.staffAuthorization_title', replacements)}</SectionHeading>
      <Signature
        data-testid="additionalSignature"
        initialUrl={additionalSignature}
        onValue={(additionalSignature) => updateForm({ additionalSignature })}
        required
        readOnly={disabled || readOnly}
      />
      {!readOnly ? (
        <>
          <Divider sx={{ marginTop: '16px' }} />
          <Box sx={{ display: 'flex', gap: '8px', marginTop: '16px' }}>
            <Button data-testid="submit" variant="contained" disabled={!valid || saving} onClick={handleSubmit}>
              {t('app.submit')}
            </Button>
            <Button data-testid="save" variant="text" disabled={saving} onClick={handleSave}>
              {t('app.save')}
            </Button>
          </Box>
        </>
      ) : null}
    </>
  );
};

export default PatientConsentForm;
