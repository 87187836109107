import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/Edit';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PersonIcon from '@mui/icons-material/Person';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import ButtonGroup from '@mui/material/ButtonGroup';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

import useTranslate from '@maya/hooks/useTranslate';
import { useAppSelector } from '@maya/store/hooks';
import { selectIsOffice } from '@maya/store/slices/auth';

import type { VisitDTO } from '@maya/interface';
import type { FC } from 'react';

export interface VisitModalProps {
  visit: Partial<VisitDTO>;
  showPatientInSummary?: boolean;
  onClose: () => void;
  onDelete: () => void;
  onEdit: () => void;
}

function isCanceled(status?: string) {
  return status?.toLowerCase() === 'canceled';
}

const VisitViewModal: FC<VisitModalProps> = ({ visit, showPatientInSummary, onClose, onDelete, onEdit }) => {
  const t = useTranslate();
  const isOffice = useAppSelector(selectIsOffice);

  const { patient, status } = visit;

  return (
    <Dialog open={true} onClose={onClose} role="dialog" data-testid="patient-visit-view-dialog">
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <DialogTitle role="heading" sx={{ paddingLeft: 0 }}>
          {t('patients.visit.header')}
        </DialogTitle>
        <ButtonGroup>
          {isOffice && !isCanceled(status) && (
            <>
              <IconButton onClick={onDelete} data-testid="delete-button">
                <DeleteIcon />
              </IconButton>
              <IconButton onClick={onEdit} data-testid="edit-button">
                <EditIcon />
              </IconButton>
            </>
          )}
          <IconButton onClick={onClose} data-testid="close-button">
            <CloseIcon />
          </IconButton>
        </ButtonGroup>
      </Toolbar>
      <List sx={{ paddingLeft: '24px', paddingRight: '24px', paddingTop: 0 }}>
        <ListItem>
          <ListItemIcon>
            <CalendarTodayIcon />
          </ListItemIcon>
          <ListItemText
            primary={visit.visitTypeName}
            secondary={`${dayjs(visit.scheduledDate).format('MM/DD/YYYY - h:mm A')} - ${t(
              `patients.visit.status.${visit.status}`
            )}`}
            data-testid="visit-type-date"
          />
        </ListItem>
        {isOffice ? (
          <ListItem disablePadding>
            <ListItemButton
              data-testid="employee-details-link"
              component={Link}
              to={`/employee/details/${visit.employee?.id}`}
            >
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText primary={visit.employee?.fullName} data-testid="employee-name" />
              <ListItemIcon sx={{ minWidth: 'unset' }}>
                <ChevronRightIcon />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        ) : showPatientInSummary ? (
          <ListItem disablePadding>
            <ListItemButton
              data-testid="patient-details-link"
              component={Link}
              to={`/patient/details/${visit.patient?.id}/overview?backTo=/dashboard/schedule`}
            >
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText
                primary={visit.patient?.fullName}
                data-testid="patient-name"
                secondary={`${dayjs(visit.patient?.dob).format('MM/DD/YYYY')} - ${t(
                  `app.gender.${visit.patient?.gender}`
                )}`}
              />
              <ListItemIcon sx={{ minWidth: 'unset' }}>
                <ChevronRightIcon />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        ) : (
          <ListItem data-testid="employee-details">
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary={visit.employee?.fullName} data-testid="employee-name" />
          </ListItem>
        )}
        <ListItem>
          <ListItemIcon>
            <LocationOnIcon />
          </ListItemIcon>
          <List>
            <ListItemText primary={patient?.street} data-testid="street" />
            <ListItemText
              primary={`${patient?.city}, ${patient?.state} ${patient?.zip}`}
              data-testid="city-state-zip"
            />
          </List>
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <LocalPhoneIcon />
          </ListItemIcon>
          <ListItemText primary={patient?.phone1} data-testid="phone" />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <SpeakerNotesIcon />
          </ListItemIcon>
          <ListItemText primary={visit.notes ?? t('app.noNotes')} data-testid="notes" />
        </ListItem>
      </List>
    </Dialog>
  );
};

export default VisitViewModal;
