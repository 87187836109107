import { useMemo } from 'react';

import { isNotEmpty } from '@maya/util/string.util';

const specialCharactersRegEx = new RegExp(
  [
    ' ',
    '!',
    '"',
    '#',
    '\\$',
    '%',
    '&',
    "'",
    '\\(',
    '\\)',
    '\\*',
    '\\+',
    ',',
    '-',
    '\\.',
    '/',
    ':',
    ';',
    '<',
    '=',
    '>',
    '\\?',
    '@',
    '\\[',
    '\\\\',
    '\\]',
    '\\^',
    '_',
    '`',
    '{',
    '\\|',
    '}',
    '~'
  ].join('|')
);

export const isValidPassword = (password: string): boolean => {
  let containsCount = 0;

  if (specialCharactersRegEx.test(password)) {
    containsCount += 1;
  }

  if (/[A-Z]/g.test(password)) {
    containsCount += 1;
  }

  if (/[0-9]/g.test(password)) {
    containsCount += 1;
  }

  return isNotEmpty(password) && password.length >= 8 && containsCount >= 2;
};

export function useIsValidPassword(password: string): boolean {
  return useMemo(() => isValidPassword(password), [password]);
}
