import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTheme, lighten } from '@mui/material/styles';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

import useTranslate from '@maya/hooks/useTranslate';

import type { VisitDTO } from '@maya/interface';
import type { FC } from 'react';

export interface VisitSummaryProps {
  visit: VisitDTO;
  type: 'overdue' | 'today' | 'upcoming';
}

const VisitSummary: FC<VisitSummaryProps> = ({ visit, type }) => {
  const t = useTranslate();
  const theme = useTheme();

  const [borderColor, backgroundColor, textColor] = useMemo(() => {
    if (type === 'overdue') {
      return [theme.palette.error.main, theme.palette.error.light];
    }

    if (visit.status === 'incomplete') {
      return [theme.palette.warning.main, theme.palette.warning.light];
    }

    if (visit.status === 'completed') {
      return [theme.palette.success.main, theme.palette.success.light];
    }

    return [theme.palette.divider, 'transparent', theme.palette.text.primary];
  }, [theme, type, visit.status]);

  return (
    <Button
      component={Link}
      to={`/visit/${visit.id}`}
      data-testid={`visit-summary-${visit.id}`}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: '12px',
        borderRadius: '4px',
        border: `1px solid ${borderColor}`,
        background: backgroundColor,
        textDecoration: 'none',
        textTransform: 'none',
        textAlign: 'start',
        color: 'unset',
        '&:hover': {
          background: backgroundColor === 'transparent' ? undefined : lighten(backgroundColor, 0.4)
        }
      }}
    >
      <Typography
        variant="body2"
        component="div"
        sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
      >
        <Box>{dayjs(visit.scheduledDate).format('MM/DD/YYYY h:mm A')}</Box>
        <Box sx={{ color: textColor ?? borderColor }} data-testid="status">
          {t(`patients.visit.status.${visit.status}`)}
        </Box>
      </Typography>
      <Typography variant="body2" component="div" sx={{ width: '100%' }}>
        {visit.visitTypeName}
      </Typography>
      <Typography variant="body2" component="div" sx={{ width: '100%' }}>
        {visit.patientName}
      </Typography>
      <Typography
        variant="body2"
        component="div"
        sx={{ width: '100%' }}
      >{`${visit.patient.street}, ${visit.patient.city} ${visit.patient.state}`}</Typography>
    </Button>
  );
};

export default VisitSummary;
