import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import { useCallback, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import logo from '@maya/assets/images/maya-logo.png';
import useTranslate from '@maya/hooks/useTranslate';
import { useAppDispatch, useAppSelector } from '@maya/store/hooks';
import { forgotPassword, selectAuthInProgress } from '@maya/store/slices/auth';
import { isEmpty } from '@maya/util/string.util';

import type { FC, FormEvent, KeyboardEventHandler } from 'react';

const ForgotPassword: FC = () => {
  const t = useTranslate();
  const navigate = useNavigate();
  const [username, setUsername] = useState('');

  const isInvalid = useMemo(() => {
    return isEmpty(username);
  }, [username]);

  const inProgress = useAppSelector(selectAuthInProgress);

  const dispatch = useAppDispatch();
  const handleReset = useCallback(
    (event?: FormEvent) => {
      event?.preventDefault();

      if (isInvalid) {
        return;
      }

      dispatch(forgotPassword(username, navigate, t));
    },
    [dispatch, isInvalid, navigate, t, username]
  );

  const handleKeydown: KeyboardEventHandler = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        handleReset();
      }
    },
    [handleReset]
  );

  return (
    <Box
      sx={{
        inset: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh'
      }}
    >
      <Card sx={{ maxWidth: 500, width: '100%', margin: '0 16px' }}>
        <CardContent>
          <Box component="form" sx={{ display: 'flex', flexDirection: 'column' }} onSubmit={handleReset}>
            <Box
              component="img"
              src={logo}
              alt="Maya Logo"
              sx={{
                maxWidth: 350,
                height: 150,
                alignSelf: 'center',
                marginBottom: '40px'
              }}
            />
            <TextField
              data-testid="username"
              label={t('auth.username')}
              variant="filled"
              sx={{ marginBottom: '24px' }}
              onChange={(event) => {
                setUsername(event.target.value);
              }}
              onKeyDown={handleKeydown}
              disabled={inProgress}
              required
            />
            <Box sx={{ display: 'flex', gap: '16px', marginBottom: '20px' }}>
              <Button data-testid="reset" variant="contained" onClick={handleReset} disabled={isInvalid || inProgress}>
                {t('auth.reset')}
              </Button>
              <Button data-testid="cancel" component={Link} variant="text" to="/">
                {t('app.cancel')}
              </Button>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ForgotPassword;
