import MuiTableCell from '@mui/material/TableCell';

import type { TableCellProps } from '@mui/material/TableCell';
import type { FC } from 'react';

const TableCell: FC<TableCellProps> = ({ children }) => {
  return (
    <MuiTableCell component="div" sx={{ whiteSpace: 'nowrap' }}>
      {children}
    </MuiTableCell>
  );
};

export default TableCell;
