import EditIcon from '@mui/icons-material/Edit';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PersonIcon from '@mui/icons-material/Person';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import dayjs from 'dayjs';

import useTranslate from '@maya/hooks/useTranslate';
import { useAppSelector } from '@maya/store/hooks';
import { selectIsOffice } from '@maya/store/slices/auth';

import type { PatientDTO } from '@maya/interface';
import type { FC } from 'react';

export interface PatientViewCardProps {
  patient: PatientDTO;
  onStartEditing: () => void;
}

const PatientOverview: FC<PatientViewCardProps> = ({ patient, onStartEditing }) => {
  const t = useTranslate();
  const isOffice = useAppSelector(selectIsOffice);

  return (
    <Card key="details-card" data-testid="patient-overview-view">
      <CardContent sx={{ position: 'relative', paddingTop: 0 }}>
        {isOffice ? (
          <IconButton
            sx={{ position: 'absolute', top: '16px', right: '16px', zIndex: 1 }}
            onClick={onStartEditing}
            data-testid="edit"
          >
            <EditIcon />
          </IconButton>
        ) : null}
        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
          <ListItem>
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText
              primary={patient.fullName}
              secondary={`${dayjs(patient.dob).format('MM/DD/YYYY')} - ${t(`app.gender.${patient.gender}`)}`}
              data-testid="name-dob-and-gender"
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <LocationOnIcon />
            </ListItemIcon>
            <List>
              <ListItemText primary={patient.street} data-testid="street" />
              <ListItemText primary={`${patient.city}, ${patient.state} ${patient.zip}`} data-testid="city-state-zip" />
            </List>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <LocalPhoneIcon />
            </ListItemIcon>
            <ListItemText primary={patient.phone1} data-testid="phone" />
          </ListItem>
        </List>
      </CardContent>
    </Card>
  );
};

export default PatientOverview;
