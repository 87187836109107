import dayjs from 'dayjs';

import type { FC } from 'react';

export interface FormattedDateProps {
  isoDate?: string;
  timestamp?: number;
}

/**
 * Component that takes a string containing an ISO date and displays it formatted
 * in a style desired by this app.
 */
const FormattedDate: FC<FormattedDateProps> = ({ isoDate, timestamp }) => (
  <>{dayjs(isoDate ?? timestamp).format('MM/DD/YYYY')}</>
);

export default FormattedDate;
