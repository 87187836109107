import { useEffect, useMemo } from 'react';

import useBranchId from '@maya/hooks/useBranchId';
import useTranslate from '@maya/hooks/useTranslate';
import { useAppDispatch, useAppSelector } from '@maya/store/hooks';
import { loadVisitTypes, selectVisitTypes } from '@maya/store/slices/visit-type';

export interface UseVisitTypesOptions {
  includeInactive?: boolean;
  currentVisitTypeId?: string;
}

export default function useVisitTypes(options?: UseVisitTypesOptions) {
  const { includeInactive = false, currentVisitTypeId } = options ?? {};

  const branchId = useBranchId();
  const t = useTranslate();

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!branchId) {
      return;
    }

    dispatch(loadVisitTypes(branchId, t));
  }, [branchId, dispatch, t]);

  const rawVisitTypes = useAppSelector(selectVisitTypes);

  return useMemo(() => {
    if (!includeInactive) {
      return rawVisitTypes.filter((visitType) => visitType.active || visitType.id === currentVisitTypeId);
    }

    return rawVisitTypes;
  }, [currentVisitTypeId, includeInactive, rawVisitTypes]);
}
