import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { createRoot } from 'react-dom/client';
import { I18n } from 'react-polyglot';
import { Provider } from 'react-redux';

import App from '@maya/App';
import './index.css';
import messages from './locales/en';
import reportWebVitals from './reportWebVitals';
import { store } from './store';
import themeOptions from './theme';
import SnackbarProvider from './common/snackbar/SnackbarProvider';

const theme = createTheme(themeOptions);

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <I18n locale="en" messages={messages}>
          <SnackbarProvider>
            <>
              <CssBaseline />
              <App key="app" />
            </>
          </SnackbarProvider>
        </I18n>
      </LocalizationProvider>
    </ThemeProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
