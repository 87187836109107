import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import { Link, useParams } from 'react-router-dom';

import Page from '@maya/common/Page';
import Loading from '@maya/common/loading/Loading';
import useTranslate from '@maya/hooks/useTranslate';
import { useAppSelector } from '@maya/store/hooks';
import { selectVisitIsLoading } from '@maya/store/slices/visit';
import RecordListItem from '../records/RecordListItem';
import useVisit from './hooks/useVisit';

import type { FC } from 'react';

const VisitDetails: FC = () => {
  const t = useTranslate();
  const { id } = useParams();

  const visit = useVisit(id);
  const visitLoading = useAppSelector(selectVisitIsLoading);

  return (
    <Page title={t('patients.visit.details')} color="paper" backTo="/dashboard/summary">
      {!visit || visitLoading ? (
        <Loading />
      ) : (
        <>
          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            <ListItem data-testid="type-date-status">
              <ListItemIcon>
                <CalendarTodayIcon />
              </ListItemIcon>
              <ListItemText
                primary={visit.visitTypeName}
                secondary={`${dayjs(visit.scheduledDate).format('MM/DD/YYYY - h:mm A')} - ${t(
                  `patients.visit.status.${visit.status}`
                )}`}
              />
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                data-testid="employee"
                component={Link}
                to={`/patient/details/${visit.patientId}/overview?backTo=${encodeURIComponent(`/visit/${visit.id}`)}`}
              >
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText
                  primary={visit.patientName}
                  secondary={`${dayjs(visit.patient.dob).format('MM/DD/YYYY')} - ${t(
                    `app.gender.${visit.patient.gender}`
                  )}`}
                />
                <ListItemIcon sx={{ minWidth: 'unset' }}>
                  <ChevronRightIcon />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
            <ListItem data-testid="address">
              <ListItemIcon>
                <LocationOnIcon />
              </ListItemIcon>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <ListItemText primary={visit.patient.street} sx={{ margin: 0 }} />
                <ListItemText
                  primary={`${visit.patient.city}, ${visit.patient.state} ${visit.patient.zip}`}
                  sx={{ margin: 0 }}
                />
              </Box>
            </ListItem>
            <ListItem data-testid="phone">
              <ListItemIcon>
                <PhoneIcon />
              </ListItemIcon>
              <ListItemText primary={visit.patient.phone1} />
            </ListItem>
            <ListItem data-testid="notes">
              <ListItemIcon>
                <SpeakerNotesIcon />
              </ListItemIcon>
              <ListItemText primary={visit.notes ?? t('app.noNotes')} />
            </ListItem>
          </List>
          <Divider sx={{ margin: '0 -16px 16px' }} />
          <List
            sx={{ width: '100%', bgcolor: 'background.paper' }}
            subheader={
              <Typography variant="subtitle1" component="div" sx={{ marginLeft: '16px', fontWeight: 'bold' }}>
                {t('patients.visit.documents')}
              </Typography>
            }
          >
            {visit.formSummaries?.map((form) => (
              <RecordListItem key={form.id} form={form} />
            ))}
          </List>
        </>
      )}
    </Page>
  );
};

export default VisitDetails;
