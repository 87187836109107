import { createSlice } from '@reduxjs/toolkit';

import Api from '@maya/api/api';
import fetch, { fetchAllList } from '@maya/api/fetch';

import type { CreateEmployeeDTO, EmployeeDTO, EmployeesDTO } from '@maya/interface';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { t } from 'react-polyglot';
import type { AppDispatch, RootState } from '../index';

// Define a type for the slice state
export interface UserState {
  user?: EmployeeDTO;
  users: EmployeeDTO[];
  total: number;
  isLoading: boolean;
  saveInProgress: boolean;
}

// Define the initial state using that type
const initialState: UserState = {
  users: [],
  total: 0,
  isLoading: false,
  saveInProgress: false
};

export const userSlice = createSlice({
  name: 'user',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setUserLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    updateUsers: (state, action: PayloadAction<EmployeesDTO>) => {
      state.users = action.payload.data;
      state.total = action.payload.total;
      state.isLoading = false;
    },
    updateUser: (state, action: PayloadAction<EmployeeDTO>) => {
      const user = action.payload;

      const index = state.users.findIndex((e) => e.id === user.id);
      if (index >= 0) {
        state.users[index] = user;
      }

      state.user = action.payload;
      state.isLoading = false;
      state.saveInProgress = false;
    },
    setSaveInProgress: (state, action: PayloadAction<boolean>) => {
      state.saveInProgress = action.payload;
    }
  }
});

export const { setUserLoading, updateUsers, updateUser, setSaveInProgress } = userSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectUsers = (state: RootState) => state.user.users;
export const selectTotalUsers = (state: RootState) => state.user.total;

export const selectUser = (state: RootState) => state.user.user;
export const selectUserLoading = (state: RootState) => state.user.isLoading;
export const selectUserSaveInProgress = (state: RootState) => state.user.saveInProgress;

export const loadUsers =
  (search = '', t: t) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    if (state.user.isLoading) {
      return;
    }

    dispatch(setUserLoading(true));

    const response = await fetchAllList(Api.admin_users_Get, t, {
      query: { search }
    });

    if (response) {
      dispatch(updateUsers(response));
    } else {
      dispatch(setUserLoading(false));
    }
  };

export const loadUser = (userId: string, t: t) => async (dispatch: AppDispatch, getState: () => RootState) => {
  const state = getState();
  if (state.user.isLoading) {
    return;
  }

  dispatch(setUserLoading(true));

  const response = await fetch(Api.admin_user_Get, t, { params: { userId } });

  if (response) {
    dispatch(updateUser(response));
  } else {
    dispatch(setUserLoading(false));
  }
};

export const createUser =
  (userData: CreateEmployeeDTO, t: t) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    if (state.user.isLoading) {
      return;
    }

    dispatch(setSaveInProgress(true));

    const user = await fetch(Api.admin_user_Post, t, { body: userData, defaultError: 'user.errors.create' });

    if (user) {
      dispatch(updateUser(user));
      return true;
    } else {
      dispatch(setSaveInProgress(false));
      return false;
    }
  };

export const saveUser = (user: EmployeeDTO, t: t) => async (dispatch: AppDispatch, getState: () => RootState) => {
  const state = getState();
  if (state.user.isLoading) {
    return;
  }

  dispatch(setSaveInProgress(true));

  const updatedUser = await fetch(Api.admin_user_Put, t, {
    params: { userId: user.id },
    body: user,
    defaultError: 'user.errors.update'
  });

  if (updatedUser) {
    dispatch(updateUser(updatedUser));
    return true;
  } else {
    dispatch(setSaveInProgress(false));
    return false;
  }
};

export default userSlice.reducer;
