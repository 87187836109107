import { createSlice } from '@reduxjs/toolkit';

import Api from '@maya/api/api';
import fetch, { fetchAllList } from '@maya/api/fetch';

import type { PaymentStatus, WageDTO, WagesDTO } from '@maya/interface';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { t } from 'react-polyglot';
import type { AppDispatch, RootState } from '../index';

// Define a type for the slice state
export interface PayrollState {
  wages: WageDTO[];
  total: number;
  isLoading: boolean;
  saveInProgress: boolean;
}

// Define the initial state using that type
const initialState: PayrollState = {
  wages: [],
  total: 0,
  isLoading: false,
  saveInProgress: false
};

export const payrollSlice = createSlice({
  name: 'payroll',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setPayrollLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setSaveInProgress: (state, action: PayloadAction<boolean>) => {
      state.saveInProgress = action.payload;
    },
    updateWages: (state, action: PayloadAction<WagesDTO>) => {
      state.wages = action.payload.data;
      state.total = action.payload.total;
      state.isLoading = false;
    },
    updateWage: (state, action: PayloadAction<WageDTO>) => {
      const wage = action.payload;

      const index = state.wages.findIndex((e) => e.id === wage.id);
      if (index >= 0) {
        state.wages[index] = wage;
      }

      state.isLoading = false;
      state.saveInProgress = false;
    }
  }
});

export const { setPayrollLoading, updateWages, updateWage, setSaveInProgress } = payrollSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectWages = (state: RootState) => state.payroll.wages;
export const selectTotalWages = (state: RootState) => state.payroll.total;

export const selectPayrollLoading = (state: RootState) => state.payroll.isLoading;

export const selectWageSaveInProgress = (state: RootState) => state.payroll.saveInProgress;

export interface WagesSearch {
  branchId: string;
  employeeId?: string;
  beforeIsoDate?: string;
  status?: PaymentStatus;
}

export const loadWages =
  ({ branchId, employeeId, beforeIsoDate, status }: WagesSearch, t: t) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    if (state.payroll.isLoading || !branchId) {
      return;
    }

    dispatch(setPayrollLoading(true));

    const response = await fetchAllList(Api.payroll_Get, t, {
      params: { branchId },
      query: { employeeId, beforeDate: beforeIsoDate, status }
    });

    if (response) {
      dispatch(updateWages(response));
    } else {
      dispatch(setPayrollLoading(false));
    }
  };

export const loadEmployeeWages =
  (branchId: string, t: t) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    if (state.payroll.isLoading || !branchId) {
      return;
    }

    dispatch(setPayrollLoading(true));

    const response = await fetchAllList(Api.payroll_EmployeeGet, t, {
      params: { branchId }
    });

    if (response) {
      dispatch(updateWages(response));
    } else {
      dispatch(setPayrollLoading(false));
    }
  };

export const saveWage =
  (branchId: string, wage: WageDTO, t: t) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    if (state.payroll.isLoading || !branchId || !wage?.id) {
      return;
    }

    dispatch(setSaveInProgress(true));

    const updatedWage = await fetch(Api.payroll_Put, t, { params: { branchId, visitId: wage.id }, body: wage });

    if (updatedWage) {
      dispatch(updateWage(updatedWage));
    } else {
      dispatch(setSaveInProgress(false));
    }
  };

export default payrollSlice.reducer;
