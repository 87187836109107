const messages = {
  app: {
    calendar: {
      canceled: 'Canceled',
      scheduled: 'Scheduled'
    },
    cancel: 'Cancel',
    edit: 'Edit',
    errors: {
      required: 'Please fill out this field.',
      invalidFormat: 'Please match the requested format.',
      min: 'Please pick at least %{min}.',
      max: 'Please pick no more than %{max}.'
    },
    gender: {
      m: 'Male',
      f: 'Female'
    },
    form: {
      address: 'Address',
      amount: 'Amount',
      cell: 'Cell',
      city: 'City',
      date: 'Date',
      dateOfBirth: 'Date of Birth',
      discipline: 'Discipline',
      email: 'Email',
      employee: 'Employee',
      extras: 'Extras',
      firstName: 'First Name',
      gender: 'Gender',
      insuranceId: 'Insurance Id',
      lastName: 'Last Name',
      middleInitial: 'Middle Initial',
      notes: 'Notes',
      patient: 'Patient',
      phone: 'Phone',
      role: 'Role',
      secondPhone: 'Second Phone',
      socialSecurityNumber: 'Social Security Number',
      state: 'State',
      status: 'Status',
      time: 'Time',
      username: 'Username',
      visitType: 'Visit Type',
      zip: 'Zip'
    },
    noNotes: 'No Notes',
    pageNotFound: 'Page not found',
    save: 'Save',
    submit: 'Submit',
    search: 'Search',
    totalRows: 'Total Rows',
    inviteAcceptedOn: 'Invite accepted on',
    invited: 'Invited',
    resend: 'Resend'
  },
  auth: {
    username: 'Username',
    password: 'Password',
    code: 'Code',
    login: 'Login',
    loginFailed: 'Login Failed',
    logout: 'Logout',
    verify: 'Verify',
    resend: 'Resend',
    reset: 'Reset',
    forgotPassword: 'Forgot Password',
    changePasword: 'Change Password',
    passwordRequirements:
      'Minimum 8 characters. Must contain at least 2 of: 1 uppercase letter, 1 number, or 1 special character.',
    errors: {
      resetPassword: 'Unable to reset password',
      forgotPassword: 'Unable to send password reset link',
      verify: 'Unable to verify user',
      resend: 'Unable to send invite link',
      reset: 'Unable to reset password'
    }
  },
  copyright: {
    copyright: 'Copyright Mayahealthcare, Inc.',
    allRightsReserved: 'All rights reserved.'
  },
  patients: {
    tabs: {
      overview: 'Overview',
      schedule: 'Schedule',
      records: 'Records'
    },
    list: {
      header: 'Patients',
      empty: 'No Patients Added',
      emptySearch: 'No Patients Found',
      column: {
        dob: 'Date of Birth',
        insurance: 'Insurance & ID',
        name: 'Name',
        phone: 'Phone'
      }
    },
    new: {
      header: 'New Patient'
    },
    patient: 'Patient',
    records: {
      column: {
        date: 'Visit Date',
        document: 'Document',
        assignee: 'Assigned To',
        discipline: 'Discipline',
        status: 'Status'
      },
      status: {
        pending: 'Scheduled',
        saved: 'Not Submitted',
        submitted: 'Submitted'
      }
    },
    visit: {
      add: 'Add Visit',
      header: 'Visit',
      details: 'Visit Details',
      modify: 'Modify Visit',
      new: 'New Visit',
      documents: 'Documents',
      notes: 'Visit Notes',
      status: {
        scheduled: 'Scheduled',
        canceled: 'Canceled',
        incomplete: 'Incomplete',
        completed: 'Completed'
      }
    },
    errors: {
      create: 'Unable to create patient',
      update: 'Unable to update patient'
    }
  },
  payroll: {
    header: 'Payroll',
    list: {
      column: {
        visitDate: 'Visit Date',
        visitType: 'Visit Type',
        employee: 'Employee',
        patient: 'Patient',
        amount: 'Amount',
        status: 'Status'
      },
      empty: 'No visits found'
    },
    editModal: {
      header: 'Pay Status'
    },
    status: {
      paid: 'Paid',
      'not-paid': 'Not Paid'
    }
  },
  employees: {
    view: {
      newHeader: 'New Employee',
      header: 'Employee',
      account: 'Account'
    },
    list: {
      header: 'Employees',
      empty: 'No Employees Added',
      emptySearch: 'No Employees Found',
      column: {
        name: 'Name',
        discipline: 'Discipline',
        role: 'Role',
        phone: 'Phone',
        active: 'Active'
      }
    },
    roles: {
      OFFICE: 'Office Staff',
      FIELD: 'Field Staff',
      BRANCH_ADMIN: 'Branch Administrator'
    },
    errors: {
      create: 'Unable to create employee',
      update: 'Unable to update employee',
      sendInvite: 'Unable to send employee invite',
      usernameExists: 'Username already exists',
      emailExists: 'Email already exists'
    }
  },
  forms: {
    rs: 'Route Sheet',
    'route-sheet': 'Route Sheet',
    pc: 'Patient Consent',
    'patient-consent': 'Patient Consent',
    tl: 'PCA Task List',
    'task-list': 'PCA Task List'
  },
  visitTypes: {
    view: {
      newHeader: 'New Visit Type',
      header: 'Visit Type'
    },
    form: {
      name: 'Visit Type Name',
      discipline: 'Discipline',
      defaultPay: 'Default Pay',
      defaultHours: 'Default Hours',
      paySchedule: 'Pay Schedule',
      visit: 'Per Visit',
      hour: 'Per Hour',
      mileage: 'Mileage',
      getMileage: 'Get Mileage',
      requiredForms: 'RequiredForms'
    },
    list: {
      empty: 'No Visit Types Added',
      column: {
        visitTypeName: 'Visit Type Name',
        discipline: 'Discipline',
        active: 'Active'
      }
    },
    errors: {
      create: 'Unable to create visit type',
      update: 'Unable to update visit type'
    }
  },
  company: {
    view: {
      newHeader: 'New Company',
      editHeader: 'Edit Company',
      header: 'Company Details',
      idNumbers: 'ID Numbers',
      administrator: 'Administrator'
    },
    form: {
      name: 'Company name',
      doingBusinessAs: 'Doing Business As',
      fax: 'Fax',
      federalTaxId: 'Federal Tax ID',
      stateAgencyId: 'State Agency ID',
      medicareId: 'Medicare ID',
      npiNumber: 'NPI Number',
      requiredForms: 'RequiredForms'
    },
    list: {
      header: 'Companies',
      empty: 'No Companies Added',
      emptySearch: 'No Companies Found',
      column: {
        company: 'Company',
        state: 'State',
        active: 'Active'
      }
    },
    errors: {
      create: 'Unable to create company',
      update: 'Unable to update company',
      sendInvite: 'Unable to send company admin invite',
      usernameExists: 'Username already exists',
      emailExists: 'Email already exists',
      taxIdExists: 'Company with federal tax id already exists'
    }
  },
  user: {
    view: {
      newHeader: 'New User',
      header: 'User'
    },
    list: {
      header: 'Users',
      empty: 'No Administrators Added',
      emptySearch: 'No Administrators Found',
      column: {
        name: 'Name',
        phone: 'Phone',
        active: 'Active'
      }
    },
    errors: {
      create: 'Unable to create user',
      update: 'Unable to update user',
      usernameExists: 'Username already exists',
      emailExists: 'Email already exists'
    }
  },
  settings: {
    header: 'Settings',
    tabs: {
      company: 'Company',
      visitTypes: 'Visit Types'
    }
  },
  dashboard: {
    header: 'Dashboard',
    allCaughtUp: 'All Caught Up!',
    tabs: {
      dashboard: 'Dashboard',
      schedule: 'Schedule'
    },
    types: {
      overdue: 'Overdue',
      today: 'Today',
      upcoming: 'Upcoming'
    }
  },
  signature: {
    label: 'Signature',
    done: 'Done',
    remove: 'Remove'
  },
  routeSheet: {
    header: 'Route Sheet',
    visit: 'Visit: RN Evaluation',
    timeIn: 'Time In',
    timeOut: 'Time Out',
    mileage: 'Mileage',
    acknowledgementOfVisit: {
      header: 'Patient Acknowledgement of Visit',
      statement:
        'By my signature below, I hereby acknowledge that the services herein stated were received from the staff named below on the date and time indicated below. I further acknowledge that:',
      point1: 'The service provided was satisfactory.',
      point2: 'During the period I was not hospitalized.',
      point3: 'I have no complaint against the Agency or staff/professional providing the services.',
      point4: 'I am not currently enrolled with an HMO.',
      point5: 'My signature below is true and authentic.'
    },
    certificationOfVisit: {
      header: 'Staff Certification of Visit',
      statement: 'I certify to the truth of the following, under penalty of perjury:',
      point1:
        'I understand that all clinical notes must be submitted to the Agency on the Monday following the home visit. Failure to comply may result in the suspension of future visits and the Agency will, at their discretion, replace me and assign another healthcare professional to undertake the visit(s).',
      point2: 'My professional license/certification, CPR certification, and X-ray or TB results are current.',
      point3: 'All safety issues and concerns noted during the visit were properly reported and documented.',
      point4: 'The services rendered were in accordance with the prescribed Plan of Care.',
      point5:
        'The Patient/Patient Caregiver (PCG) signature acknowledging the home visit and services rendered was signed before me and in my presence and is therefore true and authentic.',
      point6: 'I have rendered the service(s) to the below-named patient at the time and date indicated.',
      point7: 'The patient was NOT hospitalized or otherwise unavailable at the time the service was rendered.',
      point8: `I understand it to be the Agency's policy to submit clinical or visit notes for services actually rendered.`,
      point9:
        'The clinical or visit notes I submitted are complete and appropriate for the Agency to process for billing purposes.',
      point10:
        'In the event that I am found liable for repeatedly committing acts that fail to follow the Plan of Care, conform to Agency policies/procedures, or observe the Nursing Practice Act and all applicable licensing and regulatory board provisions, I hereby absolve, indemnify, defend, and hold the Agency free and harmless from any and all risk(s) and damages arising therefrom.',
      statement2:
        'Pursuant to the duly executed restrictive covenant and confidentiality agreement stipulated herein, I shall not solicit, refer, and/or transfer patient(s) that have been assigned to me for home healthcare to another agency or medical doctor, and I shall be held liable for any economic damages that the Agency may suffer as a direct result of such a breach.',
      statement3:
        'I, the undersigned healthcare worker, hereby acknowledge that the above information was explained to me and I fully understand that any acts of impropriety, such as falsely submitting clinical or visit notes representing services which were not actually rendered, or representing signatures of patients that are not true or authentic, will give the Agency the right and responsibility to report such unacceptable practices to the appropriate professional licensing board(s) and to HCFA for proper disposition.'
    }
  },
  patientConsent: {
    header: 'Patient Consent / Certification',
    certification_header1: 'Request For Initial Assessment/Admission/Consent for Treatment',
    certification_statement1:
      'I hereby request and agree to initial assessment and, if I am able to be admitted to %{branchName} (hereafter %{branchName}), I consent for approved personnel from %{branchName} to perform all necessary procedures and treatments ordered by my physicians for my home health care. I realize my care is directed and monitored by my doctor, who is neither an employee nor an agent of %{branchName}, and that %{branchName} is not liable for any act or omission when following the instructions of the doctor.',
    certification_header2: 'Client Rights and Responsibilities',
    certification_statement2:
      'I understand my Rights and Responsibilities. I have received written notice of my Rights and Responsibilities and the OASIS D1 Statement of Patient Privacy Rights, and the Notice of Privacy Practices before care was furnished by the staff of %{branchName}.',
    certification_header3: 'Registration of Complaints',
    certification_statement3:
      'I agree to notify a %{branchName} Registered Nurse if I have a complaint. I understand that if the Registered Nurse does not resolve the complaint, I or a member of my family should register the complaint in writing to the Administrator. I understand that each person to whom my complaint is directed in writing must respond to the complaint within five (5) working days of the receipt of the written complaint.',
    consent_title: 'Consent to Photograph',
    consent_statement:
      'to the appropriate part(s) of my body being photographed by %{branchName} personnel in order to provide supporting documentation to my medical condition (I understand that any photographs taken will be placed in and remain part of my medical record).',
    consent_consent: 'I consent',
    consent_notConsent: 'I do not consent',

    directives_title: 'Advance Directives',
    directives_statement:
      'I am aware that if I do not have, or fail to produce a properly executed copy of an advance directive, %{branchName} will be obligated to perform cardiac resuscitation and call paramedics if my condition indicates a need for such intervention.',
    directives_directive: 'I have completed an Advance Directive',
    directives_durable: 'I have Durable Power of Attorney',
    directives_will: 'I have a Living Will',
    directives_dnr: 'I have a Do Not Resuscitate Directive',
    directives_none: 'I do not know if I have any of the above',
    directives_other: 'Other (explain)',

    medicare_title: 'Medicare Patients',
    medicare_statement:
      'I understand that %{branchName} accepts all Medicare Part A and Part B as payment in full for all skilled nursing and rehabilitation services and that I will be notified, in advance, if there are any charges for medical equipment and supplies. I am aware that I am responsible for any non-covered costs, share of cost, or co-pay in consideration for the services I am to receive. All reasonable attempts will be made by %{branchName} to collect these payments.',

    patientAcknowledgement_title: 'Patient / Legal Representative Consent / Acknowledgment',
    patientAcknowledgement_statement:
      'My signature will serve to attest that I have received and verbalized understanding of information, materials (where appropriate) and an explanation of all information provided to this form',

    patientResponsibilities_title: 'Patient Responsibilities',
    patientResponsibilities_statement: 'As a patient you have the following responsibilities.',
    patientResponsibilities_point1: "Remain under a doctor's care while receiving services from %{branchName}.",
    patientResponsibilities_point2: 'Provide %{branchName} with complete and accurate health and OASIS information.',
    patientResponsibilities_point3: 'Sign the required consent prior to care being administered or received.',
    patientResponsibilities_point4: 'Provide a safe environment for %{branchName} staff.',
    patientResponsibilities_point5: 'Treat %{branchName} personnel with respect and consideration.',
    patientResponsibilities_point6: 'Notify %{branchName} when you cannot keep appointments.',
    patientResponsibilities_point7:
      'Comply with a plan of treatment while under the care of %{branchName} and understand that non-compliance may result in discharge from %{branchName}.',
    patientResponsibilities_point8: 'Participate in and follow your plan of care.',
    patientResponsibilities_point9: 'Provide %{branchName} with a copy of Advance Directives if applicable.',
    patientResponsibilities_point10:
      'Provide the evaluating staff with OASIS data as mandated by Medicare and consent that %{branchName} transmit OASIS data to CMS (HCFA) (A copy of OASIS Statement of Privacy Rights and the Privacy Act Statement will be provided to you prior to start of care).',

    patientRights_title: 'Patients Rights',
    patientRights_statement:
      'As a patient you have the following rights. %{branchName} must protect and promote the exercise of these rights.',
    patientRights_point1: `%{branchName} must provide you with written notice of these rights, including the OASIS Statement of Patient Privacy Rights, the Privacy Act Statement and Notice of Privacy Practice prior to furnishing care or during initial evaluation procedures.`,
    patientRights_point2: `You have the right to be treated with consideration, respect, and full recognition of dignity and individuality; to be given appropriate and professional home health services without discrimination against your race, religion, gender, age, creed, marital status, political status, sexual orientation, diagnosis, or source of payment; and to be assured that the personnel who provide care are qualified through education and experience to carry out the services for which they are responsible.`,
    patientRights_point3: `You have the right to receive services appropriate to your needs, and to be informed of the nature and purpose of all skilled and non-skilled services, technical procedures to be performed, including information about the potential benefits and burdens, as well as when and who will perform the procedures, and expect %{branchName} to provide safe, professional care at the required level of intensity.
    You have the right to receive reasonable continuity of care.`,
    patientRights_point4: `You have the right to have staff communicate in a language or form you can reasonably be expected to understand (when possible, %{branchName} assists with or may provide special devices, interpreters, or other aids to facilitate communication).`,
    patientRights_point5: `Your family or guardian may exercise your rights through a Durable Power of Attorney for Health Care, should you be unable to exercise them for yourself.`,
    patientRights_point6: `You have the right to have your property treated with respect. You have the right to voice grievances about treatment or care that is (or fails to be) furnished, or regarding lack of respect for your property without reprisal or discrimination for the same and be informed of the procedure to voice complaints/grievances with %{branchName}.`,
    patientRights_point7: `%{branchName} must investigate any complaints made by you, your family, or your guardian regarding any grievances you may have. %{branchName} must document the existence and outcome of such grievances.
    You have the right to be informed in advance of the care to be furnished.`,
    patientRights_point8: `%{branchName} must advise you in advance of the disciplines that will furnish healthcare and of the frequency of proposed visits.`,
    patientRights_point9: `%{branchName} must advise you of any change in the plan of care before the change is made.
    You have the right to participate in the planning of your care.`,
    patientRights_point10: `You have the right to refuse treatment and to be advised of the consequences of such refusal. You have the right to refuse any experimental treatment and/or participate in research unless you give documented voluntary consent.`,
    patientRights_point11: `%{branchName} must inform you and provide you with written information in advance regarding its policy on Advance Directives and to include a description of Nevada State law. %{branchName} will provide the information during evaluation.`,
    patientRights_point12: `You have the right to have your medical records, which are maintained by %{branchName}, remain confidential. You have the right to approve or disapprove in writing the release of your medical records to any individual employed outside of %{branchName}.`,
    patientRights_point13: `%{branchName} must advise you of its policies and procedures regarding the disclosure of medical records. You or your legal representative has the right to obtain a copy of your medical records by giving %{branchName} three (3) days' advance notice. You will be charged 40 cents per page.
    You have the right to be advised, before care is initiated, of the extent to which payment for %{branchName} services may be expected from Medicare or other sources, and the extent to which payment may be required from you.
    Before initiating care, %{branchName} must inform you verbally or in writing of:
    charges for services and Durable Medical Equipment (DME) that will not be covered by Medicare and
    charges for services and Durable Medical Equipment (DME) that you may have to pay.`,
    patientRights_point14: `You have the right to be advised verbally and in writing of any changes in the information regarding payment within 30 days from the date %{branchName} becomes aware of such changes.`,
    patientRights_point15: `You have the right to review a copy of %{branchName}'s Notice of Privacy Practices before signing this consent. %{branchName}'s Notice of Privacy Practices details how %{branchName} may use and disclose your health information. %{branchName} may amend the Notice from time to time. A copy of our Notice of Privacy Practices is included in your Home Chart.`,
    patientRights_point16: `You have the right to request that %{branchName} restrict the use and/or disclosure of your medical records for the purpose of providing treatment, obtaining payment for our services, and/or conducting healthcare operations. Such requests must be made in writing. Please note that %{branchName} is not required to agree to any restriction you may request. If however, %{branchName} decides to agree on a restriction you have requested, %{branchName} must restrict its use and/or disclosure of your medical records in the manner described in your request. To obtain a restriction request form, please contact %{branchName}.`,
    patientRights_point17: `You have the right to revoke this consent at any time. Your revocation of this consent must be in writing. If you wish to revoke this consent, please contact %{branchName} to obtain a revocation form. Note that your revocation of this consent will not be effective for disclosures %{branchName} has already made based on your prior consent. %{branchName} also reserves the right to refuse to provide further treatment if you revoke this consent. This consent is effective unless and until you revoke it in writing to:`,
    patientRights_point18: `You may contact a representative 24 hours a day, 7 days a week, at %{branchPhone}.`,

    administrator: 'Administrator',

    payment_title: 'Payment Request/Assignment of Benefits',
    payment_statement:
      'I certify that all Medicare, HMO, Medicaid, or any other health care payment information given to %{branchName} is true and accurate and I authorize %{branchName} to request payment on my behalf. I authorize the release of any and all records that may be required to secure payment. I understand I must notify %{branchName} immediately of any changes in my medical coverage including Medicare and Medicaid. I also understand that if I change insurance coverage while under the care of %{branchName} I will be responsible for any charges not covered as a result of the change in coverage. I hereby authorize %{branchName} to use and/or disclose my health information for treatment, payment, or health care operations and coordinations.',

    portability_title: 'Health Insurance Portability and Accountability Act (HIPAA)',
    portability_statement:
      'Permission to Use and Disclose Your Health Information: By signing this consent, I authorize %{branchName} to use and/or disclose my health information for treatment, payment, or health care operations. I have the right not to sign this consent. However, if I refuse to sign this consent, %{branchName} has the right to refuse me service.',

    privacy_title: 'OASIS Privacy Act Statement - Health Care Records Confidentiality and Retrieval',
    privacy_statement1:
      'I have been instructed and given a written notice of the Privacy Act Statement on OASIS to advise me of my rights, principle purposes, routine uses, and what may result from my failure to provide accurate information to complete OASIS. I have been given information on the confidentiality and the disclosure of my clinical records maintained by %{branchName}. I have been given information on how to retrieve and obtain a copy of my medical records maintained by %{branchName}.',
    privacy_statement2:
      'Information on Safety Information/Emergency Preparedness/Infection Control & Universal Precautions/911 Protocol and s/s to Report to MD is given to me at initial evaluation and on an ongoing basis as needed.',

    release_title: 'Authorization to Release Information',
    release_statement:
      'I consent to the release of any information regarding my medical condition by any recognized health agency, institution, or medical group/office in which I have been a patient, and I authorize the release and/or disclosure or any medical records or information by the preceding to %{branchName} and those involved in my care while admitted to %{branchName}. I understand my medical records may be revealed to federal and state accrediting bodies and by the Quality Management/Utilization Review Committees of %{branchName}.',
    release_notReleasedAbout: 'Information may not be released about',
    release_notReleasedTo: 'Information may not be released to',

    services_title: 'Proposed Services and Frequency of Visits',
    services_statement:
      'I have been actively involved in the planning of my care with %{branchName} and I understand that the following services and visits will be provided if needed and approved by my doctor:',
    services_nursing: 'Nursing',
    services_pt: 'PT',
    services_ot: 'OT',
    services_st: 'ST',
    services_dietitian: 'Dietitian',
    services_msw: 'MSW',
    services_chha: 'CHHA',
    staffAuthorization_title: '%{branchName} Representative / Staff Authorization of Signatures'
  },
  taskList: {
    header: 'PCA Task List',
    tabs: {
      'ambulation-locomotion': 'Ambulation/Locomotion',
      bathing: 'Bathing',
      information: 'Information',
      other: 'Other',
      'personal-care': 'Personal care',
      report: 'Report',
      vitals: 'Vitals'
    },
    heading: {
      activity: 'Activity',
      ambulationAssistWith: 'Assist with',
      ambulationLocomotion: 'Ambulation/Locomotion',
      ambulationLocomotionDevice: 'Device',
      ambulationLocomotionNeeds: 'Needs',
      amputation: 'Amputation',
      otherAssistedWithSelfMedications: 'Other',
      attentiveness: 'Attentiveness',
      bathing: 'Bathing',
      bathingAvailableEquipment: 'Available equipment',
      bathingMayChooseLowerSafeLevelIf: 'May choose a lower safe level of bath if',
      bathingNeeds: 'Needs',
      bedLinensChanged: 'Changed bed linens',
      bowel: 'Bowel',
      dentures: 'Dentures',
      dressing: 'Dressing',
      entirety: 'Entirety',
      hair: 'Hair',
      hearingAids: 'Hearing aids',
      information: 'Information',
      needs: 'Needs',
      other: 'Other',
      otherPrepared: 'Prepared',
      oxygen: 'Oxygen',
      paresis: 'Paresis',
      personalCare: 'Personal care',
      personalCareOther: 'Other',
      primaryBathType: 'Primary bath type',
      report: 'Report',
      rom: 'ROM',
      teeth: 'Teeth',
      urinaryBladder: 'Uninary bladder',
      urine: 'Urine',
      vitals: 'Vitals',
      weightBearing: 'Weight bearing'
    },
    form: {
      activityUpAsTolerated: 'Up as tolerated',
      activityBedbound: 'Bedbound',
      activityBedrestWithBRP: 'Bedrest with BRP',
      activityUpInChair: 'Up in chair',
      ambulationLocomotionAssistWithTransfers: 'Transfers',
      ambulationLocomotionAssistWithRemainedInBed: 'Remained in bed',
      ambulationLocomotionAssistWithTurnedAndRepositioned: 'Turned and repositioned',
      ambulationLocomotionAssistWithOther: 'Other',
      ambulationLocomotionAssistWithOtherText: 'Other',
      ambulationLocomotionDeviceNoAssistiveDevice: 'No assistive device',
      ambulationLocomotionDeviceGaitBelt: 'Gait belt',
      ambulationLocomotionDeviceCane: 'Cane',
      ambulationLocomotionDeviceWalker: 'Walker',
      ambulationLocomotionDeviceWheelchair: 'Wheelchair',
      ambulationLocomotionDeviceMechanicalLift: 'Mechanical lift',
      ambulationLocomotionDeviceOther: 'Other',
      ambulationLocomotionDeviceOtherText: 'Other',
      ambulationLocomotionFrequencyOtherText: 'Other',
      amputationAKALeft: 'AKA Left',
      amputationAKARight: 'AKA Right',
      amputationBKALeft: 'BKA Left',
      amputationBKARight: 'BKA Right',
      otherAssistedWithSelfMedications: 'Assisted with medications ordinarily self-administered',
      otherAssistedWithSelfMedicationsText: 'Specify',
      otherOstomyCare: 'Ostomy care',
      otherOstomyCareText: 'Specify',
      attentiveness: {
        ALERT_AND_ORIENTED: 'Alert and oriented',
        CONFUSED: 'Confused',
        OTHER: 'Other'
      },
      attentivenessOtherTextLabel: 'Other condition',
      bathingType: {
        SHOWER: 'Shower',
        TUB: 'Tub',
        CHAIR: 'Chair',
        BED: 'Bed',
        OTHER: 'Other'
      },
      bathTypeOtherText: 'Other',
      bathingDressing: {
        REMOVED_AND_DRY_REAPPLIED: 'Dressing removed for bath and dry dressing reapplied',
        KEPT_DRY: 'Dressing kept dry while bathing using'
      },
      bathingAvailableEquipmentGrabBar: 'Grab bar',
      bathingAvailableEquipmentHandheldShower: 'Handheld shower',
      bathingAvailableEquipmentOther: 'Other',
      bathingAvailableEquipmentOtherText: 'Other',
      bathingAvailableEquipmentShowerSeat: 'Shower seat',
      bathingAvailableEquipmentTubBench: 'Tub bench',
      bathingEntirety: {
        COMPLETE: 'Complete',
        PARTIAL: 'Partial',
        PERINEAL_CARE: 'Perineal care'
      },
      bathingFrequencyOtherText: 'Other',
      bathingMayChooseLowerSafeLevelIf: {
        NECESSARY_FOR_SAFETY: 'Necessary for safety',
        PATIENT_REQUESTS: 'Patient requests'
      },
      bedLinensChanged: {
        PRN: 'PRN',
        ONCE_A_WEEK: 'Once a week'
      },
      bladderBowelUsability: {
        CONTINENT: 'Continent',
        INCONTINENT: 'Incontinent'
      },
      bloodPressure: 'Blood pressure',
      bloodPressureFrequencyOtherText: 'Other',
      careNeeds: {
        ASSISTANCE: 'Assistance',
        SUPERVISION_OR_STANDBY_FOR_SAFETY: 'Supervision or standby for safety'
      },
      denturesLower: 'Lower',
      denturesUpper: 'Upper',
      diabetes: 'Diabetes',
      diet: 'Diet',
      doNotResuscitate: 'Do not resuscitate',
      dressingBandage: 'Dressing/bandage',
      fluids: 'Fluids',
      frequency: 'Frequency',
      frequencies: {
        NA: 'NA',
        ONCE_A_WEEK: 'Once a week',
        BI_WEEKLY: 'Bi-weekly',
        EACH_VISIT: 'Each visit',
        OTHER: 'Other'
      },
      hearingAidsLeft: 'Left',
      hearingAidsRight: 'Right',
      heartCondition: 'Heart condition',
      hip: 'Hip',
      infoOther: 'Other',
      knee: 'Knee',
      otherActivityAtTimeOfInquiry: 'Activity at time of inquiry',
      otherAssistedFeeding: 'Assisted feeding',
      otherCleanedCareAreas: 'Cleaned care areas',
      otherCough: 'Cough',
      otherDeepBreath: 'Deep breath',
      otherEncouragedFluids: 'Encouraged fluids',
      otherHomeExercisePerTherapy: 'Exercise per therapy',
      otherOther: 'Other',
      otherOtherText: 'Other',
      otherLastBowelMovement: 'Last bowel movement',
      otherPainLevel: 'Pain level',
      otherPreparedBreakfast: 'Breakfast',
      otherPreparedDinner: 'Dinner',
      otherPreparedLunch: 'Lunch',
      otherPreparedOther: 'Other',
      otherPreparedOtherText: 'Other',
      otherRecordedIandO: 'Recorded I and O',
      paresis: 'Paresis',
      paresisAKALeft: 'AKA Left',
      paresisAKARight: 'AKA Right',
      paresisBKALeft: 'BKA Left',
      paresisBKARight: 'BKA Right',
      personalCareFrequencyOtherText: 'Other',
      personalCareHairComb: 'Comb',
      personalCareHairBrush: 'Brush',
      personalCareHairShampoo: 'Shampoo',
      personalCareOtherDressing: 'Dressing',
      personalCareOtherFindings: 'Findings',
      personalCareOtherFootCare: 'Foot care',
      personalCareOtherNailCare: 'Nail care',
      personalCareOtherPressureAreasAndSkin: 'Checked for pressure areas and other skin changes',
      personalCareOtherShaving: 'Shaving',
      personalCareOtherSkinCare: 'Skin care',
      personalCareTeethBrush: 'Brush',
      personalCareTeethDentureCare: 'Denture care',
      personalCareTeethUseOralSwabs: 'Use oral swabs',
      pulse: 'Pulse',
      pulseFrequencyOtherText: 'Other',
      report: 'Report',
      respiration: 'Respiration',
      respirationFrequencyOtherText: 'Other',
      respiratoryCondition: 'Respiratory condition',
      rom: {
        ACTIVE: 'Active',
        PASSIVE: 'Passive'
      },
      shoulderSurgery: 'Shoulder surgery',
      shoulderSurgeryLeft: 'Left',
      shoulderSurgeryRight: 'Right',
      specialPrecautions: 'Special precautions',
      specialPrecautionsGloves: 'Gloves',
      specialPrecautionsGown: 'Gown',
      specialPrecautionsMask: 'Mask',
      surgeryProcedure: 'Surgery Procedure',
      temperature: 'Temperature',
      temperatureOtherText: 'Other',
      temperatureUnit: {
        FAHRENHEIT: 'F',
        CELSIUS: 'C'
      },
      universalPrecautions: 'Universal precautions',
      urineOstomy: 'Ostomy',
      urineOther: 'Other',
      urineOtherTextLabel: 'Other type',
      urineUrinaryCatheter: 'Urinary catheter',
      usesOxygen: 'Uses oxygen',
      usesOxygenOtherTextLabel: 'Other',
      usesOxygenType: {
        CONTINUOUS: 'Continuous',
        OTHER: 'Other',
        PRN: 'PRN'
      },
      wearsContacts: 'Wears Contacts',
      wearsGlasses: 'Wears Glasses',
      weight: 'Weight',
      weightBearing: {
        FULL: 'Full',
        PARTIAL: 'Partial',
        NONE: 'None'
      },
      weightOtherText: 'Other',
      weightUnit: {
        POUND: 'lb',
        KILOGRAM: 'kg'
      }
    }
  }
};

export default messages;
