import { createSlice } from '@reduxjs/toolkit';

import Api from '@maya/api/api';
import fetch from '@maya/api/fetch';

import type { RouteSheetDTO, UpdateRouteSheetDTO } from '@maya/interface';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { t } from 'react-polyglot';
import type { AppDispatch, RootState } from '../index';

// Define a type for the slice state
export interface RouteSheetState {
  routeSheet?: RouteSheetDTO;
  isLoading: boolean;
  saveInProgress: boolean;
}

// Define the initial state using that type
const initialState: RouteSheetState = {
  isLoading: false,
  saveInProgress: false
};

export const routeSheetSlice = createSlice({
  name: 'routeSheet',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setRouteSheetLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    updateRouteSheet: (state, action: PayloadAction<RouteSheetDTO>) => {
      state.routeSheet = action.payload;
      state.isLoading = false;
      state.saveInProgress = false;
    },
    setSaveInProgress: (state, action: PayloadAction<boolean>) => {
      state.saveInProgress = action.payload;
    }
  }
});

export const { setRouteSheetLoading, updateRouteSheet, setSaveInProgress } = routeSheetSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectRouteSheet = (state: RootState) => state.routeSheet.routeSheet;
export const selectRouteSheetLoading = (state: RootState) => state.routeSheet.isLoading;
export const selectRouteSheetSaveInProgress = (state: RootState) => state.routeSheet.saveInProgress;

export const loadRouteSheet =
  (branchId: string, visitId: string, routeSheetId: string, t: t) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    if (state.routeSheet.isLoading) {
      return;
    }

    dispatch(setRouteSheetLoading(true));

    const response = await fetch(Api.routeSheet_Get, t, { params: { branchId, visitId, routeSheetId } });

    if (response) {
      dispatch(updateRouteSheet(response));
    } else {
      dispatch(setRouteSheetLoading(false));
    }
  };

export const saveRouteSheet =
  (branchId: string, visitId: string, routeSheetId: string, data: UpdateRouteSheetDTO, t: t) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    if (state.routeSheet.isLoading) {
      return;
    }

    dispatch(setSaveInProgress(true));

    const routeSheet = await fetch(Api.routeSheet_Put, t, {
      params: { branchId, visitId, routeSheetId },
      body: data
    });

    if (routeSheet) {
      dispatch(updateRouteSheet(routeSheet));
    } else {
      dispatch(setSaveInProgress(false));
    }
  };

export default routeSheetSlice.reducer;
