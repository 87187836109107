import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Fab from '@mui/material/Fab';
import InputAdornment from '@mui/material/InputAdornment';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';

import Empty from '@maya/common/Empty';
import Page from '@maya/common/Page';
import Loading from '@maya/common/loading/Loading';
import TableCell from '@maya/common/table/TableCell';
import { PAGE_HEIGHT } from '@maya/constants';
import useBranchId from '@maya/hooks/useBranchId';
import useDebounce from '@maya/hooks/useDebounce';
import useTranslate from '@maya/hooks/useTranslate';
import { useAppDispatch, useAppSelector } from '@maya/store/hooks';
import { saveEmployee, selectEmployeeLoading, selectTotalEmployees } from '@maya/store/slices/employee';
import { isNotEmpty } from '@maya/util/string.util';
import useEmployees from './hooks/useEmployees';

import type { EmployeeDTO } from '@maya/interface';
import type { FC, MouseEvent } from 'react';

const EmployeeList: FC = () => {
  const t = useTranslate();
  const branchId = useBranchId();

  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 200);

  const employees = useEmployees(debouncedSearch);
  const totalEmployees = useAppSelector(selectTotalEmployees);
  const employeesLoading = useAppSelector(selectEmployeeLoading);

  const dispatch = useAppDispatch();
  const toggleEmployeeActive = useCallback(
    (employee: EmployeeDTO) => (event: MouseEvent) => {
      event.stopPropagation();
      if (!branchId) {
        return;
      }

      dispatch(
        saveEmployee(
          branchId,
          {
            ...employee,
            active: !employee.active
          },
          t
        )
      );
    },
    [branchId, dispatch, t]
  );

  return (
    <Page title={t('employees.list.header')}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          height: `calc(100vh - ${PAGE_HEIGHT}px)`
        }}
      >
        <Card sx={{ flexGrow: 1 }}>
          <CardContent
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
              paddingBottom: '12px!important'
            }}
          >
            <TextField
              id="filled-basic"
              label={t('app.search')}
              variant="filled"
              role="search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
              fullWidth
              onChange={(event) => {
                setSearch(event.target.value);
              }}
            />
            {employeesLoading ? (
              <Loading />
            ) : employees.length === 0 ? (
              <Empty key="empty" image="list">
                {t(isNotEmpty(debouncedSearch) ? 'employees.list.emptySearch' : 'employees.list.empty')}
              </Empty>
            ) : (
              <>
                <TableContainer sx={{ flexGrow: 1 }}>
                  <Table aria-label="employee list" stickyHeader component="div">
                    <TableHead component="div">
                      <TableRow component="div">
                        <TableCell>{t('employees.list.column.name')}</TableCell>
                        <TableCell>{t('employees.list.column.discipline')}</TableCell>
                        <TableCell>{t('employees.list.column.role')}</TableCell>
                        <TableCell>{t('employees.list.column.phone')}</TableCell>
                        <TableCell>{t('employees.list.column.active')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody component="div">
                      {employees.map((employee) => (
                        <TableRow
                          component={Link}
                          key={employee.id}
                          data-testid={`employee-${employee.fullName}`}
                          sx={{ textDecoration: 'none', '&:last-child td, &:last-child th': { border: 0 } }}
                          hover
                          to={`/employee/details/${employee.id}`}
                        >
                          <TableCell>{employee.fullName}</TableCell>
                          <TableCell>{employee.disciplineCode}</TableCell>
                          <TableCell>{t(`employees.roles.${employee.role}`)}</TableCell>
                          <TableCell>{employee.phone}</TableCell>
                          <TableCell component="div" data-testid="checkbox" sx={{ padding: 0 }}>
                            <Switch
                              checked={employee.active}
                              color="secondary"
                              onClick={toggleEmployeeActive(employee)}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Typography variant="body2" sx={{ textAlign: 'right' }}>
                  {t('app.totalRows')} {totalEmployees}
                </Typography>
              </>
            )}
          </CardContent>
        </Card>
        <Fab
          variant="extended"
          component={Link}
          to="/employee/new"
          color="secondary"
          sx={{ alignSelf: 'flex-end', gap: '4px', minHeight: '48px' }}
        >
          <AddIcon />
          {t('employees.view.header')}
        </Fab>
      </Box>
    </Page>
  );
};

export default EmployeeList;
