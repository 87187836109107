import { createSlice } from '@reduxjs/toolkit';

import Api from '@maya/api/api';
import fetch from '@maya/api/fetch';

import type { PatientConsentDTO, UpdatePatientConsentDTO } from '@maya/interface';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { t } from 'react-polyglot';
import type { AppDispatch, RootState } from '../index';

// Define a type for the slice state
export interface PatientConsentState {
  patientConsent?: PatientConsentDTO;
  isLoading: boolean;
  saveInProgress: boolean;
}

// Define the initial state using that type
const initialState: PatientConsentState = {
  isLoading: false,
  saveInProgress: false
};

export const patientConsentSlice = createSlice({
  name: 'patientConsent',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setPatientConsentLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    updatePatientConsent: (state, action: PayloadAction<PatientConsentDTO>) => {
      state.patientConsent = action.payload;
      state.isLoading = false;
      state.saveInProgress = false;
    },
    setSaveInProgress: (state, action: PayloadAction<boolean>) => {
      state.saveInProgress = action.payload;
    }
  }
});

export const { setPatientConsentLoading, updatePatientConsent, setSaveInProgress } = patientConsentSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectPatientConsent = (state: RootState) => state.patientConsent.patientConsent;
export const selectPatientConsentLoading = (state: RootState) => state.patientConsent.isLoading;
export const selectPatientConsentSaveInProgress = (state: RootState) => state.patientConsent.saveInProgress;

export const loadPatientConsent =
  (branchId: string, visitId: string, patientConsentId: string, t: t) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    if (state.patientConsent.isLoading) {
      return;
    }

    dispatch(setPatientConsentLoading(true));

    const response = await fetch(Api.patientConsent_Get, t, { params: { branchId, visitId, patientConsentId } });

    if (response) {
      dispatch(updatePatientConsent(response));
    } else {
      dispatch(setPatientConsentLoading(false));
    }
  };

export const savePatientConsent =
  (branchId: string, visitId: string, patientConsentId: string, data: UpdatePatientConsentDTO, t: t) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    if (state.patientConsent.isLoading) {
      return;
    }

    dispatch(setSaveInProgress(true));

    const patientConsent = await fetch(Api.patientConsent_Put, t, {
      params: { branchId, visitId, patientConsentId },
      body: data
    });

    if (patientConsent) {
      dispatch(updatePatientConsent(patientConsent));
    } else {
      dispatch(setSaveInProgress(false));
    }
  };

export default patientConsentSlice.reducer;
