import MuiTextField from '@mui/material/TextField';
import { useCallback, useMemo } from 'react';

import useValidate from '@maya/hooks/useValidate';
import { isEmpty } from '@maya/util/string.util';

import type { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField';
import type { ChangeEvent, ChangeEventHandler, FC } from 'react';

export interface TextFieldProps extends Omit<MuiTextFieldProps, 'onChange' | 'value' | 'defaultValue'> {
  pattern?: string;
  required?: boolean;
  readOnly?: boolean;
  min?: number;
  max?: number;
  step?: number;
  defaultValue: number | undefined | null;
  onChange: (newValue: number | undefined, event: ChangeEvent<HTMLInputElement>) => void;
  onValidate?: (valid: boolean) => void;
}

const NumberField: FC<TextFieldProps> = ({
  required = false,
  pattern,
  error,
  helperText,
  variant = 'filled',
  fullWidth = true,
  defaultValue,
  onChange,
  onValidate,
  InputProps,
  readOnly,
  min,
  max,
  step,
  ...props
}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const defaultValueMemoized = useMemo(() => defaultValue, []);

  const { validate, hint, hasError } = useValidate({
    defaultValue: defaultValueMemoized,
    required,
    pattern,
    error,
    helperText,
    onValidate
  });

  const handleOnChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const strValue = event.target.value;
      const value = isEmpty(strValue) ? undefined : +strValue;
      onChange(value, event);
      validate(value);
    },
    [onChange, validate]
  );

  return (
    <MuiTextField
      required={required}
      variant={variant}
      fullWidth={fullWidth}
      onChange={handleOnChange}
      helperText={hint ?? <>&nbsp;</>}
      defaultValue={defaultValueMemoized}
      error={hasError}
      type="number"
      InputProps={{
        readOnly,
        inputProps: {
          ...InputProps?.inputProps,
          min,
          max,
          step
        },
        ...InputProps
      }}
      {...props}
    />
  );
};

export default NumberField;
